import React, { useEffect, useState } from "react";
import smallprofilevector from "../../../assets/images/smallprofilevector.png";
import connectbmg from "../../../assets/images/Icons/connectbmg.svg";
import Profileimage from "../../../assets/images/ProfileImage.png";
import briefcase from "../../../assets/images/Icons/briefcase.svg";
import redheart from "../../../assets/images/Icons/redheart.svg";
import whiteheart from "../../../assets/images/Icons/whiteheart.svg";
import greenheart from "../../../assets/images/Icons/greenheart.svg";
import Videofullimg from "../../../assets/images/Videofullimg.png";
import Flag from "../../../assets/images/Flag.png";
import whitearrowleft from "../../../assets/images/Icons/whitearrowleft.svg";
import { useDispatch } from "react-redux";
import logo from "../../../assets/images/logo.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LogOutUserReducer } from "../../../store/loginSlice/loginSlice";
import logouticon from "../../../assets/images/Icons/logouticon.svg";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import {
  CANDIDATE_PROFILE,
  EMPLOYER_SHORTLIST_CANDIDATE,
} from "../../../utils/baseUrl";
import { useSelector } from "react-redux";
import { fetchAdminProfile } from "../../../store/adminProfileSlice/adminProfileSlice";
import Skeleton from "react-loading-skeleton";

const CandidatePublicProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { id } = useParams();
  const location = useLocation();
  const isLoading = useSelector((state) => state?.userSlice?.loading);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("candidate");

  const [user, setUser] = useState();

  useEffect(() => {
    const apiUrl = `https://api.bmgtalentplus.com.sg/public/profile?userId=${id}`;

    axios
      .get(apiUrl)
      .then((response) => {
        
        setUser(response?.data);
      })
      .catch((error) => {
        // Handle error, e.g., log it or show an error message
        
      });
  }, [id]);

  return (
    <>
      {user && (
        <div className="mx-auto">
          <div className="grid 2xl:grid-cols-[300px_1fr] xl:grid-cols-[300px_1fr] lg:grid-cols-[280px_1fr] md:grid-cols-1">
            <div className="leftsection bg-white rounded flex flex-col  h-full py-12  px-8">
              <div className="flex items-center justify-center mb-12">
                <img src={logo} />
              </div>
              <div className="top">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={150} count={4} />
                  </div>
                ) : (
                  <img
                    src={`https://api.bmgtalentplus.com.sg/profile/${user?.content?.profile_picture}`}
                    alt="profilePicture"
                    className="w-[100px] h-[100px] rounded-full object-cover mx-auto"
                  />
                )}
                <div className="candidateinfo bg-[#f9f9f9] border-[1px] mt-10 rounded-md py-1 px-8 flex flex-col items-center justify-center  ">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <Skeleton width={100} count={1} />
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <h2 className="text-md text-black font-semibold">
                        {user?.content?.first_name} {user?.content?.last_name}
                      </h2>
                      <p className="text-[12px] font-normal text-[#696969]">
                        {user?.content?.title}
                      </p>
                    </div>
                  )}
                </div>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={100} count={1} />
                  </div>
                ) : (
                  <div className="status mt-5">
                    {/* <h2 className="text-[22px] text-black font-semibold">
   Availability
 </h2>
 <div className="flex items-center gap-2 mt-2">
   <img src={briefcase} alt="" />
   <h2 className="text-[16px] text-[#696969] font-normal">
     Full time, part time
   </h2>
 </div> */}
                    <div className="flex items-center gap-2 mt-2">
                      <img src={Flag} alt="" />
                      <h2 className="text-[14px] text-[#696969] capitalize font-normal">
                        {user?.content?.country}
                      </h2>
                    </div>
                  </div>
                )}
                <div className="languages mt-8">
                  <h2 className="text-[16px] text-black font-semibold">
                    Languages
                  </h2>

                  {isLoading ? (
                    <div className="skeleton-loader">
                      <Skeleton width={150} count={4} />
                    </div>
                  ) : (
                    <div className="flex flex-col items-left w-[80%] md:w-[20%] sm:w-[44%] gap-4 mt-3 ">
                      {user?.content?.language?.map((item, index) => (
                        <h2
                          key={index}
                          className="relative 2xl:text-[13px] capitalize xl:text-[13px] lg:text-[12px] md:text-[10px] text-[10px] text-white bg-gradient-to-l from-[#E9AA06] to-[#FFC107] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                        >
                          {item.lan} : {item.spoking}
                        </h2>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="rightsection pl-6 bg-[#F8F8FB] pb-12 mr-5">
              <img src={Videofullimg} alt="" className="xl:w-full 2xl:w-full" />
              <div className="aboutsection border-[1px] bg-white  rounded-md py-12 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium">
                  About Me
                </h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={2} />
                  </div>
                ) : (
                  <p className="text-[15px] font-medium leading-[36px] pt-2 pr-16 text-[#696969]">
                    {user?.content?.about_me}
                  </p>
                )}
              </div>
              <div className="workexperience border-[1px] bg-white rounded-md shadow-md py-14 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium">
                  Work Experience
                </h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={6} />
                  </div>
                ) : (
                  <div>
                    {user?.content?.experience?.map((exp, index) => (
                      <div
                        key={index}
                        className=" px-[1rem] pt-[2px] pb-[5px] border-solid border-b-[1px] rounded-sm border-[#eeeeee] "
                      >
                        <p className="text-[14px] font-medium leading-[36px] pt-2 pr-16 text-[#696969]">
                          {exp.designation} | {exp.company} |
                        </p>
                        <p className="text-[14px] font-medium leading-[36px] pr-16 text-[#E9AA06]">
                          {exp.start_date} {exp.end_date}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="Skills border-[1px] relative bg-white rounded-md py-10 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium pb-4">
                  Skills
                </h2>

                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={3} />
                  </div>
                ) : (
                  <div className="max-h-[220px] min-h-[220px]  rounded-md overflow-y-auto ">
                    <h2 className="text-gray-700 text-[16px] text-left px-4 pt-4">
                      Tech Skills
                    </h2>
                    <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                      {user?.content?.skill?.map(
                        (title, index) =>
                          title.type === "TECH" && (
                            <h2
                              key={index}
                              className="relative text-[13px] text-center py-[9px]   border-[1px] rounded-md bg-white"
                            >
                              {title.name} {title.rating}/10
                            </h2>
                          )
                      )}
                    </div>
                  </div>
                )}
                <div className="max-h-[220px] min-h-[220px] rounded-md  mt-4 overflow-y-auto ">
                  <h2 className="text-gray-700 text-[15px] text-left px-4 pt-4">
                    Soft Skills
                  </h2>
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <Skeleton width={800} count={3} />
                    </div>
                  ) : (
                    <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                      {user?.content?.skill?.map(
                        (title, index) =>
                          title.type === "SOFT" && (
                            <h2
                              key={index}
                              className="relative text-[13px] text-center py-[9px]   border-[1px] rounded-md bg-white"
                            >
                              {title.name} {title.rating}/10
                            </h2>
                          )
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="education border-[1px] bg-white rounded-md shadow-md py-14 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium">
                  Education
                </h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={4} />
                  </div>
                ) : (
                  <div>
                    {user?.content?.education?.map((edu, index) => (
                      <div
                        key={index}
                        className="flex items-baseline my-3 justify-between px-[1rem] pt-[2px] pb-[10px] border-b-[1px] p-1  "
                      >
                        <div>
                          <p className="text-[14px] font-medium leading-[25px]  pr-16 text-[#696969]">
                            {edu.degree} | {edu.university} |
                          </p>
                          <p className="text-[14px] font-medium leading-[25px] pr-16 text-[#E9AA06]">
                            {edu.start_date} {edu.end_date}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CandidatePublicProfile;
