import { Route, Routes } from "react-router-dom";
import "./App.css";
import LoginForm from "./components/Auth/login/index";
import CandidateSignup from "./components/Auth/Register/Candidate";
import EmployerSignUp from "./components/Auth/Register/Employer";
import OTP from "./components/Auth/Register/OTP";
import AdminLogin from "./components/Auth/adminLogin/adminlogin";
import AuthRoute from "./components/ProtectedRouting/authRoute";
import RoleBaseAccess from "./components/ProtectedRouting/roleBaseAccess";
import {
  adminProtectedRoute,
  protectedRoutes,
  rolebaseProtectedRoute,
} from "./components/ProtectedRouting/routes";
import EmailSent from "./components/Auth/Register/OTP/EmailSent";
import AdminAccess from "./components/ProtectedRouting/adminAccess";
import CandidatePublicProfile from "./Pages/Employer/publicProfileCandidate/publicProfile";
import CandidateProfile from "./Pages/Employer/CandidateProfile";
import CandidateProfileAdmin from "./Pages/BMGadmin/candidateProfileAdmin/candidateProfileAdmin";
import { UserContext } from "./Pages/zoomIntegration/Context/globalContext";
import Home from "./Pages/zoomIntegration/Home/index";
import Session from "./Pages/zoomIntegration/Session/index";

var meetingArgs = {
  videoSDKJWT: "",
  sessionName: "My Zoom Session",
  userName: "",
  sessionPasscode: "",
  cloud_recording_option: "0",
  cloud_recording_election: "0",
  role: 1,
  telemetry_tracking_id: "",
  // features: ["video", "audio", "settings", "users", "chat"],
  features: ["video", "audio"],
};

const App = () => {
  return (
    <div className="App">
      <UserContext.Provider value={meetingArgs}>
        <Routes>
          <Route element={<AuthRoute allowedRoles={["CANDIDATE"]} />}>
            {protectedRoutes?.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Route>
          <Route element={<RoleBaseAccess allowedRoles={["EMPLOYEE"]} />}>
            {rolebaseProtectedRoute?.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Route>
          <Route
            element={
              <AdminAccess allowedRoles={["SUPER-ADMIN", "HR", "MANAGER"]} />
            }
          >
            {adminProtectedRoute?.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Route>
          {/* <Route path="/" element={<HomePage />} /> */}
          <Route path="/" element={<LoginForm />} />
          <Route path="/email-sent" element={<EmailSent />} />
          <Route path="/otp" element={<OTP />} />
          <Route
            path="https://app.bmgtalentplus.com.sg/candidate-signup"
            element={<CandidateSignup />}
          />
          <Route path="/employer-signup" element={<EmployerSignUp />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/public-profile" element={<CandidatePublicProfile />} />
          <Route path="/candidate-profile/:id" element={<CandidateProfile />} />
          <Route
            // path="/candidate-profile-admin/:id"
            path="/candidate-profile-admin/:id"
            element={<CandidateProfileAdmin />}
          />
          <Route path="/zoom-home" element={<Home />} />
          <Route path="/zoom-session" element={<Session />} />
        </Routes>
      </UserContext.Provider>
    </div>
  );
};

export default App;
