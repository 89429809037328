import React from "react";
import { Fragment, useState } from "react";
import logo from "../../../assets/images/logo.png";
import Interviews from "../../../assets/images/Icons/Interviews.svg";
import { IconChevronUp, IconUsersPlus } from "@tabler/icons-react";
import { IconCheck, IconChevronDown, IconUserPlus } from "@tabler/icons-react";
import { IconLoader3 } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { IconX } from "@tabler/icons-react";
import { IconUsers } from "@tabler/icons-react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import AdminNavbar from "../adminNavbar";
import profilevectoricon from "../../../assets/images/Icons/profilevectoricon.svg";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MobSideBar = ({ currentPage }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCandidateOpen, setIsCandidateOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState("PENDING");
  const [openMember, setOpenMember] = useState(false);
  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);
  const [selectedCandidateLink, setSelectedCandidateLink] = useState("HOLD_ON");

  const navigate = useNavigate();
  const handleLinkClick = (link, status) => {
    
    setSelectedLink(link);
    navigate(
      `/interview-dashboard?approval=${link}&interviewStatus=${status} `
    );
  };
  const handleAllUsers = (link, status) => {
    
    setSelectedLink(link);
    navigate(
      `/admin-interview-dashboard?approval=${link}&interviewStatus=${status}`
    );
  };
  const handleEmployerRequest = (value) => {
    
    setSelectedLink(value);
    navigate(`/employer-interviews`);
  };

  const handleAllEmployee = (link, status, value) => {
    
    setSelectedLink(value);
    navigate(
      `/admin-interview-dashboard?approval=${link}&interviewStatus=${status}`
    );
  };
  const handleCandidate = (link, status, value) => {
    setSelectedLink(value);
    navigate(
      `/admin-candidate-dashboard?approval=${link}&interviewStatus=${status} `
    );
  };
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img className="h-8 pl-4 w-auto" src={logo} alt="logo" />
                    </div>

                    {currentPage === "interviews" && (
                      <div>
                        {user?.customer.role === "HR" ? (
                          <div className="HR">
                            <div className="py-4 cursor-pointer pl-6 sm:pl-4 pr-4 flex items-center justify-between gap-3">
                              <div
                                className="flex items-center gap-2"
                                onClick={() => setIsOpen(!isOpen)}
                              >
                                <img src={Interviews} alt="" />
                                <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                                  Interview
                                </h2>
                              </div>
                              {isOpen ? (
                                <IconChevronUp
                                  size={18}
                                  color="#545a6d"
                                  onClick={() => setIsOpen(!isOpen)}
                                />
                              ) : (
                                <IconChevronDown
                                  size={18}
                                  onClick={() => setIsOpen(!isOpen)}
                                />
                              )}
                            </div>

                            <div
                              style={{ marginTop: "0px" }}
                              className={`openclosesection pl-5 transition flex items-stretch flex-col  delay-500 duration-800 ${
                                isOpen ? "block" : "hidden"
                              }`}
                            >
                              <button
                                onClick={() => handleLinkClick("PENDING")}
                                className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                                  selectedLink === "PENDING"
                                    ? "bg-white text-[#fcc015]"
                                    : "bg-white text-[#545a6d]"
                                }`}
                              >
                                <div className="flex items-center gap-2 lg:gap-0">
                                  <IconLoader3
                                    className={
                                      selectedLink === "PENDING"
                                        ? "text-[#fcc015]"
                                        : "text-[#545a6d]"
                                    }
                                  />
                                  <h2
                                    className={`text-[12px] lg:text-[12px] md:text-[12px] font-medium text-center ${
                                      selectedLink === "PENDING"
                                        ? "text-[#fcc015]"
                                        : "text-[#545a6d]"
                                    }`}
                                  >
                                    Pending Interviews
                                  </h2>
                                </div>
                              </button>

                              <button
                                onClick={() => handleLinkClick("APPROVED")}
                                className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                                  selectedLink === "APPROVED"
                                    ? "bg-white text-[#fcc015]"
                                    : "bg-white text-[#545a6d]"
                                }`}
                              >
                                <div className="flex items-center gap-2 lg:gap-0">
                                  <IconCheck
                                    className={
                                      selectedLink === "APPROVED"
                                        ? "text-[#fcc015]"
                                        : "text-[#545a6d]"
                                    }
                                  />
                                  <h2
                                    className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                                      selectedLink === "APPROVED"
                                        ? "text-[#fcc015]"
                                        : "text-[#41434b]"
                                    }`}
                                  >
                                    Accepted Interviews
                                  </h2>
                                </div>
                              </button>
                            </div>

                            {/* <Link to="/onboarding-dashboard">
                         {" "}
                         <div className="onboarding  sm:pl-4 pr-4 flex items-center justify-between gap-3  ">
                           <div className="setting py-4 pl-6 sm:pl-4  flex items-center gap-3">
                             <div className="flex items-center gap-2">
                               <img src={onboardingicon} alt="" />
                               <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                                 Onboarding
                               </h2>
                             </div>
                           </div>
                           <IconChevronDown
                             size={18}
                             onClick={() => setIsOnboardingOpen(!isOnboardingOpen)}
                           />
                         </div>
                       </Link> */}
                          </div>
                        ) : (
                          <div className="SUPER_ADMIN">
                            <div className="py-4 cursor-pointer pl-6 sm:pl-4 pr-4 flex items-center justify-between gap-3">
                              <div
                                className="flex items-center gap-2"
                                onClick={() => setIsOpen(!isOpen)}
                              >
                                <img src={Interviews} alt="" />
                                <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                                  Interview
                                </h2>
                              </div>
                              {isOpen ? (
                                <IconChevronUp
                                  size={18}
                                  color="#545a6d"
                                  onClick={() => setIsOpen(!isOpen)}
                                />
                              ) : (
                                <IconChevronDown
                                  size={18}
                                  onClick={() => setIsOpen(!isOpen)}
                                />
                              )}
                            </div>

                            <div
                              style={{ marginTop: "0px" }}
                              className={`openclosesection pl-5 transition flex items-stretch flex-col  delay-500 duration-800 ${
                                isOpen ? "block" : "hidden"
                              }`}
                            >
                              <button
                                onClick={() =>
                                  handleAllUsers("PENDING", "CANDIDATE")
                                }
                                className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                                  selectedLink === "PENDING"
                                    ? "bg-white text-[#fcc015]"
                                    : "bg-white text-[#545a6d]"
                                }`}
                              >
                                <div className="flex items-center gap-2 lg:gap-0">
                                  <IconLoader3
                                    className={
                                      selectedLink === "PENDING"
                                        ? "text-[#fcc015]"
                                        : "text-[#545a6d]"
                                    }
                                  />
                                  <h2
                                    className={`text-[12px] lg:text-[12px] md:text-[12px] font-medium text-center ${
                                      selectedLink === "PENDING"
                                        ? "text-[#fcc015]"
                                        : "text-[#545a6d]"
                                    }`}
                                  >
                                    Pending Candidates
                                  </h2>
                                </div>
                              </button>

                              <button
                                onClick={() =>
                                  handleAllUsers("APPROVED", "CANDIDATE")
                                }
                                className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                                  selectedLink === "APPROVED"
                                    ? "bg-white text-[#fcc015]"
                                    : "bg-white text-[#545a6d]"
                                }`}
                              >
                                <div className="flex items-center gap-2 lg:gap-0">
                                  <IconCheck
                                    className={
                                      selectedLink === "APPROVED"
                                        ? "text-[#fcc015]"
                                        : "text-[#545a6d]"
                                    }
                                  />
                                  <h2
                                    className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                                      selectedLink === "APPROVED"
                                        ? "text-[#fcc015]"
                                        : "text-[#41434b]"
                                    }`}
                                  >
                                    Accepted Candidates
                                  </h2>
                                </div>
                              </button>
                              <button
                                onClick={() =>
                                  handleAllEmployee("PENDING", "EMPLOYEE", "1")
                                }
                                className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                                  selectedLink === "1"
                                    ? "bg-white text-[#fcc015]"
                                    : "bg-white text-[#545a6d]"
                                }`}
                              >
                                <div className="flex items-center gap-2 lg:gap-0">
                                  <IconUserPlus
                                    className={
                                      selectedLink === "1"
                                        ? "text-[#fcc015]"
                                        : "text-[#545a6d]"
                                    }
                                  />
                                  <h2
                                    className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                                      selectedLink === "1"
                                        ? "text-[#fcc015]"
                                        : "text-[#41434b]"
                                    }`}
                                  >
                                    All Employers
                                  </h2>
                                </div>
                              </button>
                            </div>

                            <Link to="/admin-candidate-dashboard">
                              {" "}
                              <div className="onboarding py-4 pl-6 sm:pl-4 pr-4 flex items-center justify-between gap-3  ">
                                <div className="flex items-center gap-2">
                                  <img src={profilevectoricon} alt="" />
                                  <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                                    Candidates
                                  </h2>
                                </div>
                                <IconChevronDown
                                  size={18}
                                  onClick={() =>
                                    setIsCandidateOpen(!isCandidateOpen)
                                  }
                                />
                              </div>
                            </Link>
                          </div>
                        )}
                      </div>
                    )}

                    {currentPage === "candidates" &&
                      user?.customer.role !== "HR" && (
                        <div className="cursor-pointer ">
                          <Link to="/admin-interview-dashboard">
                            {" "}
                            <div className="py-4 pl-5 pr-4 flex items-center justify-between gap-3 ">
                              <div className="flex items-center gap-2">
                                <img src={Interviews} alt="" />
                                <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                                  Interview
                                </h2>
                              </div>
                              <IconChevronDown
                                size={18}
                                onClick={() => setIsOpen(!isOpen)}
                              />
                            </div>
                          </Link>

                          <div
                            className="onboarding py-4 pl-6 md:pl-4 sm:pl-4 pr-4 flex items-center justify-between gap-3 "
                            onClick={() => setIsCandidateOpen(!isCandidateOpen)}
                          >
                            <div className="flex items-center gap-2">
                              <img src={profilevectoricon} alt="" />
                              <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                                Candidates
                              </h2>
                            </div>
                            {isCandidateOpen ? (
                              <IconChevronUp
                                size={18}
                                onClick={() =>
                                  setIsCandidateOpen(!isCandidateOpen)
                                }
                              />
                            ) : (
                              <IconChevronDown
                                size={18}
                                onClick={() =>
                                  setIsCandidateOpen(!isCandidateOpen)
                                }
                              />
                            )}
                          </div>
                          <div
                            className={`openclosesection pl-5 transition flex items-stretch flex-col delay-500  duration-800 ${
                              isCandidateOpen ? "block" : "hidden"
                            }`}
                          >
                            {/* ... */}

                            <button
                              onClick={() => handleCandidate("HOLD_ON")}
                              className={`py-2 pl-6 lg:pl-6 pr-4 flex items-center justify-between gap-3 ${
                                selectedCandidateLink === "HOLD_ON"
                                  ? "bg-white text-[#fcc015]"
                                  : "bg-white text-[#545a6d]"
                              }`}
                            >
                              <div className="flex items-center gap-1">
                                <IconLoader3
                                  className={
                                    selectedCandidateLink === "HOLD_ON"
                                      ? "bg-white text-[#fcc015]"
                                      : "bg-white text-[#545a6d]"
                                  }
                                />
                                <h2
                                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                                    selectedCandidateLink === "HOLD_ON"
                                      ? "text-[#fcc015]"
                                      : "text-[#41434b]"
                                  }`}
                                >
                                  Pending Candidates
                                </h2>
                              </div>
                            </button>
                            <button
                              onClick={() => handleCandidate("CLEAR")}
                              className={`py-2 pl-6 lg:pl-6 pr-4 flex items-center justify-between gap-3 ${
                                selectedCandidateLink === "CLEAR"
                                  ? "bg-white text-[#fcc015]"
                                  : "bg-white text-[#545a6d]"
                              }`}
                            >
                              <div className="flex items-center gap-1">
                                <IconUserPlus
                                  className={
                                    selectedCandidateLink === "CLEAR"
                                      ? "bg-white text-[#fcc015]"
                                      : "bg-white text-[#545a6d]"
                                  }
                                />
                                <h2
                                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                                    selectedCandidateLink === "CLEAR"
                                      ? "text-[#fcc015]"
                                      : "text-[#41434b]"
                                  }`}
                                >
                                  Available Candidates
                                </h2>
                              </div>
                            </button>

                            <button
                              onClick={() => handleCandidate("NO_CLEAR")}
                              className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                                selectedCandidateLink === "NO_CLEAR"
                                  ? "bg-white text-[#fcc015]"
                                  : "bg-white text-[#545a6d]"
                              }`}
                            >
                              <div className="flex items-center gap-2">
                                <IconX
                                  className={
                                    selectedCandidateLink === "NO_CLEAR"
                                      ? "bg-white text-[#fcc015]"
                                      : "bg-white text-[#545a6d]"
                                  }
                                />
                                <h2
                                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                                    selectedCandidateLink === "NO_CLEAR"
                                      ? "text-[#fcc015]"
                                      : "text-[#41434b]"
                                  }`}
                                >
                                  Rejected Candidates
                                </h2>
                              </div>
                            </button>
                          </div>
                        </div>
                      )}
                    {user?.customer?.role === "SUPER-ADMIN" ||
                      (user?.customer?.role === "MANAGER" && (
                        <div className="addteammember ml-6 md:ml-[1rem] sm:ml-[0.9rem]  md:mt-4 ">
                          <div
                            className="flex items-center cursor-pointer gap-2 "
                            onClick={() =>
                              handleEmployerRequest("employer-Interview")
                            }
                          >
                            <IconUsers size={22} />
                            <h2
                              className={`text-[12px] lg:text-[12px] md:text-[12px] font-medium text-center ${
                                selectedLink === "employer-Interview"
                                  ? "text-[#fcc015]"
                                  : "text-[#545a6d]"
                              }`}
                            >
                              Employer Interviews
                            </h2>
                          </div>
                        </div>
                      ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex justify-between h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />
            <div className="2xl:hidden xl:hidden lg:hidden md:hidden">
              <AdminNavbar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobSideBar;
