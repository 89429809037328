import React, { Fragment, useEffect, useRef, useState } from "react";
import smallprofilecevtor from "../../../assets/images/smallprofilecevtor.png";
import companyempvector from "../../../assets/images/companyempvector.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchInterviewRequest } from "../../../store/interviewRequestSlice/interviewRequestSlice";
import Pagination from "../../../common/pagination/pagination";
import { Link, useNavigate } from "react-router-dom";
import {
  EMPLOYER_GIVE_RESULT,
  INTERVIEW_APPROVAL,
} from "../../../utils/baseUrl";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import Swal from "sweetalert2";
import { Dialog, Transition } from "@headlessui/react";
import ChangeProfileImage from "../../../assets/images/ChangeProfileImage.png";
import crossicon from "../../../assets/images/Icons/crossicon.svg";
import candidateDashboardSlice, {
  fetchCandidateAdmin,
} from "../../../store/candidateDashboardSlice/candidateDashboardSlice";
import { allUsersforAdmin } from "../../../store/allUsersforAdminSlice/usersforAdminSlice";
import Skeleton from "react-loading-skeleton";

const AdminPendingInterviews = ({ interviewapproval, interviewStatus }) => {
  const navigate = useNavigate();
  const [myId, setMyId] = useState("");

  const [isApiCallSuccessful, setIsApiCallSuccessful] = useState(false);
  const [myprofile, setMyProfile] = useState({});
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [openPic, setOpenPic] = useState(false);
  const isLoading = useSelector((state) => state?.userSlice?.loading);

  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);

  const interview = useSelector(
    (state) => state?.allUsersAdminSlice?.user?.content?.content
  );
  
  const page = useSelector(
    (state) => state?.allUsersAdminSlice?.user?.content?.pages
  );
  const mineInterview = useSelector(
    (state) => state?.allUsersAdminSlice?.user?.content?.total
  );

  const interviewRequestStatus = async (value, id) => {
    
    const paylod = {
      approval: value,
    };
    await axios
      .post(INTERVIEW_APPROVAL(id), paylod)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setIsApiCallSuccessful(!isApiCallSuccessful);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        
      });
  };

  useEffect(() => {
    dispatch(
      allUsersforAdmin({
        page: currentStep,
        role: interviewStatus,
        approval: interviewapproval,
      })
    );
  }, [dispatch, interviewapproval, isApiCallSuccessful, currentStep]);

  const AddProfile = () => {
    axios
      .post(EMPLOYER_GIVE_RESULT(myId), myprofile, {
        headers: {
          "Content-Type": "multipart/form-data", // Important for file uploads
        },
      })
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setOpenPic(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        
        setOpenPic(false);
      });
  };
  return (
    <>
      {/* Modal For open Pic */}
      <Transition.Root show={openPic} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenPic}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-[75%] sm:mt-24 sm:p-3">
                  <div className="">
                    <form
                    // onSubmit={AddProfile}
                    >
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Upload Result
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenPic(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:mt-0 sm:text-left">
                      <div className="items-center mx-8 sm:mx-2 mt-[1rem]">
                        <div className="mt-3 mb-10 sm:ml-4 sm:mt-0 sm:text-left ">
                          <div className="">
                            <div className="grid grid-cols-[1fr_1.75fr] sm:grid-cols-1 items-center justify-evenly gap-14 mt-4">
                              <div className="skillsfield ">
                                {/* <video
                controls
                className=" object-cover w-[200px] h-[200px] rounded-2xl "
              >
                <source
                  src={`${interview?.interview_video_url}`}
                  type="video/mp4"
                />
                Sorry, your browser doesn't support embedded videos.
              </video> */}
                                <label className=" left text-black  rounded-full cursor-pointer">
                                  <img
                                    src={ChangeProfileImage}
                                    alt=""
                                    className=""
                                  />
                                  <input
                                    type="file"
                                    name="video"
                                    onChange={(event) => {
                                      const file = event.target.files[0];

                                      setMyProfile({
                                        ...myprofile,
                                        video: file,
                                      });
                                    }}
                                    className="hidden"
                                  />
                                  Upload Your Video
                                </label>
                              </div>
                              <div className="flex items-center gap-3">
                                <select
                                  id="interview_number"
                                  name="interview_number"
                                  onChange={(e) => {
                                    setMyProfile({
                                      ...myprofile,
                                      interview_number: e.target.value,
                                    });
                                    
                                  }}
                                  className=" appearance-none text-base border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                  <option value="" disabled selected>
                                    Interview Number
                                  </option>
                                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                                    (item, index) => (
                                      <option key={index} value={index + 1}>
                                        {index + 1}
                                      </option>
                                    )
                                  )}
                                </select>
                                <select
                                  id="interview_clear"
                                  name="interview_clear"
                                  onChange={(e) => {
                                    setMyProfile({
                                      ...myprofile,
                                      interview_clear: e.target.value,
                                    });
                                  }}
                                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                  <option value="" disabled selected>
                                    Interview status
                                  </option>
                                  <option value="CLEAR">Clear</option>
                                  <option value="NO_CLEAR">No Clear</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <label
                          className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                          htmlFor="recruiter_comment"
                        >
                          Comment About Candidate
                        </label>
                        <textarea
                          rows={7}
                          type="text"
                          id="recruiter_comment"
                          placeholder="Tell about yourself..."
                          name="recruiter_comment"
                          onChange={(e) => {
                            setMyProfile({
                              ...myprofile,
                              recruiter_comment: e.target.value,
                            });
                          }}
                          className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={AddProfile}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="Onboarding  py-12">
        <h2 className="text-[#495057] text-[16px]">Candidate List</h2>
        {interview && interview?.length > 0 ? (
          <>
            <div className="grid grid-cols-[0.60fr_1.70fr_1fr_1.7fr_3fr]  lg:grid-cols-[0.60fr_1.70fr_1fr_1.7fr_3fr]  2xl:grid-col-[0.60fr_1.70fr_1fr_1.7fr_3fr]  md:grid-cols-1 sm:grid-cols-1 border-[1px] md:gap-6 md:text-center sm:text-center items-center shadow-xl mb-4 mr-6 py-6 bg-[#FCC015]  mt-7 md:hidden sm:hidden ">
              <div className="text-[13px] pl-10 text-white font-medium">
                Sr#
              </div>
              <h2 className="text-[13px] text-white font-medium">Candidate</h2>
              <h2 className="text-[13px] text-white font-medium">Date</h2>
              <h2 className="text-[13px] text-white font-medium">BMG</h2>
              <h2 className="text-[13px] text-white font-medium">Actions</h2>
            </div>
            {isLoading ? (
              <div className="skeleton-loader ">
                <Skeleton width={1000} count={10} />
              </div>
            ) : (
              <div>
                {interview?.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="onboardinglist border-[1px] bg-white grid grid-cols-[0.60fr_1.70fr_1fr_1.7fr_3fr] lg:grid-cols-[0.60fr_1.70fr_1fr_1.7fr_3fr]  2xl:grid-col-[0.60fr_1.70fr_1fr_1.7fr_3fr]  md:grid-cols-1 sm:grid-cols-1 md:gap-6 md:text-center sm:text-center items-center shadow-xl mb-3 mr-6 py-5">
                      <div className="candidateNumber">
                        <h2 className="text-[14px] pl-10 md:pl-0 sm:pl-0 font-semibold text-[#111827]">
                          {index < 9 ? `0${index + 1}` : index + 1}
                        </h2>
                      </div>
                      <div className="candidateinfo flex items-center md:justify-center sm:justify-center sm:pb-4 gap-2">
                        <img src={smallprofilecevtor} alt="" />
                        <div>
                          <h2 className="text-[13px] text-black font-medium">
                            {item.first_name} {item.last_name}
                          </h2>
                          <p className="text-[#696969] font-normal text-[10px] sm:text-left
                          ">
                            {item.role}
                          </p>
                        </div>
                      </div>
                      <div className="date sm:pb-4">
                        <h2 className="text-[13px] text-[#495057] font-semibold">
                          {item.zoom_meeting_date}
                        </h2>
                      </div>
                      <div className="company flex sm:flex-col items-center md:justify-center sm:justify-center gap-6">
                        <img src={companyempvector} alt="" />
                        <div>
                          <h2 className="text-[13px] text-black font-medium">
                            {/* {item.companyName} */}
                            BMG
                          </h2>
                          <p className="text-[#696969] font-normal text-[10px]">
                            {/* Add company details here */}
                            Lorem Ipsum Dolor
                          </p>
                        </div>
                      </div>

                      <div className="BMGcompany flex sm:flex-col items-center gap-2 md:justify-center">
                        <button
                          onClick={() => {
                            navigate(`/candidate-profile-admin/${item?._id}`, {
                              state: { item: item },
                            });
                          }}
                          className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[10px]"
                        >
                          View Profile
                        </button>
                        {interviewapproval === "APPROVED" && (
                          <button
                            onClick={() => {
                              setOpenPic(true);
                              setMyId(item?._id);
                            }}
                            className="bg-white hover:bg-[#EAB108] hover:text-white border-[1px] border-[#EAB108] flex gap-1 items-center justify-center text-black px-[0.1rem] w-[8rem] py-[5px]  text-[10px]"
                          >
                            Give Feedback
                          </button>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center">
            <h2 className="text-3xl py-4 text-[#495057]">No Results Found!</h2>
          </div>
        )}

        {mineInterview > 12 ? (
          <Pagination
            totalPages={page}
            currentPage={currentStep}
            setCurrentPage={setCurrentStep}
          />
        ) : null}
      </div>
    </>
  );
};

export default AdminPendingInterviews;
