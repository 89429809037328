import { useContext, useEffect } from "react";
import { ClientContext } from "../Context/globalContext";
import { Modal } from "antd";
import logo from "../../../assets/images/logo.png";
import Session from "./Session.css";
const Preview = ({ handleOk }) => {
  const uitoolkit = useContext(ClientContext);
// 
  useEffect(() => {
    let previewContainer = document.getElementById("preview");
    uitoolkit.uitoolkit.openPreview(previewContainer);
    return () => uitoolkit.uitoolkit.closePreview(previewContainer);
  }, [uitoolkit]);
  return (
    <>
      <div className="container mx-auto flex justify-between mt-2">
        <img src={logo} alt="" />
      
      </div>
      <div className="zoom-sdk">
        <Modal
          open={true}
          title="Preview"
          onOk={handleOk}
          width={700}
          footer={(_, { OkBtn }) => (
            <>
              <OkBtn />
            </>
          )}
        >
          <div id="preview"></div>
        </Modal>
      </div>
    </>
  );
};

export default Preview;
