import React, { useEffect, useState } from "react";
import connectbmg from "../../../assets/images/Icons/connectbmg.svg";
import redheart from "../../../assets/images/Icons/redheart.svg";
import greenheart from "../../../assets/images/Icons/greenheart.svg";
import Flag from "../../../assets/images/Flag.png";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LogOutUserReducer } from "../../../store/loginSlice/loginSlice";
import logouticon from "../../../assets/images/Icons/logouticon.svg";
import logo from "../../../assets/images/logo.png";
import blackheart from "../../../assets/images/blackheart.png";
import Skeleton from "react-loading-skeleton";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import {
  CANDIDATE_PROFILE,
  EMPLOYER_SHORTLIST_CANDIDATE,
} from "../../../utils/baseUrl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const CandidateProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoading = useSelector((state) => state?.userSlice?.loading);

  const [user, setUser] = useState();
  
  const logoutHandler = () => {
    dispatch(LogOutUserReducer());
    navigate("/login");
  };
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      axios.get(CANDIDATE_PROFILE(id)).then((response) => {
        setUser(response?.data);
      });
    } else {
      navigate(`/public-profile?candidate=${id}`);
    }
  }, []);

  const shortlistCandidate = async (value) => {
    const payload = {
      type: value,
    };
    await axios
      .post(EMPLOYER_SHORTLIST_CANDIDATE(id), payload)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response?.data?.message,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  return (
    <>
      <div className="nav-sec bg-white  flex items-center sm:flex-col gap-2 sm:justify-center md:max-w-[94%] sm:max-w-[94%] sm:mx-2 justify-between py-3 px-6">
        <img src={logo} className="ml-16 pt-2" />
        {/* <div className="connect">
          <Link to={`/schedular/${id}`}>
            {" "}
            <button className="bg-black border-2 flex gap-2 items-center border-black text-white px-10 py-2 rounded text-[14px]">
              <img src={connectbmg} alt="connectBmg" />
              Connect BMG
            </button>
          </Link>
        </div> */}
      </div>
      {user && (
        <div className="md:max-w-[91%] sm:max-w-[91%] md:mx-6 sm:mx-6">
          <div className="grid 2xl:grid-cols-[300px_1fr] bg-[#F8F8FB] xl:grid-cols-[300px_1fr] lg:grid-cols-[280px_1fr] md:grid-cols-1 ">
            <div className="leftsection bg-white rounded flex flex-col justify-between h-full py-12  px-6">
              <div className="top">
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={150} count={4} />
                  </div>
                ) : (
                  <img
                    src={`https://api.bmgtalentplus.com.sg/profile/${user?.content?.profile_picture}`}
                    alt="profilePicture"
                    className="w-[100px] h-[100px] rounded-full object-cover mx-auto"
                  />
                )}

                <div className="candidateinfo bg-[#f9f9f9] border-[1px] mt-10 rounded-md py-1 px-4 flex flex-col items-center justify-center  ">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <Skeleton width={100} count={1} />
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <h2 className="text-md text-black font-semibold">
                        {user?.content?.first_name} {user?.content?.last_name}
                      </h2>
                      <p className="text-[12px] text-center font-normal text-[#696969]">
                        {user?.content?.title}
                      </p>
                    </div>
                  )}
                </div>
                <div className="candidateinfo bg-[#f9f9f9] border-[1px] mt-5 rounded-md py-1 px-4 flex flex-col items-center justify-center  ">
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <Skeleton width={100} count={1} />
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <h2 className="text-md text-black text-center font-semibold">
                        Email:
                      </h2>
                      <h2 className="text-sm text-[#696969] font-medium">
                        {user?.content?.email}
                      </h2>
                    </div>
                  )}
                </div>

                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={100} count={1} />
                  </div>
                ) : (
                  <div className="status mt-5">
                    <div className="flex items-center gap-2 mt-2">
                      <img src={Flag} alt="" />
                      <h2 className="text-[14px] text-[#696969] capitalize font-normal">
                        {user?.content?.country}
                      </h2>
                    </div>
                  </div>
                )}

                <div className="languages mt-8">
                  <h2 className="text-[16px] text-black font-semibold">
                    Languages
                  </h2>
                  {isLoading ? (
                    <div className="skeleton-loader">
                      <Skeleton width={150} count={4} />
                    </div>
                  ) : (
                    <div className="flex flex-col items-left w-[80%] md:w-[20%] sm:w-[44%] gap-4 mt-3 ">
                      {user?.content?.language?.map((item, index) => (
                        <h2
                          key={index}
                          className="relative 2xl:text-[13px] capitalize xl:text-[13px] lg:text-[12px] md:text-[10px] text-[10px] text-white bg-gradient-to-l from-[#E9AA06] to-[#FFC107] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                        >
                          {item.lan} : {item.spoking}
                        </h2>
                      ))}
                    </div>
                  )}
                </div>

                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={150} count={4} />
                  </div>
                ) : (
                  <div className="mt-10 flex flex-col w-[78%] sm:flex-col sm:justify-center sm:w-2/5 gap-2">
                    <div
                      className="bg-[#F7E7BC] shadow-lg cursor-pointer flex items-center gap-[0.3rem] py-1 px-[3px]"
                      onClick={() => shortlistCandidate("selected")}
                    >
                      <img src={redheart} alt="" />
                      <h2 className="text-[12px] font-medium">Shortlist</h2>
                    </div>
                    <div
                      className="bg-[#F7E7BC] shadow-lg flex items-center gap-1 py-1 cursor-pointer px-[3px]"
                      onClick={() => shortlistCandidate("interested")}
                    >
                      <img src={greenheart} alt="" />
                      <h2 className="text-[12px] font-medium">Considerable</h2>
                    </div>
                    <div
                      className="bg-[#F7E7BC] shadow-lg flex items-center gap-1 py-1 cursor-pointer  px-[3px]"
                      onClick={() => shortlistCandidate("rejected")}
                    >
                      <img src={blackheart} alt="" className="accent-black" />
                      <h2 className="text-[12px] font-medium">Rejected</h2>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="flex gap-3 items-center justify-center"
                onClick={logoutHandler}
              >
                <button className="text-center text-[18px] font-medium text-[#BA0000]">
                  Logout
                </button>
                <img src={logouticon} alt="" className="cursor-pointer" />
              </div>
            </div>
            <div className="rightsection mx-6 mt-6 pb-12 ">
              <h2 className="text-[#495057] text-[16px] font-semibold pb-4 ">
                Candidate Profile
              </h2>
              {/* <video  src={`https://api.bmgtalentplus.com.sg/profile/${user?.content?.interview_video_url}`} alt="" className="xl:w-full 2xl:w-full" /> */}
              <video
                controls
                className="xl:w-full 2xl:w-full h-[30rem] rounded-md object-cover"
              >
                <source
                  src={`${user?.content?.interview_video_url}`}
                  type="video/mp4"
                />
                Sorry, your browser doesn't support embedded videos.
              </video>

              <div className="aboutsection border-[1px] bg-white rounded-md py-12 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium">
                  About Me
                </h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={2} />
                  </div>
                ) : (
                  <p className="text-[15px] font-medium leading-[36px] pt-2 pr-16 text-[#696969]">
                    {user?.content?.about_me}
                  </p>
                )}
              </div>
              <div className="workexperience border-[1px] bg-white rounded-md py-14 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium">
                  Work Experience
                </h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={6} />
                  </div>
                ) : (
                  <div>
                    {user?.content?.experience?.map((exp, index) => (
                      <div
                        key={index}
                        className=" px-[1rem] pt-[2px] pb-[5px] border-solid border-b-[1px] rounded-sm border-[#eeeeee] "
                      >
                        <p className="text-[14px] font-medium leading-[36px] pt-2 pr-16 text-[#696969]">
                          {exp.designation} | {exp.company} |
                        </p>
                        <p className="text-[14px] font-medium leading-[36px] pr-16 text-[#E9AA06]">
                          {exp.start_date} {exp.end_date}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="Skills border-[1px] relative bg-white rounded-md py-10 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium pb-4">
                  Skills
                </h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={3} />
                  </div>
                ) : (
                  <div className="max-h-[220px] min-h-[220px]  rounded-md overflow-y-auto ">
                    <h2 className="text-gray-700 text-[16px] text-left px-4 pt-4">
                      Tech Skills
                    </h2>
                    {user?.content?.skill?.length > 0 ? (
                      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                        {user?.content?.skill?.map(
                          (title, index) =>
                            title.type === "TECH" && (
                              <h2
                                key={index}
                                className="relative text-[13px] text-center py-[9px]   border-[1px] rounded-md bg-white"
                              >
                                {title.name} {title.rating}/10
                              </h2>
                            )
                        )}
                      </div>
                    ) : (
                      <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                        Candidate does not have tech skills.
                      </p>
                    )}
                  </div>
                )}
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={3} />
                  </div>
                ) : (
                  <div className="max-h-[220px] min-h-[220px] rounded-md  mt-4 overflow-y-auto ">
                    <h2 className="text-gray-700 text-[16px] text-left px-4 pt-4">
                      Soft Skills
                    </h2>
                    {user?.content?.skill?.length > 0 ? (
                      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                        {user?.content?.skill?.map(
                          (title, index) =>
                            title.type === "SOFT" && (
                              <h2
                                key={index}
                                className="relative text-[13px] text-center py-[9px]   border-[1px] rounded-md bg-white"
                              >
                                {title.name} {title.rating}/10
                              </h2>
                            )
                        )}
                      </div>
                    ) : (
                      <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                        Candidate does not have soft skills.
                      </p>
                    )}
                  </div>
                )}
              </div>

              {/* <div className="Skills border-[1px] bg-[#f9f9f9] rounded-md shadow-md py-10 px-14 mt-6">
                <h2 className="text-[22px] text-black font-medium">Skills</h2>
                <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-12 justify-center items-center pr-16 mt-7 ">
                  {user?.content?.skill?.map((title, index) => (
                    <h2
                      key={index}
                      className="text-[10px] text-center py-[6px] 2xl:px-10  border-2 rounded-md bg-white"
                    >
                      {title.name}
                    </h2>
                  ))}
                </div>
              </div> */}
              <div className="education border-[1px] bg-white rounded-md py-14 px-14 mt-6">
                <h2 className="text-[22px] text-[#495057] font-medium">
                  Education
                </h2>
                {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={800} count={4} />
                  </div>
                ) : (
                  <div>
                    {user?.content?.education?.map((edu, index) => (
                      <div
                        key={index}
                        className="flex items-baseline my-3 justify-between px-[1rem] pt-[2px] pb-[10px] border-b-[1px] p-1  "
                      >
                        <div>
                          <p className="text-[14px] font-medium leading-[25px]  pr-16 text-[#696969]">
                            {edu.degree} | {edu.university} |
                          </p>
                          <p className="text-[14px] font-medium leading-[25px] pr-16 text-[#E9AA06]">
                            {edu.start_date} {edu.end_date}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="mt-5 flex justify-center items-center">
                <Link to={"/top-candidate"}>
                  <button className="bg-black text-white hover:bg-white  border-black hover:text-black border-2 flex gap-2 items-center px-10 py-2 rounded text-lg">
                    {/* <img src={whitearrowleft} alt="" /> */}
                    Back To Applications
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CandidateProfile;
