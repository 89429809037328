import React, { Fragment, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import phoneIcon from "./../../../../assets/images/Icons/phoneIcon.svg";
import Vector from "../../../../assets/images/Icons/email.svg";
import Password from "../../../../assets/images/Icons/Password 2.svg";
import logo from "../../../../assets/images/logo.png";
import nameicon from "../../../../assets/images/nameicon.png";
import Company from "../../../../assets/images/Company.png";
import crossicon from "../../../../assets/images/Icons/crossicon.svg";
import country from "../../../../assets/images/country.png";
import registration from "../../../../assets/images/registration.png";
import empoyersignup from "../../../../assets/images/empoyersignup.png";
import arrowright from "../../../../assets/images/arrowright.png";
import { Link, useNavigate } from "react-router-dom";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { REGISTER } from "../../../../utils/baseUrl";
import axios from "../../authenticateApi/authenticateApi";
import bgimg from "../../../../assets/images/bgnew2.png";
import maleAvatar from "../../../../assets/images/male-avatar.jpeg";
import ChangeProfileImage from "../../../../assets/images/ChangeProfileImage.png";
import Button from "../../../../common/commonButton/button";
import { NumericFormat } from "react-number-format";
import { LogInUserHandler } from "../../../../store/loginSlice/loginSlice";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Dialog, Transition } from "@headlessui/react";
import Asterik from "../../../../common/asterik";
const validationSchema = Yup.object({
  first_name: Yup.string().required("Please enter your First Name"),
  last_name: Yup.string().required("Please enter your Last Name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your e-mail address"),
  cell_phone: Yup.string()
    .min(9, "Phone number must be at least 9 digits")
    .max(13, "Phone number must be at most 13 digits")
    .required("Phone number is required"),
  password: Yup.string().required("please enter your Password"),
  company_name: Yup.string().required("please enter your company name"),
  country: Yup.string().required("please choose your country name"),
  registration_number: Yup.string().required(
    "please enter your Registration number"
  ),
});

const EmployerSignUp = () => {
  const dispatch = useDispatch();
  const [myprofile, setMyProfile] = useState();
  const [changeImage, setChangeImage] = useState();
  const [openPic, setOpenPic] = useState(false);
  const [backendMessage, setBackendMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      cell_phone: "",
      password: "",
      company_name: "",
      country: "",
      registration_number: "",
      role: "EMPLOYEE",
    },
    validationSchema,
    onSubmit: async (values,formik) => {





      const countryCode = getCountryCode(values.country);
      const number=countryCode.substring(1)
      const modifiedCellPhone = `${number}${values.cell_phone}`;
    
      const updatedValues = {
        ...values,
        cell_phone: modifiedCellPhone,
        // profile: myprofile.profile,
      };
      await axios
        .post(REGISTER, updatedValues, {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        })
        .then((response) => {
          // if (response?.data?.success) {
          //   Swal.fire({
          //     icon: "success",
          //     title: `${response?.data?.message}`,
          //     showConfirmButton: false,
          //     timer: 1500,
          //   });

          // } else {
          //   Swal.fire({
          //     icon: "error",
          //     title: "Oops...",
          //     text: `${response?.data?.message}`,
          //   });
          // }
          if (response?.data?.success) {
            const loginCredentials = {
              email: values.email,
              password: values.password,
            };
            dispatch(LogInUserHandler(loginCredentials));
            navigate(
              `/otp?phoneNumber=${values.cell_phone}&firstname=${response?.data?.content?.customer?.first_name}&last_name=${response?.data?.content?.customer?.last_name}`
            );
          } else {
            setBackendMessage(response?.data?.message);
          }
        })
        .catch((error) => {
          setBackendMessage(error?.response?.data?.message);
          console.error("API call failed:", error);
        });
    },
  });

  const getCountryCode = (country) => {
    switch (country) {
      case "singapore":
        return "+65";
      case "malaysia":
        return "+60";
      default:
        return undefined;
    }
  };
  const cancelButtonRef = useRef(null);
  return (
    <>
   
      <div className="login-page-container grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1  overflow-y-auto ">
        <div className="left">
          <img src={logo} alt="" className="pl-8 pt-4" />
          <div className="2xl:py-[3.5rem] xl:py-[6rem] lg:py-[6rem] py-[6rem] 2xl:px-36 xl:px-28 lg:px-14 md:px-8 sm:px-6 md:pr-[5rem]  bg-white sm:py-[6.5rem] sm:w-[100%] sm:bg-inherit">
            <h1 className="font-semibold text-[30px] mb-[64px] ">
              Create an Account
            </h1>
            <form onSubmit={formik.handleSubmit}>
           
              <div className="mb-[20px]">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="first_name"
                >
                  <img src={nameicon} alt="email" className="mr-2" /> First Name <Asterik />
                </label>
                <input
                  type="text"
                  id="first_name"
                  placeholder="First name"
                  name="first_name"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.first_name}
                  </p>
                ) : null}
              </div>
              <div className="mb-[20px]">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="last_name"
                >
                  <img src={nameicon} alt="name" className="mr-2" /> Last Name <Asterik />
                </label>
                <input
                  type="text"
                  id="last_name"
                  placeholder="Last Name"
                  name="last_name"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.last_name}
                  </p>
                ) : null}
              </div>
              <div className="mb-[20px]">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="email"
                >
                  <img src={Vector} alt="email" className="mr-2" /> Email 
                  Address <Asterik />
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.email}
                  </p>
                ) : null}
              </div>
              <div className="mb-[30px]">
                <div className="w-full ">
                  <label
                    className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                    htmlFor="country"
                  >
                    <img src={country} alt="email" className="mr-2" /> Country <Asterik />
                  </label>
                  <div className="relative">
                    <select
                      id="select"
                      name="country"
                      value={formik.values.country} // Set the value to formik's state
                      onChange={formik.handleChange} // Handle change to update the state
                      className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled>
                        Country
                      </option>
                      <option value="singapore">Singapore</option>
                      <option value="malaysia">Malaysia</option>
                    </select>
                  </div>
                </div>

                {formik.touched.country && formik.errors.country ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.country}
                  </p>
                ) : null}
              </div>
         
              <div className="mb-[30px]">
                <div className="w-full ">
                  <label
                    className="text-gray-700 text-sm font-bold mb-2 flex items-center"
                    htmlFor="country"
                  >
                    <img src={phoneIcon} alt="phone" className="mr-2" /> Phone <Asterik />
                  </label>
                  <div className="flex items-center ">
                    <span className=" appearance-none border rounded h-[54px] w-[50px] py-4 px-2  text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                      {getCountryCode(formik.values.country)}
                    </span>
                    <NumericFormat
                      type="text"
                      id="cell_phone"
                      placeholder="Phone Number"
                      name="cell_phone"
                      maxLength={12}
                      onChange={formik.handleChange}
                      value={formik.values.cell_phone}
                      disabled={!formik.values.country}
                      className="appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  {formik.touched.cell_phone && formik.errors.cell_phone ? (
                    <p className="text-xs mt-1 text-red-500">
                      {formik.errors.cell_phone}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mb-6 relative">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="password"
                >
                  <img src={Password} alt="password" className="mr-2" />{" "}
                  Password <Asterik />
                </label>
                <input
                  type={passwordShown ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <button
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center text-sm leading-5"
                  type="button"
                >
                  {passwordShown ? (
                    <IconEyeOff size={24} />
                  ) : (
                    <IconEye size={24} />
                  )}
                </button>
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.password}
                  </p>
                ) : null}
              </div>
              <div className="mb-[30px]">
                <div className="w-full">
                  <label
                    className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                    htmlFor="company"
                  >
                    <img src={Company} alt="email" className="mr-2" /> Company <Asterik />
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company_name"
                    placeholder="Company Name"
                    onChange={formik.handleChange}
                    value={formik.values.company_name}
                    className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  {formik.touched.company_name && formik.errors.company_name ? (
                    <p className="text-xs mt-1 text-red-500">
                      {formik.errors.company_name}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mb-[20px]">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="registration_number"
                >
                  <img
                    src={registration}
                    alt="registrationIcon"
                    className="mr-2"
                  />
                  Company Registration No <Asterik />
                </label>
                <input
                  type="text"
                  id="registration_number"
                  placeholder="201688888A"
                  name="registration_number"
                  onChange={formik.handleChange}
                  value={formik.values.registration_number}
                  className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {formik.touched.registration_number &&
                formik.errors.registration_number ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.registration_number}
                  </p>
                ) : null}
              </div>

              <h2 className="text-red-600">{backendMessage}</h2>
              <button
                type="submit"
                className="flex justify-center items-center  bg-gradient-to-l from-[#E9AA06] to-[#FFC107]  hover:bg-blue-700 w-full text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Sign Up
              </button>
            </form>
            <p className="text-[#9CA3AF] text-sm font-medium leading-[19px] mt-4 capitalize">
              By creating an account, you agree to the{" "}
              <span className="text-[#E9AA06]">Terms of Service.</span> For more
              information about BMG's privacy practices, see the BMG{" "}
              <span className="text-[#E9AA06]">Privacy Statement.</span> We'll
              occasionally send you account-related emails.
            </p>
            <p className="mt-4 mr-8 text-center text-[#6B7280] ">
              Already have an account ?{" "}
              <Link to={"/login"} className="text-[#E9AA06] font-semibold ">
                Log in
              </Link>
            </p>
          </div>
        </div>
        <div className="right sm:hidden">
          <img
            src={bgimg}
            alt=""
            className="h-[1300px] w-full 2xl:block xl:block lg:block md:block sm:hidden hidden"
          />
        </div>
      </div>
    </>
  );
};

export default EmployerSignUp;
