// import { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { UserContext } from "../Context/globalContext";
// import { Form, Input, Button, Checkbox } from "antd";
// import "./Home.css";

// const Home = () => {
//   const [username, updateUsername] = useState("");
//   const [password, updatePassword] = useState("");
//   const [email, setEmail] = useState("");
//   const meetingArgs = useContext(UserContext);

//   const navigate = useNavigate();

//   const submitUserData = async () => {
//     meetingArgs.userName = username;
//     meetingArgs.sessionPasscode = password;

//     // const jwtConfig = {
//     //   topic: meetingArgs.sessionName,
//     //   name: meetingArgs.userName,
//     //   password: meetingArgs.sessionPasscode,
//     //   sessionKey: meetingArgs.sessionPasscode,
//     //   roleType: 1,
//     // };

//     const jwtConfig = {
//       sessionName: meetingArgs.sessionName,
//       userIdentity: meetingArgs.userName,
//       username: meetingArgs.userName,
//       email:email,
//       password: meetingArgs.sessionPasscode,
//       sessionKey: meetingArgs.sessionPasscode,
//       role: 1,
//     };

//     //add JWT and initialilzation functionality
//     const requestOptions = {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(jwtConfig),
//     };
// 
//     let response = await fetch("https://api.bmgtalentplus.com.sg/auth/zoom_token", requestOptions);
//     
//     let sig = await response.json();
//     
//     meetingArgs.videoSDKJWT = sig?.content?.signature;
//     
//     navigate("/zoom-session");
//   };

//   return (
//     <div className="login-page">
//       <div className="login-box">
//         <div>
//           <Form
//             name="login-form"
//             ialValues={{
//               remember: true,
//             }}
//             onFinish={submitUserData}
//             // onFinishFailed={onSubmitFailed}
//             autoComplete="off"
//           >
//             <p>Login to the Dashboard</p>
//             <Form.Item
//               label="Username"
//               name="username"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input your username!",
//                 },
//               ]}
//             >
//               <Input onChange={(e) => updateUsername(e.target.value)} />
//             </Form.Item>
//             <Form.Item
//               label="Email"
//               name="email"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input your email!",
//                 },
//               ]}
//             >
//             <Input onChange={(e) => setEmail(e.target.value)} />
//             </Form.Item>

//             <Form.Item
//               label="Password"
//               name="password"
//               rules={[
//                 {
//                   required: true,
//                   message: "Please input your password!",
//                 },
//               ]}
//             >
//               <Input.Password
//                 onChange={(e) => updatePassword(e.target.value)}
//               />
//             </Form.Item>

//             <Form.Item
//               wrapperCol={{
//                 offset: 8,
//                 span: 16,
//               }}
//             >
//               <button  className="bg-[#FFC107] text-white hover:bg-white rounded-full border-2 py-2 px-4 hover:text-black" htmlType="submit">
//                 Submit
//               </button>
//             </Form.Item>
//           </Form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Home;

import { useFormik } from "formik";
import * as Yup from "yup";
import Vector from "./../../../assets/images/Icons/email.svg";
import Password from "./../../../assets/images/Icons/Password 2.svg";
import logo from "./../../../assets/images/logo.png";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import bgimg from "../../../assets/images/bgnew2.png";
import user from "./../../../assets/images/user.png";

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Context/globalContext";
import "./Home.css";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your e-mail address"),
  password: Yup.string().required("please enter your Password"),
});

const Home = () => {
  const [backendMessage,setBackendMessage]=useState("")
  const [passwordShown, setPasswordShown] = useState(false);
  const [username, updateUsername] = useState("");
  const [password, updatePassword] = useState("");
  const [email, setEmail] = useState("");
  const meetingArgs = useContext(UserContext);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        // 
        meetingArgs.userName = values.username;
        meetingArgs.sessionPasscode = values.password;

        const jwtConfig = {
          sessionName: meetingArgs.sessionName,
          userIdentity: meetingArgs.userName,
          username: meetingArgs.userName,
          email: values.email,
          password: meetingArgs.sessionPasscode,
          sessionKey: meetingArgs.sessionPasscode,
          role: parseInt(meetingArgs.role, 10),
          telemetry_tracking_id : meetingArgs.telemetry_tracking_id,
          cloud_recording_option : meetingArgs.cloud_recording_option,
          cloud_recording_election:meetingArgs.cloud_recording_election
        };

        let response = await axios.post("/auth/zoom_token", jwtConfig);
        
        if(response?.data?.success){
        let sig = await response;
        // 
        meetingArgs.videoSDKJWT = sig?.data?.content?.signature;
        // 
        navigate("/zoom-session");
        }else{
          setBackendMessage(response?.data?.message)
        }
      } catch (error) {
        
      }
    },
  });

  return (
    <div className="login-page-container grid 2xl:grid-cols-[1fr_1.35fr] xl:grid-cols-[1fr_1.35fr] lg:grid-cols-[1fr_1.15fr] md:grid-cols-[1fr_1fr] sm:grid-cols-1 grid-cols-1 h-screen overflow-hidden ">
      <div className="left">
        <img src={logo} alt="" className="pl-8 pt-4" />
        <div className="2xl:py-[7.5rem] xl:py-[5rem] lg:py-[6rem] py-[3rem] 2xl:px-36 xl:px-24 lg:px-10 md:px-10 sm:px-12 px-6  md:pr-[1rem] md:w-[80%]  bg-white sm:py-[6.5rem] sm:w-[100%]">
          <h1 className="font-semibold text-[30px] xl:text-[24px] lg:text-[24px] md:text-[18px] sm:text-[15px] mb-[44px] xl:mb-[40px] ">
            Zoom Credentials
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-[20px]">
              <label
                className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                htmlFor="email"
              >
                <img src={Vector} alt="email" className="mr-2" /> Email Address
              </label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
            <div className="mb-[20px]">
              <label
                className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                htmlFor="userName"
              >
                <img src={Vector} alt="userName" className="mr-2" /> User Name
              </label>
              <input
                type="text"
                id="username"
                placeholder="username..."
                name="username"
                onChange={formik.handleChange}
                value={formik.values.username}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
            <div className="mb-10 xl:mb-10 lg:mb-8 md:mb-5 sm:mb-5 relative">
              <label
                className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                htmlFor="password"
              >
                <img src={Password} alt="password" className="mr-2" /> Password
              </label>
              <input
                type={passwordShown ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 top-8 pr-3 flex items-center text-sm leading-5"
                type="button"
              >
                {passwordShown ? (
                  <IconEyeOff size={24} />
                ) : (
                  <IconEye size={24} />
                )}
              </button>
              {formik.touched.password && formik.errors.password ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.password}
                </p>
              ) : null}
            </div>
              <p className=" text-red-500">{backendMessage}</p>
            <button
              type="submit"
              className="group transition-all bg-gradient-to-l from-[#E9AA06] to-[#FFC107] w-full text-black font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline "
            >
              Log In
            </button>
          </form>
        </div>
      </div>
      <div className="right sm:hidden ">
        <img
          src={bgimg}
          alt=""
          className="w-full h-[1000px] 2xl:block xl:block lg:block md:block sm:hidden hidden"
        />
      </div>
    </div>
  );
};

export default Home;
