import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useFormik } from "formik";
import maleAvatar from "./../../../../assets/images/male-avatar.jpeg";
import ChangeProfileImage from "./../../../../assets/images/ChangeProfileImage.png";
// import ScrollIntoView from 'react-scroll-into-view';
import smallcross from "./../../../../assets/images/smallcross.png";
import logo from "./../../../../assets/images/logo.png";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import calendarevent from "./../../../../assets/images/Icons/calendar-event.svg";
import article from "./../../../../assets/images/Icons/article.svg";
import info from "./../../../../assets/images/Icons/info-square-filled.svg";
import gender from "./../../../../assets/images/Icons/gender-agender.svg";
import language from "./../../../../assets/images/Icons/language.svg";
import circlefilled from "./../../../../assets/images/Icons/circles-filled.svg";
import Location from "./../../../../assets/images/Icons/Location.svg";
import Phone from "./../../../../assets/images/Icons/Phone.svg";
import videoicon from "./../../../../assets/images/videoicon.png";
import clock from "./../../../../assets/images/clock.png";
import user from "./../../../../assets/images/user.png";
import backarrowsvg from "./../../../../assets/images/Icons/backarrowsvg.svg";
import Vector from "./../../../../assets/images/Icons/email.svg";
import rightArrowBlack from "./../../../../assets/images/Icons/rightArrowBlack.svg";
import nameIcon from "./../../../../assets/images/Icons/nameIcon.svg";
import phoneIcon from "./../../../../assets/images/Icons/phoneIcon.svg";
import passwordIcon from "./../../../../assets/images/Icons/Password 2.svg";
import addressIcon from "./../../../../assets/images/Icons/address.svg";
import CandidateNavbar from "../../../../common/candidateNavbar";
import { Link, useNavigate } from "react-router-dom";
import { IconEye, IconEyeOff, IconInfoCircleFilled } from "@tabler/icons-react";
import titleicon from "../../../../assets/images/Icons/titleicon.svg";
import degreeicon from "../../../../assets/images/Icons/degreeicon.svg";
import Stepper from "../../../../common/stepper/stepper";
import workIcon from "./../../../../assets/images/Icons/workExperience.svg";
import educationIcon from "./../../../../assets/images/Icons/Education.svg";
import faqIcon from "./../../../../assets/images/Icons/faq.svg";
import openArrow from "./../../../../assets/images/Icons/accordianOpenArrow.svg";
import closeArrow from "./../../../../assets/images/Icons/accordianCloseArrow.svg";
import calendaricon from "./../../../../assets/images/Icons/calendaricon.svg";
import "react-datepicker/dist/react-datepicker.css";
import faqTablet from "./../../../../assets/images/faqTablet.png";
import buildingicon from "./../../../../assets/images/Icons/buildingicon.svg";
import crossicon from "../../../../assets/images/Icons/crossicon.svg";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import rating from "../../../../assets/images/Icons/rating.svg";
import {
  CANDIDATE_PICTURE,
  CANDIDATE_SCHEDULAR_TIME,
  EMPLOYER_SCHEDULAR,
  REGISTER,
  SCHEDULAR,
} from "../../../../utils/baseUrl";
import axios from "../../authenticateApi/authenticateApi";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import { Tooltip } from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconTrashFilled } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import Asterik from "../../../../common/asterik";
import iconnn from "../../../../assets/images/Icons/iconnn.svg";
import Edit from "../../../../assets/images/Edit.png";


const validationSchemaStep1 = Yup.object({
  first_name: Yup.string().required("please enter your First Name"),
  last_name: Yup.string().required("please enter your Last Name"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your e-mail address"),
  password: Yup.string().required("please enter your Password"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  cell_phone: Yup.string()
    .min(9, "Phone number must be at least 9 digits")
    .max(15, "Phone number must be maximum 15 digits")
    .required("Phone number is required"),
  date_of_birth: Yup.string().required("please enter your Date of Birth"),
  gender: Yup.string().required("please enter your Gender"),
  city: Yup.string().required("please enter your city name"),
  address: Yup.string().required("please enter your address"),
  country: Yup.string().required("please enter your country name"),
  about_me: Yup.string().required("please enter about you"),
  title: Yup.string().required("please enter your profession"),
  notice_period: Yup.string().required("please select 1 "),
});

const validationSchemaStep2 = Yup.object({
  name: Yup.string().required("required"),
  rating: Yup.string().required("required"),
  designation: Yup.string().required("required"),
  company: Yup.string().required("required"),
  start_date: Yup.string().required("required"),
  end_date: Yup.string().required("required"),
  university: Yup.string().required("required"),
  degree: Yup.string().required("required"),
  Q_number: Yup.string().required("required"),
  Q_ans: Yup.string().required("required"),
});

const validationSchemaStep4 = Yup.object({
  expect_message: Yup.string().required("Ask any Question"),
});

const Step1 = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const firstErrorRef = useRef(null);
  const [registrationError, setRegistrationError] = useState(null);

  const formik = useFormik({
    initialValues: props.data,
    validationSchema: validationSchemaStep1,
    onSubmit: async (values) => {},
  });

  let isProcessing = false; 

  const checkUser = async (values, myformik, myStep) => {
    if (isProcessing) return; 

    isProcessing = true; 

    const countryCode = getCountryCode(values.country);
    const number = countryCode?.substring(1);
    const modifiedCellPhone = `${number}${values.cell_phone}`;
    const updatedValues = {
      ...values,
      cell_phone: modifiedCellPhone,
    };

    try {
      const checkExistingUser = await axios.post(REGISTER, updatedValues);
      if (
        checkExistingUser?.data?.message === "Phone Number Already exists" ||
        checkExistingUser?.data?.message === "Email Already exists"
      ) {
        setRegistrationError(checkExistingUser?.data?.message);
      } else {
        props.next(updatedValues, myformik, myStep);
      }
    } catch (error) {
      if (error.response?.status === 500) {
        props.next(updatedValues, myformik, myStep);
      }
    } finally {
      isProcessing = false; // Reset processing flag after registration attempt
    }
  };

  const handleSubmit = (values, myformik, myStep) => {
    const firstErrorField = Object.keys(myformik.errors)[0];

    let el = document.querySelector(`[name=${firstErrorField}]`);

    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    // Call your checkUser function here
    checkUser(values, myformik, myStep);
  };


 

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setconfirmPasswordShown(!confirmPasswordShown);
  };

  const getCountryCode = (country) => {
    switch (country) {
      case "singapore":
        return "+65";
      case "malaysia":
        return "+60";
      default:
        return undefined;
    }
  };

  return (
    <div className="register-page-container">
      <CandidateNavbar />
      <div className=" container sm:max-w-[80%] border-solid border-2 rounded-[50px] border-[#eeeeee] bg-[#FAFAFA] mb-[64px] mt-[64px] flex flex-col items-center mx-auto text-center ">
        <Stepper step="step1" />
        <h1 className="font-bold text-4xl sm:text-[25px] mb-4">Step 1</h1>
        <p className="text-[#696969] mb-12 sm:text-[14px] sm:px-3">
          Please enter your personal details for the account sign up process.
          Upon completion of <br />
          registration you will be able to create additional users
        </p>

        <div className="mx-auto mb-[5rem] rounded-2xl pb-[2rem]  md:pr-[5rem] md:w-[80%]  sm:py-[3.5rem] sm:px-[2rem] sm:w-[100%] sm:bg-inherit ">
          <form
            onSubmit={formik.handleSubmit}
            className="lg:flex lg:justify-evenly lg:flex-wrap  xl:flex xl:justify-evenly xl:flex-wrap 2xl:flex 2xl:justify-evenly 2xl:flex-wrap container "
          >
            <div className=" w-5/12 h-32 md:w-full sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="first_name"
              >
                <img src={nameIcon} alt="email" className="mr-2" /> First Name{" "}
                <Asterik />
              </label>
              <input
                type="text"
                id="first_name"
                placeholder="First Name"
                name="first_name"
                onChange={formik.handleChange}
                value={formik.values.first_name}
                // ref={firstErrorRef}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.first_name}
                </p>
              ) : null}
            </div>
            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="last_name"
              >
                <img src={nameIcon} alt="last_name" className="mr-2" /> Last
                Name <Asterik />
              </label>
              <input
                type="text"
                id="last_name"
                placeholder="Last Name"
                name="last_name"
                onChange={formik.handleChange}
                value={formik.values.last_name}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.last_name}
                </p>
              ) : null}
            </div>

            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="country"
              >
                <img src={addressIcon} alt="country" className="mr-2" /> Country{" "}
                <Asterik />
              </label>

              <select
                id="select"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                className=" appearance-none border rounded w-full my-2 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>
                  Select Country
                </option>
                <option value="singapore">Singapore</option>
                <option value="malaysia">Malaysia</option>
              </select>
              {formik.touched.country && formik.errors.country ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.country}
                </p>
              ) : null}
            </div>
            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="city"
              >
                <img src={addressIcon} alt="password" className="mr-2" /> City{" "}
                <Asterik />
              </label>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                onChange={formik.handleChange}
                value={formik.values.city}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.city && formik.errors.city ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.city}
                </p>
              ) : null}
            </div>

            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="email"
              >
                <img src={Vector} alt="email" className="mr-2" /> Email{" "}
                <Asterik />
              </label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>
            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="cell_phone"
              >
                <img src={phoneIcon} alt="phoneIcon" className="mr-2" /> Phone
                Number <Asterik />
              </label>
              <div className="flex items-center ">
                <span className=" appearance-none bg-white h-[54px] w-[50px] border rounded  py-4 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                  {getCountryCode(formik.values.country)}
                </span>
                <NumericFormat
                  type="text"
                  id="cell_phone"
                  placeholder="Phone Number"
                  name="cell_phone"
                  maxLength={12}
                  onChange={formik.handleChange}
                  // value={formik.values.cell_phone}

                  disabled={!formik.values.country}
                  className=" appearance-none border bg-white rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              {formik.touched.cell_phone && formik.errors.cell_phone ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.cell_phone}
                </p>
              ) : null}
            </div>
            <div className=" w-5/12 h-32 md:w-full relative  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="password"
              >
                <img src={passwordIcon} alt="email" className="mr-2" /> Choose
                Password <Asterik />
              </label>
              <input
                type={passwordShown ? "text" : "password"}
                id="password"
                placeholder="Choose Password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 top-1 pr-3 flex items-center text-sm leading-5"
                type="button"
              >
                {passwordShown ? (
                  <IconEyeOff size={24} />
                ) : (
                  <IconEye size={24} />
                )}
              </button>
              {formik.touched.password && formik.errors.password ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.password}
                </p>
              ) : null}
            </div>
            <div className="relative  w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="confirmPassword"
              >
                <img
                  src={passwordIcon}
                  alt="confirmPassword"
                  className="mr-2"
                />{" "}
                Confirm Password <Asterik />
              </label>
              <input
                type={confirmPasswordShown ? "text" : "password"}
                id="confirmPassword"
                placeholder="Confirm Password"
                name="confirmPassword"
                onChange={formik.handleChange}
                // value={formik.values.confirmPassword}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-0 top-1 pr-3 flex items-center text-sm leading-5"
                type="button"
              >
                {confirmPasswordShown ? (
                  <IconEyeOff size={24} />
                ) : (
                  <IconEye size={24} />
                )}
              </button>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.confirmPassword}
                </p>
              ) : null}
            </div>
            <div className="w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="date_of_birth"
              >
                <img src={calendarevent} alt="person" className="mr-2" /> Date
                of Birth <Asterik />{" "}
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="you must be 18+ to join our platform"
                >
                  <Tooltip id="my-tooltip" style={{ fontSize: "12px" }} />
                  <IconInfoCircleFilled className="w-[20px] h-[20px]  ml-2" />
                </a>
              </label>
              <input
                type="date"
                id="date_of_birth"
                placeholder="Date of Birth"
                name="date_of_birth"
                onChange={formik.handleChange}
                value={formik.values.date_of_birth}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.date_of_birth && formik.errors.date_of_birth ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.date_of_birth}
                </p>
              ) : null}
            </div>
            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="gender"
              >
                <img src={gender} alt="gender" className="mr-2" /> Gender{" "}
                <Asterik />
              </label>
              {/* <input
                type="text"
                id="gender"
                placeholder="Male/Female"
                name="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              /> */}
              <select
                id="selectGender"
                name="gender"
                value={formik.values.gender} // Set the value to formik's state
                onChange={formik.handleChange} // Handle change to update the state
                className=" appearance-none border rounded w-full my-2 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>
                  Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                {/* <option value="other">Other</option> */}
              </select>
              {formik.touched.gender && formik.errors.gender ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.gender}
                </p>
              ) : null}
            </div>
            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center"
                htmlFor="title"
              >
                <img src={article} alt="last_name" className="mr-2" /> Title{" "}
                <Asterik />{" "}
                <p className="text-gray-500">(Your Current Title)</p>
              </label>
              <input
                type="text"
                id="title"
                placeholder="UI/UX Designer"
                name="title"
                onChange={formik.handleChange}
                value={formik.values.title}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.title && formik.errors.title ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.title}
                </p>
              ) : null}
            </div>
            <div className=" w-5/12 h-32 md:w-full  sm:w-full">
              <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center">
                <img src={buildingicon} alt="notice_period" className="mr-2" />
                Notice Period <Asterik />
                <p className="text-gray-500">(How Soon you can Join)</p>
              </label>
              <select
                id="select_Notice_Period"
                name="notice_period"
                value={formik.values.notice_period} // Set the value to formik's state
                onChange={formik.handleChange} // Handle change to update the state
                className=" appearance-none border rounded w-full  py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>
                  Please Select 1
                </option>
                <option value="ONE_WEEK">1 week</option>
                <option value="TWO_WEEK">2 week</option>
                <option value="THREE_WEEK">3 week</option>
                <option value="ONE_MONTH">1 month</option>
                <option value="TWO_MONTH">2 month</option>
                <option value="THREE_MONTH">3 month</option>
              </select>
              {formik.touched.notice_period && formik.errors.notice_period ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.notice_period}
                </p>
              ) : null}
            </div>
            <div className=" w-[89%] h-32 md:w-full  sm:w-full">
              <label
                className=" text-black-500 text-[18px] mb-2 flex items-center"
                htmlFor="address"
              >
                <img src={addressIcon} alt="address" className="mr-2" /> Address{" "}
                <Asterik />{" "}
              </label>
              <input
                type="text"
                id="address"
                placeholder="Address..."
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
                className=" appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.address && formik.errors.address ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.address}
                </p>
              ) : null}
            </div>
            <div className=" mb-[4%]  w-[89%] h-52">
              <label
                className=" text-gray-700 text-[18px] sm:text-[14px] font-bold mb-2 flex items-center"
                htmlFor="about_me"
              >
                <img src={info} alt="about_me" className="mr-2" /> About Me{" "}
                <Asterik />
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Do give us your best pitch by stating ( brief introduction, relevant experience, your skills, educational background, career objectives and your personal qualities)."
                >
                  <Tooltip id="my-tooltip" style={{ fontSize: "12px" }} />
                  <IconInfoCircleFilled className="w-[20px] h-[20px]  ml-2" />
                </a>
              </label>
              <textarea
                rows={7}
                type="text"
                id="about_me"
                placeholder="Tell about yourself..."
                name="about_me"
                onChange={formik.handleChange}
                value={formik.values.about_me}
                className=" appearance-none border rounded w-full py-4 px-3  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {formik.touched.about_me && formik.errors.about_me ? (
                <p className="text-xs text-left mt-1 text-red-500">
                  {formik.errors.about_me}
                </p>
              ) : null}
            </div>
          </form>
          {registrationError && (
            <p className=" text-center mt-1 text-red-500">
              {registrationError}
            </p>
          )}
        </div>
      </div>
      <div className="container sm:max-w-[80%] pb-[5rem]  w-4/5 mx-auto flex justify-between">
        <Link to="/">
          <div className="flex items-center gap-0">
            <img src={backarrowsvg} alt="rightArrow" className="mr-2 pl-2" />
            <p className="bg-inherit text-black py-2 rounded text-lg sm:text-[14px] mr-3">
              Back To Home Page
            </p>
          </div>
        </Link>

        <button
          type="submit"
          onClick={() => handleSubmit(formik.values, formik, props.stepIndex)}
          // onClick={() => props.next(formik.values, formik, props.stepIndex)}

          className="bg-black text-white px-16 sm:px-3 py-2 sm:py-0 rounded text-lg hover:bg-white hover:text-black border-[1px]"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const Step2 = (props) => {
  const [previousData, setPreviousData] = useState();
  const [toastError, setToastError] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const savedPayload = localStorage.getItem("step2");
    const parsedPayload = JSON.parse(savedPayload);
    // if (parsedPayload) {
    //   formik.setValues(parsedPayload);
    // }
    setPreviousData(parsedPayload);
    if (
      parsedPayload &&
      parsedPayload.experienceArray &&
      parsedPayload.languageArray &&
      parsedPayload.educationArray &&
      parsedPayload.skillArray
    ) {
      setExperienceArray(parsedPayload.experienceArray);
      setSkillArray(parsedPayload.skillArray);
      setEducationArray(parsedPayload.educationArray);
      setLanguageArray(parsedPayload.languageArray);
    }
  }, []);
  const prevSection = () => {
    props.prev();
  };

  ////////////////////// // for open and close modals////////////////
  const [open, setOpen] = useState(false);
  const [openSkill, setOpenSkill] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  //////////////// for Work Experience////////////////
  const [experienceArray, setExperienceArray] = useState(
    previousData?.experienceArray || []
  );
  const [formData, setFormData] = useState({
    designation: "",
    company: "",
    description: "",
    start_date: "",
    end_date: "",
  });

  const handleAddExperience = (event) => {
    event.preventDefault();
    for (const key in formData) {
      if (formData[key].trim() === "") {
        toast(`Please Fill all the Fields.`);
        // alert(`Please fill the ${key} field.`);
        return; // Exit the function if any field is empty
      }
    }
    const newExperienceArray = [...experienceArray, formData];
    setExperienceArray(newExperienceArray);
    updateArrayInFormik("experience", newExperienceArray);
    setOpen(false);

    setFormData({
      designation: "",
      company: "",
      description: "",
      start_date: "",
      end_date: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeleteExperience = (index) => {
    // Create a new array excluding the experience at the specified index
    const newArray = experienceArray.filter((_, i) => i !== index);
    // Update the state with the new array
    setExperienceArray(newArray);
  };
  ////////////////// for skills//////////////

  const [techSkills, setTechSkills] = useState(
    previousData?.skillArray?.filter((skill) => skill.type === "TECH") || []
  );
  const [softSkills, setSoftSkills] = useState(
    previousData?.skillArray?.filter((skill) => skill.type === "SOFT") || []
  );

  const [skillArray, setSkillArray] = useState(previousData?.skillArray || []);
  const [skillData, setSkillData] = useState({
    name: "",
    rating: "",
    type: "",
  });

  useEffect(() => {
    // Filter skill array based on type
    const techSkillsArray = props.data.skill.filter(
      (skill) => skill.type === "TECH"
    );
    const softSkillsArray = props.data.skill.filter(
      (skill) => skill.type === "SOFT"
    );

    // Set state for techSkills and softSkills
    setTechSkills(techSkillsArray);
    setSoftSkills(softSkillsArray);
  }, [props.data.skill]);

  useEffect(() => {
    // Filter skill array based on type
    const techSkillsArray = skillArray.filter((skill) => skill.type === "TECH");
    const softSkillsArray = skillArray.filter((skill) => skill.type === "SOFT");

    // Set state for techSkills and softSkills
    setTechSkills(techSkillsArray);
    setSoftSkills(softSkillsArray);
  }, [skillArray]);

  const handleAddSkill = (event) => {
    event.preventDefault();
    for (const key in skillData) {
      if (skillData[key].trim() === "") {
        toast(`Please Fill all the Fields.`);

        return;
      }
    }

    let newSkillArray = [];
    if (skillData.type === "TECH") {
      newSkillArray = [...techSkills, skillData];
      setTechSkills(newSkillArray);
    } else if (skillData.type === "SOFT") {
      newSkillArray = [...softSkills, skillData];
      setSoftSkills(newSkillArray);
    }

    const combinedSkills = [...techSkills, ...softSkills, skillData];
    setSkillArray(combinedSkills);
    updateArrayInFormik("skill", combinedSkills);
    setOpenSkill(false);
    setSkillData({
      name: "",
      rating: "",
      type: "",
    });
  };

  const handleInputSkill = (e) => {
    const { name, value } = e.target;
    setSkillData({
      ...skillData,
      [name]: value,
    });
  };

  const deleteTechSkill = (index) => {
    // Create a new array excluding the experience at the specified index
    const newArray = techSkills.filter((_, i) => i !== index);
    // Update the state with the new array
    setTechSkills(newArray);
  };
  const deleteSoftSkill = (index) => {
    // Create a new array excluding the experience at the specified index
    const newArray = softSkills.filter((_, i) => i !== index);
    // Update the state with the new array
    setSoftSkills(newArray);
  };

  //////////////////// for language//////////////////////
  const [languageArray, setLanguageArray] = useState(
    previousData?.languageArray || []
  );
  const [languageData, setLanguageData] = useState({
    lan: "",
    spoking: "",
    reading: "",
    writing: "",
  });

  const handleAddLanguage = (event) => {
    event.preventDefault();
    for (const key in languageData) {
      if (languageData[key].trim() === "") {
        toast(`Please Fill all the Fields.`);
        // alert(`Please fill the ${key} field.`);
        return;
      }
    }
    setLanguageArray([...languageArray, languageData]);
    const newlanguageArray = [...languageArray, languageData];
    setLanguageArray(newlanguageArray);
    updateArrayInFormik("language", newlanguageArray);
    setOpenLanguage(false);

    setLanguageData({
      lan: "",
      spoking: "",
      reading: "",
      writing: "",
    });
  };

  const handleInputLanguage = (e) => {
    const { name, value } = e.target;
    setLanguageData({
      ...languageData,
      [name]: value,
    });
  };

  const handleDeleteLanguage = (index) => {
    // Create a new array excluding the experience at the specified index
    const newArray = languageArray.filter((_, i) => i !== index);
    // Update the state with the new array
    setLanguageArray(newArray);
  };

  //////////////////// for education//////////////////////
  const [educationArray, setEducationArray] = useState(
    previousData?.educationArray || []
  );
  const [educationData, setEducationData] = useState({
    degree: "",
    university: "",
    start_date: "",
    end_date: "",
  });

  const handleAddEducation = (event) => {
    event.preventDefault();

    for (const key in educationData) {
      if (educationData[key].trim() === "") {
        toast(`Please Fill all the Fields.`);
        // alert(`Please fill the ${key} field.`);
        return;
      }
    }

    const newEducationArray = [...educationArray, educationData];
    setEducationArray(newEducationArray);
    updateArrayInFormik("education", newEducationArray);

    setOpenEducation(false);

    setEducationData({
      degree: "",
      university: "",
      start_date: "",
      end_date: "",
    });
  };

  const handleInputEducation = (e) => {
    const { name, value } = e.target;
    setEducationData({
      ...educationData,
      [name]: value,
    });
  };

  const handleDeleteEducation = (index) => {
    // Create a new array excluding the experience at the specified index
    const newArray = educationArray.filter((_, i) => i !== index);
    // Update the state with the new array
    setEducationArray(newArray);
  };

  const formik = useFormik({
    initialValues: {
      ...props.data,
      experience: [],
      skill: [],
      language: [],
      education: [],
      // experience: previousData?.experienceArray || [],
      // skill: previousData?.skillArray || [],
      // language: previousData?.languageArray || [],
      // education: previousData?.educationArray || [],
    },
    // validationSchema: validationSchemaStep2,
    onSubmit: (values) => {
      // Handle login logic here
    },
  });
  const updateArrayInFormik = (arrayName, arrayData) => {
    formik.setFieldValue(arrayName, arrayData);
  };

  const cancelButtonRef = useRef(null);

  return (
    <>
      {/* model for work experience */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[70%] sm:w-[85%]  h-[600px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8">
                  <div className=" sm:items-start">
                    <form onSubmit={handleAddExperience}>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Add Experience
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 text-center md:mx-4 sm:ml-4 sm:mt-0 sm:text-left">
                      <div className=" w-[90%]  mx-auto  mt-[1rem] sm:mt-[5rem] md:w-full sm:w-full">
                        <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-10">
                          <img src={titleicon} alt="email" className="mr-2" />{" "}
                          Title <Asterik />
                        </label>
                        <input
                          type="text"
                          id="designation"
                          placeholder="ui/ux Designer"
                          name="designation"
                          value={formData.designation}
                          onChange={handleInputChange}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.designation &&
                        formik.errors.designation ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.designation}
                          </p>
                        ) : null}
                        <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={buildingicon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Company Name <Asterik />
                        </label>
                        <input
                          type="text"
                          id="company"
                          placeholder="BMG Talent"
                          name="company"
                          value={formData.company}
                          onChange={handleInputChange}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.company && formik.errors.company ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.company}
                          </p>
                        ) : null}

                        <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={buildingicon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Job Description <Asterik />
                        </label>
                        <input
                          type="text"
                          id="description"
                          placeholder="job description ....."
                          name="description"
                          value={formData.description}
                          onChange={handleInputChange}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.description}
                          </p>
                        ) : null}
                        <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={calendaricon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Start Date <Asterik />
                        </label>
                        <input
                          type="date"
                          id="start_date"
                          placeholder="BMG Talent"
                          name="start_date"
                          value={formData.start_date}
                          onChange={handleInputChange}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.start_date}
                          </p>
                        ) : null}
                        <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-6">
                          <img
                            src={calendaricon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          End Date <Asterik />
                        </label>
                        <input
                          type="date"
                          id="end_date"
                          placeholder="BMG Talent"
                          name="end_date"
                          value={formData.end_date}
                          onChange={handleInputChange}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.end_date}
                          </p>
                        ) : null}
                      </div>
                      {/* <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All
                          of your data will be permanently removed from our
                          servers forever. This action cannot be undone.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md   py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={(event) => handleAddExperience(event)}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* model for skills */}
      <Transition.Root show={openSkill} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenSkill}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[450px] sm:h-[520px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-[85%]">
                  <div className=" sm:items-start">
                    <form onSubmit={handleAddSkill}>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Add Skills
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenSkill(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>

                    <div className="mt-3 sm:ml-0 sm:mt-0 sm:text-left ">
                      <div className="grid grid-cols-1 items-center mx-8 sm:mx-4 mt-[1rem]">
                        <div className="skillsfield">
                          <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-10">
                            <img
                              src={titleicon}
                              alt="titleIcon"
                              className="mr-2"
                            />{" "}
                            Choose Skill Type <Asterik />
                          </label>
                          <select
                            id="select"
                            name="type"
                            value={skillData?.type} // Set the value to formik's state
                            onChange={handleInputSkill} // Handle change to update the state
                            className=" appearance-none border rounded w-full my-2 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="" disabled selected>
                              Please Select 1
                            </option>
                            <option value="TECH">Technical Skill</option>
                            <option value="SOFT">Soft Skill</option>
                          </select>
                        </div>

                        <div className="grid grid-cols-2 sm:grid-cols-1 sm:gap-2 items-center  mt-[1rem]">
                          <div className="skillsfield">
                            <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center ">
                              <img
                                src={titleicon}
                                alt="email"
                                className="mr-2"
                              />{" "}
                              Skills <Asterik />
                            </label>
                            <input
                              type="text"
                              id="name"
                              placeholder="ui/ux Designer"
                              name="name"
                              value={skillData.name}
                              onChange={handleInputSkill}
                              className=" appearance-none w-[90%] border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="Ratingfield">
                            <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center ">
                              <img src={rating} alt="email" className="mr-2" />{" "}
                              Rating <Asterik />
                            </label>

                            <select
                              id="select"
                              name="rating"
                              value={skillData?.rating} // Set the value to formik's state
                              onChange={handleInputSkill} // Handle change to update the state
                              className=" appearance-none w-[100%] border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled className="text-[14px]">
                                Choose Number Between 1 to 10
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-[2rem] mr-[4rem] bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={(event) => handleAddSkill(event)}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* model for language */}
      <Transition.Root show={openLanguage} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenLanguage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[300px] sm:h-[440px] overflow-hidden rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-[80%]">
                  <div className="">
                    <form onSubmit={handleAddLanguage}>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Languages
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenLanguage(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:mt-0 sm:text-left">
                      <div className="items-center mx-8 sm:mx-4 mt-[1rem] sm:mt-[4rem]">
                        <div className="skillsfield">
                          {/* <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={languages} alt="email" className="mr-2" />{" "}
                            Language
                          </label> */}
                          <select
                            id="language"
                            name="lan"
                            value={languageData.lan} // Set the value to formik's state
                            onChange={handleInputLanguage} // Handle change to update the state
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="" disabled>
                              Language
                            </option>
                            <option value="English">English</option>
                            <option value=" Mandarin/Chinese">
                              {" "}
                              Mandarin/Chinese
                            </option>
                            <option value="Cantonese">Cantonese</option>
                            <option value="Hokkien">Hokkien</option>
                            <option value="Bahasa">Bahasa </option>
                            <option value="Malaysian">Malaysian</option>
                          </select>
                          <div className="grid grid-cols-3 sm:grid-cols-1 gap-4 mt-4">
                            <select
                              id="language"
                              name="spoking"
                              value={languageData.spoking} // Set the value to formik's state
                              onChange={handleInputLanguage}
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled>
                                Spoken
                              </option>

                              <option value="PROFICIENT">Proficient</option>
                              <option value="FLUENT">Fluent</option>
                              <option value="CONVERSATIONAL">
                                Conversational
                              </option>
                            </select>
                            <select
                              id="language"
                              name="reading"
                              value={languageData.reading} // Set the value to formik's state
                              onChange={handleInputLanguage}
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled>
                                Reading
                              </option>
                              <option value="PROFICIENT">Proficient</option>
                              <option value="FLUENT">Fluent</option>
                              <option value="CONVERSATIONAL">
                                Conversational
                              </option>
                            </select>
                            <select
                              id="language"
                              name="writing"
                              value={languageData.writing} // Set the value to formik's state
                              onChange={handleInputLanguage}
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled>
                                Writing
                              </option>
                              <option value="PROFICIENT">Proficient</option>
                              <option value="FLUENT">Fluent</option>
                              <option value="CONVERSATIONAL">
                                Conversational
                              </option>
                            </select>
                          </div>
                          {formik.touched.language && formik.errors.language ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.language}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All
                          of your data will be permanently removed from our
                          servers forever. This action cannot be undone.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={(event) => handleAddLanguage(event)}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* model for education */}
      <Transition.Root show={openEducation} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenEducation}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-[80%]">
                  <div className="">
                    <form onSubmit={handleAddEducation}>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Add Education
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenEducation(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:mt-16 sm:text-left">
                      <div className="items-center mx-8 sm:mx-4 mt-[1rem]">
                        <div className="skillsfield">
                          <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-6">
                            <img
                              src={degreeicon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            Degree <Asterik />
                          </label>
                          <input
                            type="text"
                            id="degree"
                            placeholder="ui/ux Designer"
                            name="degree"
                            value={educationData?.degree}
                            onChange={handleInputEducation}
                            className=" appearance-none w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.degree && formik.errors.degree ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.degree}
                            </p>
                          ) : null}
                          <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-6">
                            <img
                              src={degreeicon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            University <Asterik />
                          </label>
                          <input
                            type="text"
                            id="university"
                            placeholder="University Name"
                            name="university"
                            value={educationData?.university}
                            onChange={handleInputEducation}
                            className=" appearance-none w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.university &&
                          formik.errors.university ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.university}
                            </p>
                          ) : null}
                          <label className=" text-black-500 text-[18px] sm:text-[14px] mb-2 flex items-center my-6">
                            <img
                              src={calendaricon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            Start Date <Asterik />
                          </label>
                          <input
                            type="date"
                            id="start_date"
                            placeholder="BMG Talent"
                            name="start_date"
                            value={educationData?.start_date}
                            onChange={handleInputEducation}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.start_date &&
                          formik.errors.start_date ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.start_date}
                            </p>
                          ) : null}
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                            <img
                              src={calendaricon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            End Date <Asterik />
                          </label>
                          <input
                            type="date"
                            id="end_date"
                            placeholder="BMG Talent"
                            name="end_date"
                            value={educationData?.end_date}
                            onChange={handleInputEducation}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.end_date && formik.errors.end_date ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.end_date}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {/* <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to deactivate your account? All
                          of your data will be permanently removed from our
                          servers forever. This action cannot be undone.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={(event) => handleAddEducation(event)}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="register-page-container">
        <CandidateNavbar />
        {toast && <ToastContainer />}

        <div className=" container sm:max-w-[85%] border-solid rounded-[50px] border-2 border-[#eeeeee] bg-[#FAFAFA] mb-[64px] mt-[64px] flex flex-col items-center mx-auto text-center">
          <Stepper step="step2" />
          <h1 className="font-bold text-4xl sm:text-[25px] mb-2">Step 2</h1>
          <p className="text-[#696969] sm:text-[14px]">
            Please enter your personal details for the account sign up process.
            Upon completion of <br />
            registration you will be able create additional users
          </p>

          <div className=" w-4/5 mx-auto mb-[5rem] rounded-2xl py-[2rem]  md:pr-[0rem] md:w-[80%]  sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
            <form
              onSubmit={formik.handleSubmit}
              className="container mx-auto sm:max-w-[80%] "
            >
              <div className=" mb-[4%] mx-auto w-[100%] h-68">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="experience"
                >
                  <img src={workIcon} alt="workIcon" className="mr-2" /> Work
                  Experience <Asterik />
                </label>

                <div className=" max-h-[260px] min-h-[260px] border-solid border-2 border-[#eeeeee] bg-white  text-left relative  ">
                  <div className="max-h-[220px] min-h-[220px] overflow-y-auto">
                    {experienceArray?.length > 0 ? (
                      <div>
                        {experienceArray?.map((exp, index) => (
                          <div
                            key={index}
                            className="pl-[2rem] pt-[5px] pb-[10px] border-solid border-b-2 border-[#eeeeee] relative"
                          >
                            <p className="text-[14px] font-medium  pr-16 text-[#696969]">
                              {exp.designation} | {exp.company}
                            </p>
                            <p className="text-[14px] font-medium  pr-16 text-[#E9AA06]">
                              {exp.start_date} {exp.end_date}
                            </p>
                            <p className="text-[14px] font-medium  pr-16 text-[#696969]">
                              {exp.description}
                            </p>
                            <button
                              className="text-[#E9AA06] absolute right-4 top-4"
                              onClick={() => handleDeleteExperience(index)}
                            >
                              <IconTrashFilled />
                            </button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-[22px] sm:text-[14px] text-[#878d93] font-medium pt-4 max-h-[220px] min-h-[220px] overflow-y-auto flex justify-center items-center	">
                        Please add your Experience
                      </p>
                    )}
                  </div>
                  <div>
                    <button
                      className=" absolute  right-1  bottom-1  max-h-[30px] min-h-[30px]  bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[30px] sm:px-[15px] rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={() => setOpen(true)}
                    >
                      Add +
                    </button>
                  </div>
                </div>
                {/* {formik.touched.experience && formik.errors.experience ? (
                  <p className="text-xs text-left mt-1 text-red-500">
                    {formik.errors.experience}
                  </p>
                ) : null} */}
              </div>
              <div className=" mb-[4%] mx-auto w-[100%] overflow-y-auto h-68">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="skill"
                >
                  <img src={circlefilled} alt="workIcon" className="mr-2" />{" "}
                  Skills <Asterik />
                </label>
                <div className="  border-solid border-2 border-[#eeeeee] relative  bg-white ">
                  <div className="">
                    <h2 className="text-gray-700 text-[16px] text-left pl-[1rem] pt-[1rem] flex items-center">
                      Technical Skills
                      <p className="text-gray-500 text-sm">
                        {" "}
                        (Programming, project management,software, content
                        creation, data analysis)
                      </p>
                    </h2>
                    {techSkills?.length > 0 ? (
                      <div>
                        <div className="grid grid-cols-4 pl-[2rem] md:grid-cols-2 sm:grid-cols-1 gap-6 justify-center items-center pr-16 my-4 ">
                          {techSkills?.map((title, index) => (
                            <h2
                              key={index}
                              className="text-[13px] text-center py-[6px] 2xl:px-10  border-2 rounded-md bg-white relative"
                            >
                              {title.name}
                              {"  "} {title.rating}/10
                              <button onClick={() => deleteTechSkill(index)}>
                                <img
                                  src={smallcross}
                                  alt=""
                                  className="absolute top-[-4px] right-[-4px] "
                                />
                              </button>
                            </h2>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p className="text-[22px] sm:text-[14px] text-[#878d93] font-medium pt-4 text-center">
                        Please add your Tech Skills
                      </p>
                    )}
                  </div>
                  <div className="">
                    <h2 className="text-gray-700 mt-4 text-[16px] text-left pl-[1rem]  flex items-center">
                      Soft Skills
                      <p className="text-gray-500 text-sm">
                        {" "}
                        (Teamwork, communication,critical thinking, leadership)
                      </p>
                    </h2>
                    {softSkills?.length > 0 ? (
                      <div>
                        <div className="grid grid-cols-4 pl-[2rem] md:grid-cols-2 sm:grid-cols-1 gap-6 justify-center items-center pr-16 my-4 ">
                          {softSkills?.map((title, index) => (
                            <h2
                              key={index}
                              className="text-[13px] text-center py-[6px] 2xl:px-10  border-2 rounded-md bg-white  relative"
                            >
                              {title.name}
                              {"  "} {title.rating}/10
                              <button onClick={() => deleteSoftSkill(index)}>
                                <img
                                  src={smallcross}
                                  alt=""
                                  className="absolute top-[-4px] right-[-4px] "
                                />
                              </button>
                            </h2>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <p className="text-[22px] sm:text-[14px] text-[#878d93] font-medium pt-4 text-center">
                        Please add your Soft Skills
                      </p>
                    )}
                  </div>
                  <div className="mt-12">
                    <button
                      type="button"
                      className="absolute  right-1  bottom-1  max-h-[30px] min-h-[30px] sm:px-[15px] bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[30px]  rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={() => setOpenSkill(true)}
                    >
                      Add +
                    </button>
                  </div>
                </div>

                {/* {formik.touched.skill && formik.errors.skill ? (
                  <p className="text-xs text-left mt-1 text-red-500">
                    {formik.errors.skill}
                  </p>
                ) : null} */}
              </div>
              <div className=" mb-[4%] mx-auto w-[100%] h-68">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="languages"
                >
                  <img src={language} alt="nameIcon" className="mr-2" />{" "}
                  Languages <Asterik />
                </label>
                <div className="max-h-[260px] min-h-[260px]  border-solid border-2 border-[#eeeeee] relative bg-white ">
                  <div className="max-h-[220px] min-h-[220px] overflow-y-auto">
                    {languageArray.length > 0 ? (
                      <div>
                        <div className="grid grid-cols-1 pl-[1rem]  md:grid-cols-1 sm:grid-cols-1 gap-12 justify-center items-center pr-4 mt-7 ">
                          <table className="table-fixed border-collapse border  border-slate-400">
                            <thead className="">
                              <tr>
                                <th className="border border-slate-300 font-medium py-2 bg-[#FFC107] text-white">
                                  Language
                                </th>
                                <th className="border border-slate-300 font-medium py-2 bg-[#FFC107] text-white">
                                  Reading
                                </th>
                                <th className="border border-slate-300 py-2 font-medium bg-[#FFC107] text-white">
                                  Writing
                                </th>
                                <th className="border border-slate-300 py-2 font-medium bg-[#FFC107] text-white">
                                  Spoken
                                </th>
                                <th className="border border-slate-300 py-2 font-medium bg-[#FFC107] text-white">
                                  Action
                                </th>
                              </tr>
                            </thead>

                            {languageArray?.map((title, index) => (
                              ///add index but dont test
                              <tbody key={index}>
                                <tr className="border-collapse border border-slate-400">
                                  <td className="border border-slate-300 py-2 ">
                                    {title.lan}
                                  </td>
                                  <td className="border border-slate-300 py-2">
                                    {title.reading}
                                  </td>
                                  <td className="border border-slate-300 py-2">
                                    {title.writing}
                                  </td>
                                  <td className="border border-slate-300 py-2">
                                    {title.spoking}
                                  </td>
                                  <td className="border border-slate-300 py-2">
                                    <button
                                      className="text-[#E9AA06]"
                                      onClick={() =>
                                        handleDeleteLanguage(index)
                                      }
                                    >
                                      <IconTrashFilled />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                            {/* </div> */}
                          </table>
                        </div>
                      </div>
                    ) : (
                      <p className="text-[22px] sm:text-[14px] text-[#878d93] font-medium pt-4 max-h-[220px] min-h-[220px] overflow-y-auto flex justify-center items-center	">
                        Please add your Languages
                      </p>
                    )}
                    {/* <div className="grid grid-cols-1 pl-[1rem] md:grid-cols-2 sm:grid-cols-1 gap-12 justify-center items-center pr-4 mt-7 ">
                      {languageArray?.map((title, index) => (
                        <h2
                          key={index}
                          className="text-[10px] text-center py-[6px] 2xl:px-10  border-2 rounded-md bg-white"
                        >
                          {title.lan} {" "} <br/> {title.spoking}/{title.writing}/{title.reading}
                        </h2>
                      ))}
                    </div> */}
                  </div>
                  <div>
                    <button
                      className="absolute  right-1  bottom-1  max-h-[30px] min-h-[30px] bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[30px] sm:px-[15px] rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={() => setOpenLanguage(true)}
                    >
                      Add +
                    </button>
                  </div>
                </div>
                {/* {formik.touched.languages && formik.errors.languages ? (
                  <p className="text-xs mt-1 text-red-500">
                    {formik.errors.languages}
                  </p>
                ) : null} */}
              </div>
              <div className=" mb-[4%] mx-auto w-[100%] h-68">
                <label
                  className=" text-gray-700 text-sm font-bold mb-2 flex items-center"
                  htmlFor="education"
                >
                  <img
                    src={educationIcon}
                    alt="educationIcon"
                    className="mr-2"
                  />{" "}
                  Education <Asterik />
                </label>
                <div className="max-h-[260px] min-h-[260px] border-solid border-2 border-[#eeeeee] relative text-left bg-white ">
                  <div className="max-h-[220px] min-h-[220px] overflow-y-auto">
                    {educationArray.length > 0 ? (
                      <div>
                        {educationArray?.map((edu, index) => (
                          <div
                            key={index}
                            className="pl-[2rem] pt-[5px] pb-[10px] border-solid border-b-2 border-[#eeeeee] relative"
                          >
                            <p className="text-[14px] font-medium   pr-16 text-[#696969]">
                              {edu.degree} | {edu.university} |
                            </p>
                            <p className="text-[14px] font-medium  pr-16 text-[#E9AA06]">
                              {edu.start_date} {edu.end_date}
                            </p>
                            <button
                              className="text-[#E9AA06] absolute right-4 top-4"
                              onClick={() => handleDeleteEducation(index)}
                            >
                              <IconTrashFilled />
                            </button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-[22px] sm:text-[14px] text-[#878d93] font-medium pt-4 max-h-[220px] min-h-[220px] overflow-y-auto flex justify-center items-center	">
                        Please add your Education
                      </p>
                    )}
                  </div>

                  <div>
                    <button
                      className="absolute  right-1  bottom-1  max-h-[30px] min-h-[30px] sm:px-[15px] bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[30px] rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={() => setOpenEducation(true)}
                    >
                      Add +
                    </button>
                  </div>
                </div>
                {/* {formik.touched.education && formik.errors.education ? (
                  <p className="text-xs text-left mt-1 text-red-500">
                    {formik.errors.education}
                  </p>
                ) : null} */}
              </div>
            </form>
            <h2 className="text-red-600">{errorMessage}</h2>
          </div>
        </div>
        <div className="container sm:max-w-[80%] pb-[5rem]  w-4/5 mx-auto flex sm:flex-col justify-between">
          <Link to={"/"}>
            {" "}
            <div className="flex items-center gap-0">
              <img src={backarrowsvg} alt="rightArrow" className="mr-2 pl-2" />
              <p className="bg-inherit text-black py-2 rounded text-lg sm:text-[16px] mr-3">
                Back To Home Page
              </p>
            </div>
          </Link>
          <div className="sm:flex sm:justify-center gap-2">
            <button
              onClick={() => prevSection()}
              className="bg-white border-[1px] text-black px-10 py-2 rounded text-lg"
            >
              Back
            </button>{" "}
            <button
              onClick={() => {
                // Check if any of the arrays is empty
                if (
                  experienceArray.length === 0 ||
                  skillArray.length === 0 ||
                  languageArray.length === 0 ||
                  educationArray.length === 0
                ) {
                  setErrorMessage(
                    "Please fill in all fields before proceeding to the next step."
                  );
                } else {
                  localStorage.setItem(
                    "step2",
                    JSON.stringify({
                      experienceArray,
                      skillArray,
                      languageArray,
                      educationArray,
                    })
                  );

                  props.next2(formik.values, formik, props.stepIndex);
                }
              }}
              className="bg-black text-white px-16 sm:px-6 py-2 rounded text-lg hover:bg-white hover:text-black border-[1px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const Step3 = (props) => {
  const [toastError, setToastError] = useState(null);
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (props.data) {
      const questionAnswers = {};

      props.data.question.forEach((item) => {
        questionAnswers[item.Q_number] = item.Q_ans;
      });

      formik.setValues({
        ...props.data,
        ...questionAnswers,
      });
    }
  }, [props.data]);
  const handleSubmit = (values, formik, forStep) => {
    const questionAnswers = [];
    let isEmptyAnswer = false;
    for (let i = 1; i <= 11; i++) {
      const answer = values[`q${i}`];
      if (!answer) {
        // If answer is empty
        isEmptyAnswer = true;
        break; // Exit loop if any empty answer found
      }

      questionAnswers.push({
        Q_number: `q${i}`,
        // Q_ans: values[`q${i}`],
        Q_ans: answer,
      });
    }
    if (isEmptyAnswer) {
      setShowError(true);
      setToastError(`Please Fill all the fields`);
      setTimeout(() => {
        setToastError(``);
        setShowError(false);
      }, 3000);
      return; // Exit function if any empty answer found
    }
    // Create the full payload for the API
    const apiPayload = {
      ...props.data,
      question: questionAnswers,
      // ... other fields as needed ...
    };
    // props.next2(formik.values, formik, forStep)
    props.next2(apiPayload);
  };
  const formik = useFormik({
    initialValues: {
      ...props.data,
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
      q9: "",
      q10: "",
      q11: "",
    },
    // validationSchema: validationSchemaStep3,
    onSubmit: (values) => {
      const questionAnswers = [];
      for (let i = 1; i <= 11; i++) {
        questionAnswers.push({
          Q_number: `q${i}`,
          Q_ans: values[`q${i}`],
        });
      }

      const apiPayload = {
        ...props.data,
        question: questionAnswers,
      };

      props.next(apiPayload);
    },
  });

  return (
    <div className="register-page-container">
      <CandidateNavbar />
      <div className=" container sm:max-w-[85%] bg-[#FAFAFA]  mb-[64px] mt-[64px] flex flex-col items-center mx-auto text-center">
        <Stepper step="step3" />

        <h1 className="font-bold text-4xl mb-2">Step 3</h1>
        <p className="text-[#696969]">
          Please do answer the following questions and give it your best so we
          could know you a little better.
        </p>

        <div className="  w-4/5 mx-auto mb-[5rem] rounded-2xl py-[2rem]  md:pr-[5rem] md:w-[80%] sm:py-[6.5rem] sm:pr-[0rem] sm:w-[100%] sm:bg-inherit ">
          <form
            onSubmit={formik.handleSubmit}
            className=" container sm:max-w-[85%] sm:ml-4  "
          >
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">01: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Singapore Work Experience: Have you previously worked in
                Singapore, or are you open to working in Singapore in the
                future, potentially with the goal of obtaining Permanent
                Residency?
              </label>
              <input
                type="text"
                id="q1"
                name="q1"
                onChange={formik.handleChange}
                value={formik.values.q1}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {formik.touched.q1 && formik.errors.q1 ? (
                <p className="text-xs mt-1 text-red-500">{formik.errors.q1}</p>
              ) : null}
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">02: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Educational Background: Please describe your highest level of
                education, including the field of study, and indicate who funded
                your education.
              </label>
              <input
                type="text"
                id="q2"
                name="q2"
                onChange={formik.handleChange}
                value={formik.values.q2}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">03: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Family Responsibilities: In situations where your child is ill,
                who typically takes the responsibility for their care?
              </label>
              <input
                type="text"
                id="q3"
                name="q3"
                onChange={formik.handleChange}
                value={formik.values.q3}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">04: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Perception of Time: How do you prioritize and manage your time
                effectively?
              </label>
              <input
                type="text"
                id="q4"
                name="q4"
                onChange={formik.handleChange}
                value={formik.values.q4}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">05: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Retirement Goals: What are your aspirations for when you reach
                55 years old, particularly regarding retirement and financial
                planning?
              </label>
              <input
                type="text"
                id="q5"
                name="q5"
                onChange={formik.handleChange}
                value={formik.values.q5}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">06: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Handling Unreasonable Requests: If you encounter an unreasonable
                demand from your boss, how do you typically handle the
                situation?
              </label>
              <input
                type="text"
                id="q6"
                name="q6"
                onChange={formik.handleChange}
                value={formik.values.q6}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">07: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Value of Money: How do you evaluate the importance and utility
                of money in your life?
              </label>
              <input
                type="text"
                id="q7"
                name="q7"
                onChange={formik.handleChange}
                value={formik.values.q7}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">08: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Reaction to Company's Profit without Personal Gain: If the
                company you work for achieves substantial profit but doesn't
                provide bonuses to employees, what would be your thought process
                or reaction?
              </label>
              <input
                type="text"
                id="q8"
                name="q8"
                onChange={formik.handleChange}
                value={formik.values.q8}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q1"
              >
                <p className="font-bold mr-2">09: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Quitting Point: What is one scenario or factor that would lead
                you to consider quitting your job?
              </label>
              <input
                type="text"
                id="q9"
                name="q9"
                onChange={formik.handleChange}
                value={formik.values.q9}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>

            <div className=" w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q10"
              >
                <p className="font-bold mr-2">10: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                Introversion or Extroversion Preference: Would you describe
                yourself more as an introvert, enjoying focused, independent
                work, or as an extrovert, thriving in dynamic, team-oriented
                environments? How does this trait influence your work style and
                interactions in a professional setting?{" "}
              </label>
              <input
                type="text"
                id="q10"
                name="q10"
                onChange={formik.handleChange}
                value={formik.values.q10}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>
            <div className="w-80% h-auto mb-16">
              <label
                className=" text-black-500 mb-2 flex items-start text-left"
                htmlFor="q11"
              >
                <p className="font-bold mr-2">11: </p>
                {/* <img src={faqIcon} alt="q1" className="mr-2 mt-[3px]" /> */}
                International Experience: Could you describe any international
                experiences you have had, such as education, work, or extensive
                travel? How have these experiences shaped your perspective and
                approach in a professional context?
              </label>
              <input
                type="text"
                id="q11"
                name="q11"
                onChange={formik.handleChange}
                value={formik.values.q11}
                className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />

              {/* {formik.touched.email && formik.errors.email ? (
                <p className="text-xs mt-1 text-red-500">
                  {formik.errors.email}
                </p>
              ) : null} */}
            </div>
          </form>
          <p className="mt-2 text-red-500">{toastError}</p>
        </div>
      </div>
      <div className="container sm:max-w-[80%] pb-[5rem] sm:flex-col w-4/5 mx-auto flex justify-between">
        <Link to={"/"}>
          {" "}
          <div className="flex items-center gap-0">
            <img src={backarrowsvg} alt="rightArrow" className="mr-2 pl-2" />
            <p className="bg-inherit text-black py-2 sm:text-[16px] rounded text-lg mr-3">
              Back To Home Page
            </p>
          </div>
        </Link>
        <div className="sm:flex sm:justify-center gap-2">
          <button
            onClick={() => props.prev()}
            className="bg-white border-[1px] text-black px-10 py-2 rounded text-lg"
          >
            Back
          </button>{" "}
          <button
            type="submit"
            onClick={() => handleSubmit(formik.values, formik, props.stepIndex)}
            className="bg-black text-white px-16 sm:px-6 py-2 rounded text-lg hover:bg-white hover:text-black border-[1px]"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

////////////Faq Step////////////////////
const Step4 = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const items = [
    {
      title: "What types of clients does your company work with? ",
      content:
        "Our company collaborates with a diverse range of Singaporean clients across all industries, including SMEs earning less than $1 million SGD. We help you find opportunities that align with your career aspirations and skills in sectors like finance, technology, healthcare, education, and more.",
    },
    {
      title:
        "As a Malaysian working for a Singaporean employer, am I covered by Malaysian labor laws?",
      content:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      title: "How can I find opportunities to work with Singaporean employers?",
      content:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
    {
      title: "Will I need to go to Singapore to work and acquire a work visa?",
      content:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
    {
      title:
        "Will there be an opportunity for me to relocate to Singapore, and if so, will relocation and accommodation be handled?",
      content:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
    {
      title:
        "What are the requirements for applying to jobs with Singaporean employers?",
      content:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
    {
      title:
        "What kind of support does your company provide during the job application process?",
      content:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    },
  ];
  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const formik = useFormik({
    initialValues: props.data,
    // validationSchema: validationSchemaStep4,
    onSubmit: (values) => {},
  });
  return (
    <>
      <div className="faq-page-container pb-[5rem] ">
        {/* <CandidateNavbar /> */}
        <div className="p-4">
          <img src={logo} alt="logo" />
        </div>
        <div className="mb-[64px] mt-[64px]  flex flex-col  container mx-auto sm:ml-[3rem] sm:mx-0 ">
          <h1 className="font-bold text-4xl mb-2">FAQ</h1>
          <p className="text-[#696969] sm:w-[400px]">
            Our FAQ section is tailored to address your needs, providing
            insights into how BMG Talent Concierge Plus bridges the talent gap
            between Singapore and Malaysia.
          </p>
        </div>
        <div className="flex md:container lg:container xl:container 2xl:container lg:flex-col md:flex-col sm:flex-col  mx-auto">
          <div className="w-[50%] lg:w-[90%] md:w-[90%] sm:w-[90%]  mx-auto mt-8">
            {items.map((item, index) => (
              <div key={index} className="border-b-2">
                <div
                  className="p-4 cursor-pointer flex items-center justify-between"
                  onClick={() => handleClick(index)}
                >
                  <h2 className="text-[18px] font-medium  flex">
                    {" "}
                    <span className="mr-4  text-[#696969]">
                      {(index + 1).toString().padStart(2, "0")}
                    </span>
                    <div>{item.title}</div>
                  </h2>
                  <div className="flex align-middle">
                    <span className="ml-2 w-[13px]">
                      {activeIndex === index ? (
                        <img src={closeArrow} alt="Close" />
                      ) : (
                        <img src={openArrow} alt="Open" />
                      )}
                    </span>
                  </div>
                </div>
                {activeIndex === index && (
                  <div className=" pb-4 ml-12 font-medium text-[14px] text-[#696969] ">
                    <p>{item.content}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className=" mt-[-4rem] lg:w-[90%] lg:mt-[2rem] lg:flex lg:justify-center md:w-[90%] md:mt-[2rem] md:flex md:justify-center  sm:w-[90%] sm:mt-[2rem] sm:flex sm:justify-center ">
            <img
              src={faqTablet}
              alt="faqImage"
              className="xl:w-[708px] xl:h-[508px] 2xl:w-[708px] 2xl:h-[508px]"
            />
          </div>
        </div>
      </div>
      {/* <form onSubmit={formik.handleSubmit}>
        <div className="  mx-auto sm:w-[90%] md:container lg:container xl:container 2xl:container pb-[7rem] faq-page-container2">
          <label
            className=" text-[#111827] text-[22px] font-[600] mb-2 flex items-center"
            htmlFor="expect_message"
          >
            What To Expect?
          </label>
          <textarea
            rows={14}
            type="text"
            id="expect_message"
            placeholder="Enter Your Message Here..."
            name="expect_message"
            onChange={formik.handleChange}
            value={formik.values.expect_message}
            className=" appearance-none border rounded w-full py-8 px-3 mb-8  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {formik.touched.expect_message && formik.errors.expect_message ? (
            <p className="text-xs text-left mt-1 text-red-500">
              {formik.errors.expect_message}
            </p>
          ) : null}
          <div className=" md:container lg:container xl:container 2xl:container flex justify-center">
          <button className="border-[1px]  border-black px-16 py-4 rounded text-lg flex items-center">
            Send Message{" "}
            <img src={rightArrowBlack} alt="email" className="mr-2 pl-2" />
          </button>
        </div>
        </div>
      </form> */}
      <div className="md:container lg:container xl:container 2xl:container pb-[5rem]  w-4/5 mx-auto flex justify-center">
        <button
          onClick={() => props.prev()}
          className="border-[1px]  border-black mr-[4px] px-16 py-2 rounded text-lg flex items-center"
        >
          {/* <img src={blackarrowleft} alt="email" className="mr-2 pl-2" />  */}
          Back
        </button>

        <button
          onClick={() => props.next(formik.values, formik, props.stepIndex)}
          className="border-[1px] bg-black text-white hover:bg-white hover:text-black  border-black ml-[5px] px-20  py-2 rounded text-lg flex items-center"
        >
          Continue{" "}
          {/* <img src={rightArrowBlack} alt="email" className="mr-2 pl-2" /> */}
        </button>
      </div>
    </>
  );
};
////////////////Schedular//////////////////
const Step5 = (props) => {
  const [previousData, setPreviousData] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const currentDate = new Date(); // Get the current date
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1); // Set the next date
  const selectableDate = new Date(currentDate);
  selectableDate.setDate(currentDate.getDate() + 2);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  useEffect(() => {
    const savedPayload = localStorage.getItem("step2");
    const parsedPayload = JSON.parse(savedPayload);
    setPreviousData(parsedPayload);
  }, []);

  useEffect(() => {
    const year = startDate.getFullYear();
    const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
    const day = ("0" + startDate.getDate()).slice(-2);

    const formattedStartDate = `${year}-${month}-${day}`;
    const fetchTimeSlots = async () => {
      try {
        const response = await axios.get(
          CANDIDATE_SCHEDULAR_TIME(formattedStartDate)
        );
        setTimeSlots(response?.data?.content);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching time slots:", error);
      }
    };

    fetchTimeSlots();
  }, [startDate]);
  const handleTimeSlotClick = (time, index) => {
    formik.setFieldValue("zoom_meeting_time", time.start_time);
    setSelectedTimeSlot(index); 
  };
  const formik = useFormik({
    initialValues: props.data,
    onSubmit: async (values) => {
      if (values.meeting_date === "" || values.zoom_meeting_time === "") {
       
        Swal.fire({
          icon: "error",
          title: "Meeting date and time is required",
          text: "Please Select Both.",
          confirmButtonColor: "#EEAF06",
        });
        return;
      }
      const year = startDate.getFullYear();
      const month = ("0" + (startDate.getMonth() + 1)).slice(-2);
      const day = ("0" + startDate.getDate()).slice(-2);

      const formattedStartDate = `${year}-${month}-${day}`;
      try {
        const payload = {
          ...values,
          experience: previousData?.experienceArray,
          education: previousData?.educationArray,
          language: previousData?.languageArray,
          skill: previousData?.skillArray,

          zoom_meeting_date: formattedStartDate,
          zoom_meeting_time: values.zoom_meeting_time,
        };
        props.next(payload);
        localStorage.setItem("registrationPayload", JSON.stringify(payload));
        // const response = await axios.post(REGISTER, payload);
        // if (response?.data?.success) {
        //   Swal.fire({
        //     icon: "success",
        //     title: `${response?.data?.message}`,
        //     showConfirmButton: false,
        //     timer: 1500,
        //   });
        //   navigate("/login");
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: `${response?.data?.message}`,
        //   });
        // }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
  });
  return (
    <>
      <div className="scheduling-page-container">
        <CandidateNavbar />
        <div className="container sm:max-w-[85%] mx-auto shadow-lg  bg-white rounded-xl mb-[4rem] w-[90%] py-10 mt-16 ">
          <div className="border-b-[2px] py-4 text-lg text-center font-semibold">
            You are one step closer in making your dreams a reality. Do book a
            slot with us. You will reach a confirmation <br /> email once the
            booking is done. All the best
          </div>
          <div className="p-6 rounded-xl mt-2 grid 2xl:grid-cols-[1fr_1.75fr] xl:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_2fr] gap-16">
            <div className="custom-calendar border-r-2 pr-7">
              <div className="py-4">
                <h2 className="text-[22px]">Office Hours</h2>
                <p className="text-lg sm:text-sm font-normal py-4">
                  Book A Slot To Speak To Our Team
                </p>
                <div className="flex sm:flex-col sm:gap-2 justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <img src={videoicon} alt="" className="w-8 sm:w-5" />
                    <h2 className="text-[16px] sm:text-[14px]">Zoom Meeting</h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={clock} alt="" className="w-8  sm:w-5" />
                    <h2 className="text-[16px] sm:text-[14px]">20 minutes</h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={user} alt="" className="w-8 sm:w-5" />
                    <h2 className="text-[16px] sm:text-[14px]">1 on 1</h2>
                  </div>
                </div>
              </div>
              <DatePicker
                selected={selectedDate}
                minDate={selectableDate}
                inline
                // selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  formik.setFieldValue(
                    "zoom_meeting_date",
                    date.toISOString().split("T")[0],
                    setSelectedDate(date)
                  );
                }}
              />
            </div>

            <div className="rightsection flex flex-col pt-10 ">
              <h2 className="text-lg text-[#696969]">Your Selected Date</h2>
              <h2 className="text-[16px]">Saturday, 20 Dec</h2>
              <div className="slots mt-6">
                <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 gap-4 ">
                  {isLoading ? (
                    <div className="py-8  px-8 text-center rounded-lg">
                      <Skeleton count={6} width={300} className="mb-4" />
                    </div>
                  ) : (
                    timeSlots?.map((time, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          handleTimeSlotClick(time, index);
                          formik.setFieldValue(
                            "zoom_meeting_time",
                            time.start_time
                          );
                        }}
                        className={`border-2 sm:max-w-[90%] py-3 px-8 sm:px-4 text-center rounded-lg ${
                          selectedTimeSlot === index
                            ? "bg-[#E9AA06] text-white"
                            : ""
                        }`}
                      >
                        {time?.start_time} - {time?.end_time}
                      </button>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="md:container lg:container xl:container 2xl:container pb-[5rem]  w-4/5 mx-auto flex  justify-center">
            <button
              onClick={() => props.prev()}
              className="border-[1px]  border-black mr-[4px] px-16 sm:px-8  py-2 rounded text-lg flex items-center"
            >
              {/* <img  alt="email" className="mr-2 pl-2" />{" "} */}
              Back
            </button>

            <button
              type="submit"
              // onClick={handleSubmit}
              className="border-[1px] bg-black text-white hover:bg-white hover:text-black  border-black ml-[5px] px-20  sm:px-8  py-2 rounded text-lg flex items-center"
            >
              Continue{" "}
              {/* <img src={rightArrowBlack} alt="email" className="mr-2 pl-2" /> */}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
////////////////Review the profile//////////////////
const Step6 = (props) => {
  const [myprofile, setMyProfile] = useState("image");
  const [changeImage, setChangeImage] = useState();
  const [openPic, setOpenPic] = useState(false);
  const cancelButtonRef = useRef(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [openName, setOpenName] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const [buttonLanguageVisible, setButtonLanguageVisible] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [button1Visible, setButton1Visible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [buttonSkillVisible, setButtonSkillVisible] = useState(false);
  const [buttonEducationVisible, setButtonEducationVisible] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);


  useEffect(() => {
    const savedPayload = localStorage.getItem("registrationPayload");
    setUser(JSON.parse(savedPayload));
  }, []);

  const formik = useFormik({
    initialValues: props.data,
    onSubmit: async (values) => {
      try {
        const payload = {
          ...values,
          profile: myprofile.profile,
        };
        const response = await axios.post(REGISTER, payload, {
          headers: {
            "Content-Type": "multipart/form-data", // Important for file uploads
          },
        });
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          localStorage.removeItem("step2");
          localStorage.removeItem("registrationPayload");
          navigate("/login");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
  });
  const handleLanguageClick = () => {
    setButtonLanguageVisible(!buttonLanguageVisible);
  };
  const handleEditClick1 = () => {
    setButtonEducationVisible(!buttonEducationVisible);
  };
  const handleEditClick = () => {
    setButton1Visible(!button1Visible);
  };
  const handleEditClick2 = () => {
    setButtonSkillVisible(!buttonSkillVisible);
  };
  
   //////////////// for Work Experience////////////////
   const [formData, setFormData] = useState();
  const [open, setOpen] = useState(false);
  const handleInputExperience = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const openAddModal = () => {
    setIsEditMode(false);
    setFormData({
      designation: "",
      company: "",
      description: "",
      start_date: "",
      end_date: "",
    });
    setOpen(true);
  };
  const openEditModal = (experience) => {
    setIsEditMode(true);
    setFormData({
      designation: experience.designation,
      company: experience.company,
      description: experience.description,
      start_date: experience.start_date,
      end_date: experience.end_date,
    });

    setOpen(true);
  };

  const handleDeleteExperience = (index) => {
    const updatedExperience = [...user.experience];
    updatedExperience.splice(index, 1);
   
    setUser(prevState => ({
      ...prevState,
      experience: updatedExperience
    }));
    
    // Update local storage with the modified user data
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      experience: updatedExperience
    }));
    setOpen(false)
  };
  
 
const [experienceIndex,setExperienceIndex]=useState()


  const handleUpdateExperience = () => {
    let updatedExperiences;
    if (isEditMode) {
      const tempExperiences = [...user.experience];
      tempExperiences[experienceIndex] = formData; // Replace the experience at the editIndex with formData
      updatedExperiences = tempExperiences;
    } else {
      updatedExperiences = [...user.experience, formData];
    }
    
    setUser(prevState => ({
      ...prevState,
      experience: updatedExperiences
    }));
  
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      experience: updatedExperiences
    }));
  
    setOpen(false);
  };
  
   //////////////// for Education////////////////
  const [educationData, setEducationData] = useState();

   const openEditEducation = (experience) => {
    setIsEditMode(true);
    setEducationData({
      university: experience.university,
      degree: experience.degree,
      start_date: experience.start_date,
      end_date: experience.end_date,
      _id: experience._id,
    });
    setOpenEducation(true);
  };
  const openAddEducation = () => {
    setIsEditMode(false);
    setEducationData({
      degree: "",
      university: "",
      start_date: "",
      end_date: "",
    });
    setOpenEducation(true);
  };

  const handleAddEducation = () => {
    let updatedEducation;
    if (isEditMode) {
      const tempEducation = [...user.education];
      tempEducation[experienceIndex] = educationData; // Replace the experience at the editIndex with formData
      updatedEducation = tempEducation;
    } else {
      updatedEducation = [...user.education, educationData];
    }
    
    setUser(prevState => ({
      ...prevState,
      education: updatedEducation
    }));
  
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      education: updatedEducation
    }));
  
    setOpenEducation(false);
  };

  const handleInputEducation = (e) => {
    const { name, value } = e.target;

    setEducationData({
      ...educationData,
      [name]: value,
    });
  };

  const handleDeleteEducation = (index) => {
    const updatedEducation = [...user.education];
    updatedEducation.splice(index, 1);
   
    setUser(prevState => ({
      ...prevState,
      education: updatedEducation
    }));
    
    // Update local storage with the modified user data
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      education: updatedEducation
    }));
    setOpenEducation(false)
  };



  //////////////for skill//////////////////////////
  const [openSkill, setOpenSkill] = useState(false);
  const [skillData, setSkillData] = useState({
    name: "",
    rating: "",
    type: "",
  });
  const handleInputSkill = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setSkillData({
      ...skillData,
      [name]: value,
    });
  };
  
  const handleAddSkill = () => {
    
    const updatedSkill = [...user.skill];
   
    updatedSkill.push(skillData);
   
    setUser(prevState => ({
      ...prevState,
      skill: updatedSkill
    }));

    
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      skill: updatedSkill
    }));

    setOpenSkill(false);
  };

  const handleDeleteSkill = (index) => {
    // Create a copy of the array
    const updatedSkill = [...user.skill];
    // Remove the language item at the specified index
    updatedSkill.splice(index, 1);
    // Update the state with the modified array
    setUser(prevState => ({
      ...prevState,
      skill: updatedSkill
    }));
    
    // Update local storage with the modified user data
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      skill: updatedSkill
    }));

  };
  


   //////////////for Language//////////////////////////
   const [languageData, setLanguageData] = useState({
    lan: "",
    reading: "",
    speaking: "",
    writing: "",
  });

  const handleInputLanguage = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLanguageData({
      ...languageData,
      [name]: value,
    });
  };
  
  const handleAddLanguage = () => {
    
    const updatedLanguages = [...user.language];
   
    updatedLanguages.push(languageData);
   
    setUser(prevState => ({
      ...prevState,
      language: updatedLanguages
    }));

    
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      language: updatedLanguages
    }));

    setOpenLanguage(false);
  };

  const handleDeleteLanguage = (index) => {
    // Create a copy of the array
    const updatedLanguage = [...user.language];
    // Remove the language item at the specified index
    updatedLanguage.splice(index, 1);
    // Update the state with the modified array
    setUser(prevState => ({
      ...prevState,
      language: updatedLanguage
    }));
    
    // Update local storage with the modified user data
    localStorage.setItem("registrationPayload", JSON.stringify({
      ...user,
      language: updatedLanguage
    }));
  };
  






  ///////////////// for update the data /////////////////////
  const [stepsData,setStepsData]=useState(props.data)

  const handleUpdate = () => {
    const updatedData = {
      ...props.data,
      first_name: stepsData.first_name,
      last_name: stepsData.last_name,
      title: stepsData.title,
      address:stepsData.address,
      cell_phone:stepsData.cell_phone,
      notice_period:stepsData?.notice_period,
      about_me:stepsData?.about_me
    };
    setStepsData(updatedData);
    setOpenName(false);
    setOpenAddress(false);
    setOpenNotice(false)
    setOpenAbout(false)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStepsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



    ////////////////////////////////////////////
    const noticePeriod = stepsData?.notice_period?.replace(/_/g, " ");
  return (
    <>

    {/* modal for picture update */}
      <Transition.Root show={openPic} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenPic}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[450px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[70%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Upload Picture
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenPic(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="items-center mx-8 mt-[1rem]">
                        <div className="skillsfield relative flex justify-center">
                          <img
                            src={
                              changeImage ? changeImage?.profile : maleAvatar
                            }
                            alt=""
                            className="w-[200px] h-[200px] rounded-2xl object-cover"
                          />
                          <label className=" absolute bottom-[-12px] right-[85px] left text-white  rounded-full cursor-pointer">
                            <img src={ChangeProfileImage} alt="" className="" />
                            <input
                              type="file"
                              name="profile"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                const imageUrl = URL.createObjectURL(file);

                                setChangeImage({
                                  profile: imageUrl,
                                });
                                setMyProfile({
                                  profile: file,
                                });
                                setOpenPic(false);
                              }}
                              className="hidden"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={AddProfile}
                    >
                      Save
                    </button>
                  </div> */}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for name update */}
      <Transition.Root show={openName} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenName}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[500px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%] ">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Edit Information
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenName(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-2 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="">
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            First Name
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            placeholder="First Name"
                            name="first_name"
                            value={stepsData?.first_name}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            placeholder="Last Name"
                            name="last_name"
                            value={stepsData?.last_name}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={titleicon} alt="email" className="mr-2" />{" "}
                            Title
                          </label>
                          <input
                            type="text"
                            id="title"
                            placeholder="ui/ux Designer"
                            name="title"
                            value={stepsData?.title}
                            onChange={handleInputChange}
                            className=" appearance-none w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />

                          {/* {formik.touched.name && formik.errors.name ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.name}
                            </p>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for address and phone  */}
      <Transition.Root show={openAddress} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenAddress}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[400px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Edit Information
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenAddress(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="">
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            Address
                          </label>
                          <input
                            type="text"
                            id="address"
                            placeholder="Address"
                            name="address"
                            value={stepsData?.address}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={titleicon} alt="email" className="mr-2" />{" "}
                            Phone Number
                          </label>
                          <input
                            type="text"
                            id="cell_phone"
                            placeholder="+123123123123"
                            name="cell_phone"
                            maxLength={12}
                            value={stepsData?.cell_phone}
                            onChange={handleInputChange}
                            className=" appearance-none w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for language */}
      <Transition.Root show={openLanguage} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenLanguage}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[400px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 sm:w-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Edit Language
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenLanguage(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="">
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            Language
                          </label>
                          <select
                            id="language"
                            name="lan"
                            value={languageData?.lan}
                            onChange={(event) => handleInputLanguage(event)}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="" disabled>
                              Choose Language
                            </option>
                            <option value="English">English</option>
                            <option value=" Mandarin/Chinese">
                              {" "}
                              Mandarin/Chinese
                            </option>
                            <option value="Cantonese">Cantonese</option>
                            <option value="Hokkien">Hokkien</option>
                            <option value="Bahasa">Bahasa </option>
                            <option value="Malaysian">Malaysian</option>
                          </select>

                          <div className="grid grid-cols-3 gap-4 mt-4">
                            <select
                              id="spoken"
                              name="spoking"
                              value={languageData?.spoking}
                              onChange={(event) => handleInputLanguage(event)}
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled selected>
                                Spoken
                              </option>

                              <option value="PROFICIENT">Proficient</option>
                              <option value="FLUENT">Fluent</option>
                              <option value="CONVERSATIONAL">
                                Conversational
                              </option>
                            </select>
                            <select
                              id="writing"
                              name="writing"
                              value={languageData?.writing}
                              onChange={(event) => handleInputLanguage(event)}
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled selected>
                                Writing
                              </option>
                              <option value="PROFICIENT">Proficient</option>
                              <option value="FLUENT">Fluent</option>
                              <option value="CONVERSATIONAL">
                                Conversational
                              </option>
                            </select>
                            <select
                              id="reading"
                              name="reading"
                              value={languageData?.reading}
                              onChange={(event) => handleInputLanguage(event)}
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled selected>
                                Reading
                              </option>
                              <option value="PROFICIENT">Proficient</option>
                              <option value="FLUENT">Fluent</option>
                              <option value="CONVERSATIONAL">
                                Conversational
                              </option>
                            </select>
                          </div>

                          {/* {formik.touched.name && formik.errors.name ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.name}
                            </p>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleAddLanguage}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
       {/* modal for notice Period */}
       <Transition.Root show={openNotice} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenNotice}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[280px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl sm:text-lg font-medium leading-6 text-gray-900"
                        >
                          Edit Notice Period
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenNotice(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="">
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={iconnn} alt="email" className="mr-2" />{" "}
                            Notice Period
                          </label>
                          <select
                            id="noticePeriod"
                            name="notice_period"
                            value={stepsData?.notice_period}
                            onChange={handleInputChange}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="" disabled selected>
                              Please Select 1
                            </option>
                            <option value="ONE_WEEK">1 week</option>
                            <option value="TWO_WEEK">2 week</option>
                            <option value="THREE_WEEK">3 week</option>
                            <option value="ONE_MONTH">1 month</option>
                            <option value="TWO_MONTH">2 month</option>
                            <option value="THREE_MONTH">3 month</option>
                          </select>
                          {/* {formik.touched.name && formik.errors.name ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.name}
                            </p>
                          ) : null} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
       {/* modal for about */}
       <Transition.Root show={openAbout} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenAbout}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[400px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl sm:text-lg font-medium leading-6 text-gray-900"
                        >
                          Add About Yourself
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenAbout(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" items-center mx-8 mt-[1rem]">
                        <div className="skillsfield">
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={titleicon} alt="email" className="mr-2" />{" "}
                            About Yourself
                          </label>

                          <textarea
                            rows={6}
                            name="about_me"
                            value={stepsData?.about_me}
                            onChange={handleInputChange}
                            id="about_me"
                            className="block w-full pl-4 outline-0 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                            // defaultValue={""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleUpdate}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for work experience */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" overflow-y-auto relative transform w-[70%] h-[600px] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:max-w-[80%]">
                  <div className="">
                    <form onSubmit={handleUpdateExperience}>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 ">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl sm:text-lg font-medium leading-6 text-gray-900"
                        >
                          Add Experience
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className=" w-[90%]  mx-auto  mt-[1rem] md:w-full sm:w-full">
                        <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                          <img src={titleicon} alt="email" className="mr-2" />{" "}
                          Title
                        </label>

                        <input
                          type="text"
                          id="designation"
                          placeholder="ui/ux Designer"
                          name="designation"
                          value={formData?.designation}
                          // onChange={(e) =>
                          //   setFormData({
                          //     ...formData,
                          //     [e.target.name]: e.target.value
                          //   })
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.designation &&
                        formik.errors.designation ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.designation}
                          </p>
                        ) : null}
                        <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                          <img
                            src={buildingicon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Company Name
                        </label>
                        <input
                          type="text"
                          id="company"
                          placeholder="BMG Talent"
                          name="company"
                          value={formData?.company}
                          // onChange={(e) =>
                          //   setFormData({
                          //     ...formData,
                          //     [e.target.name]: e.target.value
                          //   })
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.company && formik.errors.company ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.company}
                          </p>
                        ) : null}
                        <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                          <img
                            src={buildingicon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Job Description
                        </label>
                        <input
                          type="text"
                          id="description"
                          placeholder="job description ....."
                          name="description"
                          value={formData?.description}
                          // onChange={(e) =>
                          //   setFormData({
                          //     ...formData,
                          //     [e.target.name]: e.target.value
                          //   })
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.description}
                          </p>
                        ) : null}
                        <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                          <img
                            src={calendaricon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          Start Date
                        </label>
                        <input
                          type="date"
                          id="start_date"
                          placeholder="BMG Talent"
                          name="start_date"
                          value={formData?.start_date}
                          // onChange={(e) =>
                          //   setFormData({
                          //     ...formData,
                          //     [e.target.name]: e.target.value
                          //   })
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.start_date &&
                        formik.errors.start_date ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.start_date}
                          </p>
                        ) : null}
                        <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                          <img
                            src={calendaricon}
                            alt="email"
                            className="mr-2"
                          />{" "}
                          End Date
                        </label>
                        <input
                          type="date"
                          id="end_date"
                          placeholder="BMG Talent"
                          name="end_date"
                          value={formData?.end_date}
                          // onChange={(e) =>
                          //   setFormData(...formData,"end_date", e.target.value )
                          // }
                          onChange={(event) => handleInputExperience(event)}
                          className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        {formik.touched.end_date && formik.errors.end_date ? (
                          <p className="text-xs text-left mt-1 text-red-500">
                            {formik.errors.end_date}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] border-[1px] px-[70px] rounded-md   py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                      onClick={handleUpdateExperience}
                    >
                      Save
                    </button>
                    <button
                      onClick={() => handleDeleteExperience(formData?._id)}
                      className="border-[1px] px-[70px] rounded-md   py-2 text-sm font-semibold text-black shadow-sm  sm:ml-3 sm:w-auto hover:text-white hover:bg-[#E9AA06]"
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* modal for skill */}
      <Transition.Root show={openSkill} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenSkill}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[400px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    {/* <form onSubmit={handleAddSkill}> */}
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 sm:w-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Add Skills
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenSkill(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    {/* </form> */}
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className="grid grid-cols-1 items-center mx-8 mt-[1rem]">
                        <div className="skillsfield">
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img
                              src={titleicon}
                              alt="titleIcon"
                              className="mr-2"
                            />{" "}
                            Choose Skill Type
                          </label>
                          <select
                            id="select"
                            name="type"
                            // value={skillData?.type} // Set the value to formik's state
                            onChange={handleInputSkill} // Handle change to update the state
                            className=" appearance-none border rounded w-full my-2 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="" disabled selected>
                              Please Select 1
                            </option>
                            <option value="TECH">Technical Skill</option>
                            <option value="SOFT">Soft Skill</option>
                          </select>
                        </div>

                        <div className="grid grid-cols-2 items-center mx-8 mt-[1rem]">
                          <div className="skillsfield">
                            <label className=" text-black-500 text-[18px] mb-2 flex items-center ">
                              <img
                                src={titleicon}
                                alt="email"
                                className="mr-2"
                              />{" "}
                              Skills
                            </label>
                            <input
                              type="text"
                              id="name"
                              placeholder="ui/ux Designer"
                              name="name"
                              // value={skillData.name}
                              onChange={handleInputSkill}
                              className=" appearance-none w-[90%] border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="Ratingfield">
                            <label className=" text-black-500 text-[18px] mb-2 flex items-center ">
                              <img src={rating} alt="email" className="mr-2" />{" "}
                              Rating
                            </label>

                            <select
                              id="select"
                              name="rating"
                              // value={skillData?.rating} // Set the value to formik's state
                              onChange={handleInputSkill} // Handle change to update the state
                              className=" appearance-none w-[90%] border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value="" disabled selected>
                                Choose Number Between 1 to 10
                              </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleAddSkill}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
       {/* model for education */}
       <Transition.Root show={openEducation} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenEducation}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <form onSubmit={handleAddEducation}>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 sm:w-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Add Education
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenEducation(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="items-center mx-8 mt-[1rem]">
                        <div className="skillsfield">
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                            <img
                              src={degreeicon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            Degree
                          </label>
                          <input
                            type="text"
                            id="degree"
                            placeholder="ui/ux Designer"
                            name="degree"
                            value={educationData?.degree}
                            onChange={(event) => handleInputEducation(event)}
                            className=" appearance-none w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.degree && formik.errors.degree ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.degree}
                            </p>
                          ) : null}
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                            <img
                              src={degreeicon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            University
                          </label>
                          <input
                            type="text"
                            id="university"
                            placeholder="University Name"
                            name="university"
                            value={educationData?.university}
                            onChange={(event) => handleInputEducation(event)}
                            className=" appearance-none w-full border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.university &&
                          formik.errors.university ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.university}
                            </p>
                          ) : null}
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                            <img
                              src={calendaricon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            Start Date
                          </label>
                          <input
                            type="date"
                            id="start_date"
                            placeholder="BMG Talent"
                            name="start_date"
                            value={educationData?.start_date}
                            onChange={(event) => handleInputEducation(event)}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.start_date &&
                          formik.errors.start_date ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.start_date}
                            </p>
                          ) : null}
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-6">
                            <img
                              src={calendaricon}
                              alt="email"
                              className="mr-2"
                            />{" "}
                            End Date
                          </label>
                          <input
                            type="date"
                            id="end_date"
                            placeholder="BMG Talent"
                            name="end_date"
                            value={educationData?.end_date}
                            onChange={(event) => handleInputEducation(event)}
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          />
                          {formik.touched.end_date && formik.errors.end_date ? (
                            <p className="text-xs text-left mt-1 text-red-500">
                              {formik.errors.end_date}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleAddEducation}
                    >
                      Save
                    </button>
                    <button
                      onClick={() => handleDeleteEducation(educationData._id)}
                      className="border-[1px] px-[70px] rounded-md   py-2 text-sm font-semibold text-black shadow-sm  sm:ml-3 sm:w-auto hover:text-white hover:bg-[#E9AA06]"
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div>
        <CandidateNavbar title={"Please Review Your Profile"} />
        <div className="">
          <div className="grid 2xl:grid-cols-[300px_1fr] xl:grid-cols-[300px_1fr] lg:grid-cols-[280px_1fr] md:grid-cols-1mt-10">
            <div className="leftsection bg-white  rounded flex flex-col justify-between h-full py-12 px-7">
              <div className="top">
                <div className="relative">
                  {/* {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={150} count={4}  />
                  </div>
                ) : ( */}
                  <img
                    src={changeImage ? changeImage?.profile : maleAvatar}
                    alt="profilePicture"
                    className="w-[200px] h-[200px] rounded-full object-cover mx-auto"
                  />
                  {/* )} */}
                  <label className="block  text-white px-1 py-1 rounded-full cursor-pointer">
                    <button onClick={() => setOpenPic(true)}>
                      <img
                        src={ChangeProfileImage}
                        alt=""
                        className="absolute right-[-8px] bottom-[-3px]"
                      />
                    </button>
                  </label>
                </div>
                {/* {isLoading ? (
                <div className="skeleton-loader">
                  <Skeleton width={50} count={1} />
                </div>
              ) : ( */}
                <div className="relative candidateinfo bg-[#f9f9f9] border-[1px] mt-6 rounded-md py-1 px-[12px] flex flex-col items-center justify-center  ">
                <img
                    src={Edit}
                    alt=""
                    className="absolute right-1 top-1 cursor-pointer"
                    onClick={() => setOpenName(true)}
                  />

                  <h2 className="text-[16px] text-black font-semibold">
                    {stepsData?.first_name} {stepsData?.last_name}
                  </h2>
                  <p className="text-[12px] text-center font-normal text-[#696969]">
                    {stepsData?.title}
                  </p>
                </div>
                <div className="relative candidateinfo bg-[#f9f9f9] border-[1px] mt-[0.5rem] rounded-md py-1 px-[12px] flex flex-col items-center justify-center  ">
                  <h2 className="text-[16px] text-black font-semibold">
                    Email:
                  </h2>
                  <p className="text-[12px] text-center font-normal text-[#696969]">
                    {user?.email}
                  </p>
                </div>
                {/* )} */}
                {/* {isLoading ? (
                <div className="skeleton-loader">
                  <Skeleton width={150} count={4} />
                </div>
              ) : ( */}
                <div className=" relative contactinfo bg-[#f9f9f9] border-[1px] mt-[0.5rem] rounded-md py-6 px-4">
                  <img
                    src={Edit}
                    alt=""
                    className="absolute right-1 top-1 cursor-pointer"
                    onClick={() => setOpenAddress(true)}
                  />

                  <div className="location flex items-start gap-2">
                    <img src={Location} alt="" className="w-4 h-4" />
                    {stepsData?.address ? (
                      <p className="text-[12px] font-medium">{stepsData?.address}</p>
                    ) : (
                      <p className="text-[12px] font-medium">
                        Please add your address
                      </p>
                    )}
                  </div>
                  <div className="phone flex items-start gap-2 mt-8">
                    <img src={Phone} alt="" className="w-4 h-4" />
                    <p className="text-[12px] font-medium">
                      +{stepsData?.cell_phone}
                    </p>
                  </div>
                </div>
               
                <div className="languages mt-8">
                  <div className="flex justify-between items-center">
                    <h2 className="text-[16px] text-black font-semibold">
                      Languages
                    </h2>
                    <img
                    src={Edit}
                    alt=""
                    onClick={handleLanguageClick}
                    className="cursor-pointer"
                  />
                  </div>
                  {/* {isLoading ? (
                  <div className="skeleton-loader">
                    <Skeleton width={150} count={4} />
                  </div>
                ) : ( */}
                  <div className="flex flex-col items-left w-[80%] gap-4 mt-3 ">
                    {user?.language?.map((item, index) => (
                      <h2
                        key={index}
                        className="relative 2xl:text-[13px] capitalize xl:text-[13px] lg:text-[12px] md:text-[10px] text-[10px] text-white bg-gradient-to-l from-[#E9AA06] to-[#FFC107] py-1 2xl:px-2 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                      >
                        {item.lan} : {item.spoking}
                        {buttonLanguageVisible ? (
                          <button
                            onClick={() => handleDeleteLanguage(index)}
                          >
                            <img
                              src={smallcross}
                              alt=""
                              className="absolute top-[-4px] right-[-4px] "
                            />
                          </button>
                        ) : null}
                      </h2>
                    ))}
                    {buttonLanguageVisible ? (
                      <button
                        className=" max-h-[30px] min-h-[30px]  bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[10px] rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                        onClick={() => setOpenLanguage(true)}
                      >
                        +
                      </button>
                    ) : null}
                  </div>
                  {/* )} */}
                </div>
                <div className="noticePeriod mt-8">
                  <div className="flex justify-between items-center">
                    <h2 className="text-[16px] text-black font-semibold">
                      Notice Period
                    </h2>
                    <img
                    src={Edit}
                    alt=""
                    onClick={() => setOpenNotice(true)}
                    className="cursor-pointer"
                  />
                  </div>

                  <div className="flex items-center gap-2 mt-3">
                    {/* {isLoading ? (
                    <div className="skeleton-loader">
                      <Skeleton width={150} count={1} />
                    </div>
                  ) : ( */}
                    <h2 className="2xl:text-[13px] xl:text-[13px] lg:text-[12px] md:text-[10px] text-[10px] text-white bg-gradient-to-l from-[#E9AA06] to-[#FFC107] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal">
                      {noticePeriod}
                    </h2>
                    {/* // )} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="rightsection bg-[#F8F8FB] px-6 pt-6 ">
              <div className="aboutsection border-[1px] relative bg-white  rounded-md shadow-md py-12 px-14 md:py-6 sm:py-4 md:px-4 sm:px-4 ">
                <img
                src={Edit}
                alt=""
                className="absolute right-2 top-2 cursor-pointer "
                onClick={() => setOpenAbout(true)}
              />
                <h2 className="text-lg text-[#495057] font-medium">About Me</h2>
                {/* {isLoading ? (
                <div className="skeleton-loader">
                  <Skeleton width={800} count={2} />
                </div>
              ) : ( */}
                <p className="text-[15px] md:text-[14px] sm:text-[14px] font-medium leading-[26px] pt-2 pr-16 text-[#696969] rounded-md">
                  {stepsData?.about_me}
                </p>
                {/* )} */}
              </div>
            

              <div className="workexperience border-[1px] relative bg-white rounded-md shadow-md py-14 px-14 md:py-2 sm:pt-4 md:px-2 sm:px-4 mt-6">
                <img
                src={Edit}
                alt=""
                className="absolute right-2 top-2 cursor-pointer"
                onClick={handleEditClick}
              />

                <h2 className="text-[22px] text-[#495057] font-medium">
                  Work Experience
                </h2>
                {/* {isLoading ? (
                <div className="skeleton-loader">
                  <Skeleton width={800} count={6} />
                </div>
              ) : ( */}

                <div>
                  {user?.experience?.length > 0 ? (
                    <div>
                      {user?.experience?.map((exp, index) => (
                        <div
                          key={index}
                          className="flex items-baseline justify-between  px-[1rem] mb-2 py-[15px] pb-[5px] bg-white border-b-[1px] "
                        >
                          <div>
                            <p className="text-[14px] font-medium leading-[20px] pt-1 pr-16 text-[#696969]">
                              {exp.designation} | {exp.company} |
                            </p>
                            <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#E9AA06]">
                              {exp.start_date} {exp.end_date}
                            </p>
                            <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                              {exp.description}
                            </p>
                          </div>

                          {button1Visible ? (
                        <div className="">
                          <img
                            src={Edit}
                            alt=""
                            onClick={() => {
                              openEditModal(exp)
                              setExperienceIndex(index)
                            }}
                            className="cursor-pointer"
                          />
                        </div>
                      ) : null}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-[22px] sm:text-[16px] text-[#878d93] font-medium pt-4 text-center">
                      Please add your Experience
                    </p>
                  )}
                </div>
                {/* )} */}
                {button1Visible ? (
                <button
                  className=" absolute  right-1  bottom-1  max-h-[30px] min-h-[30px] bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[30px] rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                  onClick={openAddModal}
                >
                  Add +
                </button>
              ) : null}
              </div>
              <div className="Skills border-[1px] relative bg-white rounded-md shadow-md py-10 px-14 mt-6">
                <img
                src={Edit}
                alt=""
                className="absolute right-2 top-2 cursor-pointer"
                onClick={handleEditClick2}
                // onClick={() => setOpenSkill(true)}
              />

                <h2 className="text-[22px] text-[#495057] font-medium">
                  Skills
                </h2>
                {/* {isLoading ? (
                <div className="skeleton-loader">
                  <Skeleton width={800} count={3} />
                </div>
              ) : ( */}
                <div className="max-h-[220px] min-h-[220px] overflow-y-auto bg-white">
                  <h2 className="text-gray-700 text-[16px] text-left px-4 pt-4">
                    Tech Skills
                  </h2>
                  {user?.skill?.length > 0 ? (
                    <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                      {user?.skill?.map(
                        (title, index) =>
                          title.type === "TECH" && (
                            <h2
                              key={index}
                              className="relative text-[13px] text-center py-[9px]   border-[1px] rounded-md bg-white"
                            >
                              {title.name} {title.rating}/10
                              {buttonSkillVisible ? (
                              <button
                                onClick={() => {
                                  handleDeleteSkill(index)
                                  setExperienceIndex(index)
                                }}
                              >
                                <img
                                  src={smallcross}
                                  alt=""
                                  className="absolute top-[-4px] right-[-4px] "
                                />
                              </button>
                            ) : null}
                            </h2>
                          )
                      )}
                    </div>
                  ) : (
                    <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                      Please add your Tech Skills
                    </p>
                  )}
                </div>
                {/* // )} */}
                {/* {isLoading ? (
                <div className="skeleton-loader">
                  <Skeleton width={800} count={3} />
                </div>
              ) : ( */}
                <div className="max-h-[220px] min-h-[220px] overflow-y-auto bg-white">
                  <h2 className="text-gray-700 text-[16px] text-left px-4 pt-4">
                    Soft Skills
                  </h2>
                  {user?.skill?.length > 0 ? (
                    <div>
                      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                        {user?.skill?.map(
                          (title, index) =>
                            title.type === "SOFT" && (
                              <h2
                                key={index}
                                className="relative text-[13px] text-center py-[9px]   border-2 rounded-md bg-white"
                              >
                                {title.name} {title.rating}/10
                                {buttonSkillVisible ? (
                              <button
                                onClick={() => {
                                  handleDeleteSkill(index)
                                  setExperienceIndex(index)
                                }}
                              >
                                <img
                                  src={smallcross}
                                  alt=""
                                  className="absolute top-[-4px] right-[-4px] "
                                />
                              </button>
                            ) : null}
                              </h2>
                            )
                        )}
                      </div>
                    </div>
                  ) : (
                    <p className="text-[22px] text-[#878d93] font-medium pt-4 max-h-[180px] min-h-[180px] flex justify-center items-center">
                      Please add your Soft Skills
                    </p>
                  )}
                </div>
                {/* // )} */}
                {buttonSkillVisible ? (
                <button
                  className=" absolute  right-1  bottom-1  max-h-[30px] min-h-[30px] bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[30px] rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                  onClick={() => {
                    setOpenSkill(true)
                    
                  }}
                >
                  Add +
                </button>
              ) : null}
              </div>
              <div className="education border-[1px] relative bg-white rounded-md shadow-md py-14 px-14 mt-6 mb-8">
                <img
                src={Edit}
                alt=""
                className="absolute right-2 top-2 cursor-pointer"
                onClick={handleEditClick1}
              />

                <h2 className="text-[22px] text-[#495057] font-medium">
                  Education
                </h2>
                {/* {isLoading ? (
                <div className="skeleton-loader">
                  <Skeleton width={800} count={4} />
                </div>
              ) : ( */}
                <div>
                  {user?.education?.length > 0 ? (
                    <div>
                      {user?.education?.map((edu, index) => (
                        <div
                          key={index}
                          className="flex items-baseline justify-between  px-[1rem] mt-[15px] pb-[10px] border-solid border-b-[1px] mb-2 bg-white py-2"
                        >
                          <div>
                            <p className="text-[14px] font-medium leading-[25px]  pr-16 text-[#696969]">
                              {edu.degree} | {edu.university} |
                            </p>
                            <p className="text-[14px] font-medium leading-[25px] pr-16 text-[#E9AA06]">
                              {edu.start_date} {edu.end_date}
                            </p>
                          </div>
                          {buttonEducationVisible ? (
                        <div className="">
                          <img
                            src={Edit}
                            alt=""
                            onClick={() =>{
                               openEditEducation(edu)
                               setExperienceIndex(index)
                              }}
                            className="cursor-pointer"
                          />
                        </div>
                      ) : null}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-[22px] text-[#878d93] font-medium pt-4 text-center">
                      Please add your Education
                    </p>
                  )}
                </div>
                {/* )} */}
                {buttonEducationVisible ? (
                <button
                  className=" absolute  right-1  bottom-1  max-h-[30px] min-h-[30px] bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[30px] rounded-md text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto"
                  onClick={openAddEducation}
                >
                  Add +
                </button>
              ) : null}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="md:container lg:container xl:container 2xl:container pb-[5rem]  w-4/5 mx-auto flex gap-2 sm:flex-col sm:gap-2 sm:justify-center sm:items-center justify-center">
                  <button
                    onClick={() => props.prev()}
                    className="border-[1px]  border-black px-16 py-2 rounded text-lg flex items-center"
                  >
                    Back
                  </button>

                  <button
                    type="submit"
                    // onClick={handleSubmit}
                    className="border-[1px] bg-black text-white hover:bg-white hover:text-black  border-black  px-20  py-2 rounded text-lg flex items-center"
                  >
                    Submit{" "}
                    {/* <img src={rightArrowBlack} alt="email" className="mr-2 pl-2" /> */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function CandidateSignup() {
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    title: "",
    address: "",
    notice_period: "",
    cell_phone: "",
    password: "",
    date_of_birth: "",
    gender: "",
    country: "",
    city: "",
    role: "CANDIDATE",
    about_me: "",
    // profile : maleAvatar,
    experience: [],
    skill: [],
    education: [],
    language: [],
    question: [],
    // profile: "",
    expect_message: "",
    zoom_meeting_date: "",
    zoom_meeting_time: "",
    zoom_meeting_url: "http://localhost:3000/zoom-home",
  });
  ////////////////// Handle all the steps/////////////////
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep2 = (newData, formik, stepIndex) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
  };

  const validationSchemas = [
    validationSchemaStep1,
    validationSchemaStep2,
    // validationSchemaStep4,
    // validationSchemaStep3,
  ];

  const handleNextStep = async (newData, formik, stepIndex) => {
    const { confirmPassword, ...payload } = newData;

    if (validationSchemas[stepIndex]) {
      const currentSchema = validationSchemas[stepIndex];

      // Validate against the current schema
      const errors = await currentSchema
        .validate(newData, { abortEarly: false })
        .catch((err) => err);
      if (!errors.errors || errors.errors.length === 0) {
        setData((prev) => ({ ...prev, ...payload }));
        setCurrentStep((prev) => prev + 1);
      } else {
        // Update Formik with the errors from the current step
        let formattedErrors = {};
        errors.inner.forEach((error) => {
          if (!formattedErrors[error.path]) {
            formattedErrors[error.path] = error.message;
          }
        });
        formik.setErrors(formattedErrors);
        formik.setTouched(formattedErrors);
      }
    } else {
      // If no validation schema, simply proceed to the next step
      setData((prev) => ({ ...prev, ...payload }));
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleprevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };
  const steps = [
    <Step1 next={handleNextStep} data={data} stepIndex={0} />,
    <Step2
      next2={handleNextStep2}
      data={data}
      prev={handleprevStep}
      stepIndex={1}
    />,
    <Step3
      next2={handleNextStep2}
      data={data}
      prev={handleprevStep}
      // stepIndex={2}
    />,
    <Step4
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      stepIndex={2}
    />,
    <Step5
      next={handleNextStep}
      data={data}
      prev={handleprevStep}
      stepIndex={4}
    />,
    <Step6
      next2={handleNextStep}
      data={data}
      prev={handleprevStep}
      stepIndex={5}
    />,
  ];

  return <>{steps[currentStep]}</>;
}

export default CandidateSignup;
