// import { useContext, useEffect } from "react";
// import { ClientContext, UserContext } from "../Context/globalContext";
// import logo from "../../../assets/images/logo.png";
// import ZoomVideo from "@zoom/videosdk";

// const Video = ({ handleJoin, handleClose }) => {
//   var client = ZoomVideo.createClient()
//   const cloudRecording = client.getRecordingClient()
//   
//   // 
//   const uitoolkit = useContext(ClientContext);
//   const meetingConfig = useContext(UserContext);

//   // 
//   useEffect(() => {
//     let sessionContainer = document.getElementById("session");
//     uitoolkit.uitoolkit.joinSession(sessionContainer, meetingConfig);
//     uitoolkit.uitoolkit.onSessionJoined(handleJoin);
//     uitoolkit.uitoolkit.onSessionClosed(handleClose);
//     return () => {
//       uitoolkit.uitoolkit.offSessionJoined(handleJoin);
//       uitoolkit.uitoolkit.offSessionClosed(handleClose);
//       uitoolkit.uitoolkit.closeSession(sessionContainer);
//     };
//   }, [meetingConfig, uitoolkit, handleJoin, handleClose]);

// const cloudRecord=async()=>{

//   
//  await cloudRecording.startCloudRecording()
// }
//   return (
//     <>
//       <div className="main flex flex-col justify-between h-screen">
//         <div>
//           <div className="container mx-auto flex justify-between mt-2">
//             <img src={logo} alt="" />
//           </div>
//           <div className="flex justify-center items-center">
//             <h2 className="text-[30px] font-bold">Candidate Interview</h2>
//           </div>
//           <div id="session"></div>
//         </div>
//         <div className="footer bg-[#E9AA06] py-8">
//           <button onClick={()=>cloudRecord()} className="border-4">Recording</button>
//         <h2 className="text-center text-white text-2xl">
//           BMG Talent Private LTD || All Rights By BMG Talent
//         </h2>
//       </div>
//       </div>

//     </>
//   );
// };

// export default Video;

import React, { useContext, useEffect, useState } from "react";
import { ClientContext, UserContext } from "../Context/globalContext";
import logo from "../../../assets/images/logo.png";
import ZoomVideo from "@zoom/videosdk";
import {
  getRecordingButtons,
  RecordButtonProps,
  RecordingButton,
} from "../recording/recording";
import { Button } from "antd";




const Video = ({ handleJoin, handleClose }) => {
 
  const {zmClient,uitoolkit,stream} = useContext(ClientContext);
  const meetingConfig = useContext(UserContext);
  const [recordingStatus, setRecordingStatus] = useState("");
  const isHost = zmClient.isHost();
  
  
  
  // useEffect(() => {
  //   const fetchRecordingClient = async () => {
  //     const client = zmClient?.getRecordingClient();
  //     if (client) {
  //       const status = await client.getCloudRecordingStatus();
  //       setRecordingStatus(status);
  //     }
  //   };

  //   fetchRecordingClient();
  // }, [zmClient]);

  const recordingClient = zmClient.getRecordingClient();
  
  


  useEffect(() => {
    let sessionContainer = document.getElementById("session");
    uitoolkit.joinSession(sessionContainer, meetingConfig);
    uitoolkit.onSessionJoined(handleJoin);
   uitoolkit.onSessionClosed(handleClose);

    return () => {
      uitoolkit.offSessionJoined(handleJoin);
      uitoolkit.offSessionClosed(handleClose);
      uitoolkit.closeSession(sessionContainer);
    };
  }, [meetingConfig, uitoolkit, handleJoin, handleClose, recordingClient]);

  const onRecordingClick = async (key) => {
    
    
   
    switch (key) {
      case "Record": {
        await recordingClient.startCloudRecording();
        break;
      }
      case "Resume": {
        await recordingClient.resumeCloudRecording();
        break;
      }
      case "Stop": {
        await recordingClient.stopCloudRecording();
        break;
      }
      case "Pause": {
        await recordingClient.pauseCloudRecording();
        break;
      }
      case "Status": {
        break;
      }
      default: {
        await recordingClient.startCloudRecording();
      }
    }
  
  };
  const recordingButtons = getRecordingButtons(
    recordingStatus,zmClient?.isHost()
  );
// 
  return (
    <>
      <div className="main flex flex-col justify-between h-screen">
        <div>
          <div className="container mx-auto flex justify-between mt-2">
            <img src={logo} alt="" />
          </div>
          <div className="flex justify-center items-center">
            <h2 className="text-[30px] font-bold">Candidate Interview</h2>
          </div>
          <div id="session"></div>
          <div>
            
            <Button onClick={() => onRecordingClick("Record")}>Record</Button>
            {/* {recordingButtons.map((button) => (
              <RecordingButton
                key={button.text}
                onClick={() => {
                  onRecordingClick(button.text);
                }}
                {...button}
              />
            ))} */}
          </div>
        </div>
        <div className="footer bg-[#E9AA06] py-8">
          <h2 className="text-center text-white text-2xl">
            BMG Talent Private LTD || All Rights By BMG Talent
          </h2>
        </div>
      </div>
    </>
  );
};


export default Video;
