import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClientContext, UserContext } from "../Context/globalContext.js";

import Preview from "./Preview.js";
import Video from "./Video.js";
import "./Session.css";

const Session = () => {
  const { zmClient, stream, start } = useContext(ClientContext);
  //
  const [state, updateState] = useState("preview");
  const meetingArgs = useContext(UserContext);
  const navigate = useNavigate();

  const handleJoin = async () => {
    // await zmClient
    //   .init("en-US", "Global", { patchJsMedia: true })
    //   .then(() => {
    //     zmClient
    //       .join(
    //         meetingArgs.sessionName,
    //         meetingArgs.videoSDKJWT,
    //         meetingArgs.userName,
    //         meetingArgs.sessionPasscode
    //       )
    //       .then(() => {
    //         stream = zmClient.getMediaStream();
    //       })
    //       .catch((error) => {
    //
    //       });
    //   })
    //   .catch((error) => {
    //
    //   });
  };
  const handleClose = () => {
    //
    navigate("/zoom-home");
  };

  useEffect(() => {
    if (meetingArgs.videoSDKJWT === "") {
    }
  }, [meetingArgs.videoSDKJWT, navigate]);
  if (state === "preview")
    return <Preview handleOk={() => updateState("video")} />;
  if (state === "video")
    return <Video handleClose={handleClose} handleJoin={handleJoin} />;
};

export default Session;
