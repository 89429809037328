import React, { Fragment, useState, useRef } from "react";
import profilevectoricon from "../../../assets/images/Icons/profilevectoricon.svg";
import onboardingicon from "../../../assets/images/Icons/onboardingicon.svg";
import Interviews from "../../../assets/images/Icons/Interviews.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import tick from "../../../assets/images/Icons/tick.svg";
import {
  IconCheck,
  IconChevronDown,
  IconUserPlus,
  IconUsersPlus,
} from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { IconLoader3 } from "@tabler/icons-react";
import { IconChevronUp } from "@tabler/icons-react";
import logo from "../../../assets/images/logo.png";
import { Dialog, Transition } from "@headlessui/react";
import crossicon from "../../../assets/images/Icons/crossicon.svg";
import iconnn from "../../../assets/images/Icons/iconnn.svg";
import titleicon from "../../../assets/images/Icons/titleicon.svg";
import email from "../../../assets/images/Icons/email.svg";
import password from "../../../assets/images/Icons/Password 2.svg";
import { IconEye, IconEyeOff, IconInfoCircleFilled } from "@tabler/icons-react";
import axios from "../../../components/Auth/authenticateApi/authenticateApi";
import { ADD_MORE_ADMIN } from "../../../utils/baseUrl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IconUsersGroup } from "@tabler/icons-react";
import { IconUsers } from "@tabler/icons-react";

const LeftSidebar = ({ currentPage }) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const user = useSelector((state) => state?.AdminLogInSlice?.user?.content);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [isCandidateOpen, setIsCandidateOpen] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [openMember, setOpenMember] = useState(false);
  const cancelButtonRef = useRef(null);
  const [selectedCandidateLink, setSelectedCandidateLink] = useState();

  const [selectedLink, setSelectedLink] = useState(
    user?.customer.role === "HR" ? "PENDING" : "HOLD_ON"
  );

  const handleLinkClick = (link) => {
    
    setSelectedLink(link);
    navigate(`/interview-dashboard?approval=${link}`);
  };

  const handleCandidate = (value) => {
    
    setSelectedCandidateLink(value);
    navigate(`/admin-candidate-dashboard?approval=${value}`);
  };

  const handleOnboarding = (link, status, value) => {
    setSelectedLink(link);
    navigate(
      `/onboarding-dashboard?approval=${link}&interviewStatus=${status} `
    );
  };

  const handleAllUsers = (link, status) => {
    
    setSelectedLink(link);
    navigate(
      `/admin-interview-dashboard?approval=${link}&interviewStatus=${status}`
    );
  };

  const handleAllEmployee = (link, status, value) => {
    
    setSelectedLink(value);
    navigate(
      `/admin-interview-dashboard?approval=${link}&interviewStatus=${status}`
    );
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
  });

  const handleChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleUpdate = async () => {
    
    await axios
      .post(ADD_MORE_ADMIN, formData)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: `${response?.data?.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          setOpenMember(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${response?.data?.message}`,
            confirmButtonColor: "#EEAF06",
          });
        }
      })
      .catch((error) => {
        
      });
  };

  const handleEmployerRequest = (value) => {
    
    setSelectedLink(value);
    navigate(`/employer-interviews`);
  };

  return (
    <>
      {/* Modal For add member */}
      <Transition.Root show={openMember} as={Fragment}>
        <Dialog
          as="div"
          className=" z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenMember}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform w-[70%] h-[530px]  overflow-hidden rounded-lg bg-white  pb-4  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <form>
                      <div className="  flex h-16 pl-8  flex-shrink-0 items-center border-gray-400 justify-between  bg-[#F2F2F2] sm:mx-0 sm:h-10 sm:w-10">
                        <Dialog.Title
                          as="h3"
                          className="text-2xl font-medium leading-6 text-gray-900"
                        >
                          Add Member
                        </Dialog.Title>
                        <img
                          src={crossicon}
                          alt=""
                          className="pr-10"
                          onClick={() => setOpenMember(false)}
                          ref={cancelButtonRef}
                        />
                      </div>
                    </form>
                    <div className="mt-3 sm:ml-4 sm:mt-0 sm:text-left ">
                      <div className=" mx-8 mt-[1rem]">
                        <div className="grid grid-cols-2 gap-3">
                          <div className="firstname">
                            <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                              <img src={iconnn} alt="email" className="mr-2" />{" "}
                              First Name
                            </label>
                            <input
                              type="text"
                              id="first_name"
                              placeholder="First Name"
                              name="first_name"
                              value={formData.first_name}
                              onChange={(e) =>
                                handleChange("first_name", e.target.value)
                              }
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="lastname">
                            <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                              <img src={iconnn} alt="email" className="mr-2" />{" "}
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="last_name"
                              placeholder="Last Name"
                              name="last_name"
                              value={formData.last_name}
                              onChange={(e) =>
                                handleChange("last_name", e.target.value)
                              }
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-3">
                          <div className="email">
                            <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                              <img src={email} alt="email" className="mr-2" />{" "}
                              Email
                            </label>
                            <input
                              type="email"
                              id="email"
                              placeholder="email"
                              name="email"
                              value={formData.email}
                              onChange={(e) =>
                                handleChange("email", e.target.value)
                              }
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                          </div>
                          <div className="password relative">
                            <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                              <img
                                src={password}
                                alt="email"
                                className="mr-2"
                              />{" "}
                              Password
                            </label>
                            <input
                              type={passwordShown ? "text" : "password"}
                              id="password"
                              placeholder="Password"
                              name="password"
                              value={formData.password}
                              onChange={(e) =>
                                handleChange("password", e.target.value)
                              }
                              className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <button
                              onClick={togglePasswordVisibility}
                              className="absolute inset-y-0 right-0 top-[4.6rem] pr-3 flex items-center text-sm leading-5"
                              type="button"
                            >
                              {passwordShown ? (
                                <IconEyeOff size={24} />
                              ) : (
                                <IconEye size={24} />
                              )}
                            </button>
                          </div>
                        </div>

                        <div>
                          <label className=" text-black-500 text-[18px] mb-2 flex items-center my-10">
                            <img src={titleicon} alt="email" className="mr-2" />{" "}
                            Role
                          </label>
                          <select
                            id="role"
                            name="role"
                            value={formData.role}
                            onChange={(e) =>
                              handleChange("role", e.target.value)
                            }
                            className=" appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="" disabled>
                              Role
                            </option>

                            <option value="HR">HR</option>
                            <option value="MANAGER">
                              Customer Success Manager
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="bg-gradient-to-l from-[#E9AA06] to-[#FFC107] px-[70px] rounded-md bg-red-600  py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => handleUpdate()}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="flex items-center justify-center pb-12 pt-5">
        <img src={logo} alt="logo" className="text-center" />
      </div>

      {currentPage === "interviews" && (
        <div>
          {user?.customer.role === "HR"  ? (
            <div className="HR">
              {/* <div className="py-4 cursor-pointer pl-6 sm:pl-4 pr-4 flex items-center justify-between gap-3">
                <div
                  className="flex items-center gap-2"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <img src={Interviews} alt="" />
                  <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                    Interview
                  </h2>
                </div>
                {isOpen ? (
                  <IconChevronUp
                    size={18}
                    color="#545a6d"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                ) : (
                  <IconChevronDown
                    size={18}
                    onClick={() => setIsOpen(!isOpen)}
                  />
                )}
              </div> */}

              <div
                style={{ marginTop: "0px" }}
                className={`openclosesection transition flex items-stretch flex-col  delay-500 duration-800 `}
              >
                <button
                  onClick={() => handleLinkClick("PENDING")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3  ${
                    selectedLink === "PENDING"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0">
                    <IconLoader3
                      className={
                        selectedLink === "PENDING"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[12px] font-medium text-center ${
                        selectedLink === "PENDING"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }`}
                    >
                      Pending Interviews
                    </h2>
                  </div>
                </button>

                <button
                  onClick={() => handleLinkClick("APPROVED")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                    selectedLink === "APPROVED"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0">
                    <IconCheck
                      className={
                        selectedLink === "APPROVED"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                        selectedLink === "APPROVED"
                          ? "text-[#fcc015]"
                          : "text-[#41434b]"
                      }`}
                    >
                      Accepted Interviews
                    </h2>
                  </div>
                </button>
              </div>
            </div>
          ): user?.customer.role === "SUPER-ADMIN" && (
            <div className="SUPER_ADMIN">
              {/* <div className="py-4 cursor-pointer pl-6 sm:pl-4 pr-4 flex items-center justify-between gap-3">
                <div
                  className="flex items-center gap-2"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <img src={Interviews} alt="" />
                  <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                    Interview
                  </h2>
                </div>
                {isOpen ? (
                  <IconChevronUp
                    size={18}
                    color="#545a6d"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                ) : (
                  <IconChevronDown
                    size={18}
                    onClick={() => setIsOpen(!isOpen)}
                  />
                )}
              </div> */}

              <div
                style={{ marginTop: "0px" }}
                className={`openclosesection transition flex items-stretch flex-col  delay-500 duration-800`}
              >
                <button
                  onClick={() => handleAllUsers("PENDING", "CANDIDATE")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                    selectedLink === "PENDING"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0">
                    <IconLoader3
                      className={
                        selectedLink === "PENDING"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[12px] font-medium text-center  ${
                        selectedLink === "PENDING"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }`}
                    >
                      Pending Interviews
                    </h2>
                  </div>
                </button>

                <button
                  onClick={() => handleAllUsers("APPROVED", "CANDIDATE")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3  ${
                    selectedLink === "APPROVED"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0">
                    <IconCheck
                      className={
                        selectedLink === "APPROVED"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                        selectedLink === "APPROVED"
                          ? "text-[#fcc015]"
                          : "text-[#41434b]"
                      }`}
                    >
                      Accepted Interviews
                    </h2>
                  </div>
                </button>
                <button
                  onClick={() => handleAllEmployee("PENDING", "EMPLOYEE", "1")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 border-solid border-b-2 border-[#eeeeee] ${
                    selectedLink === "1"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0">
                    <IconUserPlus
                      className={
                        selectedLink === "1"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                        selectedLink === "1"
                          ? "text-[#fcc015]"
                          : "text-[#41434b]"
                      }`}
                    >
                      All Employers
                    </h2>
                  </div>
                </button>
              </div>

        

            
            </div>
          )}
            {/* <Link to="/admin-candidate-dashboard">
                {" "}
                <div className="onboarding py-4 pl-6 sm:pl-4 pr-4 flex items-center justify-between gap-3  ">
                  <div className="flex items-center gap-2">
                    <img src={profilevectoricon} alt="" />
                    <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                      Candidates
                    </h2>
                  </div>
                  <IconChevronDown
                    size={18}
                    onClick={() => setIsCandidateOpen(!isCandidateOpen)}
                  />
                </div>
              </Link> */}
              {user?.customer.role !== "HR"   &&(
                <>
               <button
              onClick={() => handleCandidate("HOLD_ON")}
              className={`py-2 pl-6 lg:pl-6 pr-4 flex items-center justify-between gap-3 ${
                selectedCandidateLink === "HOLD_ON"
                  ? "bg-white text-[#fcc015]"
                  : "bg-white text-[#545a6d]"
              }`}
            >
              <div className="flex items-center gap-1">
                <IconLoader3
                  className={
                    selectedCandidateLink === "HOLD_ON"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }
                />
                <h2
                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                    selectedCandidateLink === "HOLD_ON"
                      ? "text-[#fcc015]"
                      : "text-[#41434b]"
                  }`}
                >
                  Pending Candidates
                </h2>
              </div>
            </button>
            <button
              onClick={() => handleCandidate("CLEAR")}
              className={`py-2 pl-6 lg:pl-6 pr-4 flex items-center justify-between gap-3 ${
                selectedCandidateLink === "CLEAR"
                  ? "bg-white text-[#fcc015]"
                  : "bg-white text-[#545a6d]"
              }`}
            >
              <div className="flex items-center gap-1">
                <IconUserPlus
                  className={
                    selectedCandidateLink === "CLEAR"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }
                />
                <h2
                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                    selectedCandidateLink === "CLEAR"
                      ? "text-[#fcc015]"
                      : "text-[#41434b]"
                  }`}
                >
                  Available Candidates
                </h2>
              </div>
            </button>
          
            <button
              onClick={() => handleCandidate("NO_CLEAR")}
              className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 border-solid border-b-2 border-[#eeeeee] ${
                selectedCandidateLink === "NO_CLEAR"
                  ? "bg-white text-[#fcc015]"
                  : "bg-white text-[#545a6d]"
              }`}
            >
              <div className="flex items-center gap-2">
                <IconX
                  className={
                    selectedCandidateLink === "NO_CLEAR"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }
                />
                <h2
                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                    selectedCandidateLink === "NO_CLEAR"
                      ? "text-[#fcc015]"
                      : "text-[#41434b]"
                  }`}
                >
                  Rejected Candidates
                </h2>
              </div>
            </button>
            </>
            )}
        </div>
      )}
      <div>
      
      </div>

      {currentPage === "candidates" && user?.customer.role !== "HR"   && (

        <div className="cursor-pointer ">
         { user?.customer.role === "SUPER-ADMIN" && (
              <div
                style={{ marginTop: "0px" }}
                className={`openclosesection transition flex items-stretch flex-col  delay-500 duration-800`}
              >
                <button
                  onClick={() => handleAllUsers("PENDING", "CANDIDATE")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                    selectedLink === "PENDING"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0">
                    <IconLoader3
                      className={
                        selectedLink === "PENDING"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[12px] font-medium text-center ${
                        selectedLink === "PENDING"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }`}
                    >
                      Pending Interviews
                    </h2>
                  </div>
                </button>

                <button
                  onClick={() => handleAllUsers("APPROVED", "CANDIDATE")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 ${
                    selectedLink === "APPROVED"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0 ">
                    <IconCheck
                      className={
                        selectedLink === "APPROVED"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                        selectedLink === "APPROVED"
                          ? "text-[#fcc015]"
                          : "text-[#41434b]"
                      }`}
                    >
                      Accepted Interviews
                    </h2>
                  </div>
                </button>
                <button
                  onClick={() => handleAllEmployee("PENDING", "EMPLOYEE", "1")}
                  className={`py-2 pl-6 pr-4 flex items-center justify-between gap-3 border-solid border-b-2 border-[#eeeeee] ${
                    selectedLink === "1"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }`}
                >
                  <div className="flex items-center gap-2 lg:gap-0">
                    <IconUserPlus
                      className={
                        selectedLink === "1"
                          ? "text-[#fcc015]"
                          : "text-[#545a6d]"
                      }
                    />
                    <h2
                      className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                        selectedLink === "1"
                          ? "text-[#fcc015]"
                          : "text-[#41434b]"
                      }`}
                    >
                      All Employers
                    </h2>
                  </div>
                </button>
              </div>
          )}
          {/* <Link to="/admin-interview-dashboard">
            {" "}
            { user?.customer.role !== "MANAGER" &&(
            <div className="py-4 pl-6 pr-4 flex items-center justify-between gap-3 ">
              <div className="flex items-center gap-2">
                <img src={Interviews} alt="" />
                <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                  Interview
                </h2>
              </div>
              <IconChevronDown size={18} onClick={() => setIsOpen(!isOpen)} />
            </div>
            )}
          </Link> */}

         

          {/* <div
            className="onboarding py-4 pl-6 md:pl-4 sm:pl-4 pr-4 flex items-center justify-between gap-3 "
            onClick={() => setIsCandidateOpen(!isCandidateOpen)}
          >
            <div className="flex items-center gap-2">
              <img src={profilevectoricon} alt="" />
              <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
                Candidates
              </h2>
            </div>
            {isCandidateOpen ? (
              <IconChevronUp
                size={18}
                onClick={() => setIsCandidateOpen(!isCandidateOpen)}
              />
            ) : (
              <IconChevronDown
                size={18}
                onClick={() => setIsCandidateOpen(!isCandidateOpen)}
              />
            )}
          </div> */}
          {/* <div
            className={`openclosesection pl-5 transition flex items-stretch flex-col delay-500  duration-800
              ${isCandidateOpen ? "block" : "hidden"}
               `
            }
          > */}
          <div
            className={`openclosesection  transition flex items-stretch flex-col delay-500  duration-800`}
          >
            {/* ... */}

            <button
              onClick={() => handleCandidate("HOLD_ON")}
              className={`py-2 pl-6 lg:pl-6 pr-4 flex items-center justify-between gap-3 ${
                selectedCandidateLink === "HOLD_ON"
                  ? "bg-white text-[#fcc015]"
                  : "bg-white text-[#545a6d]"
              }`}
            >
              <div className="flex items-center gap-1">
                <IconLoader3
                  className={
                    selectedCandidateLink === "HOLD_ON"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }
                />
                <h2
                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                    selectedCandidateLink === "HOLD_ON"
                      ? "text-[#fcc015]"
                      : "text-[#41434b]"
                  }`}
                >
                  Pending Candidates
                </h2>
              </div>
            </button>
            <button
              onClick={() => handleCandidate("CLEAR")}
              className={`py-2 pl-6 lg:pl-6 pr-4 flex items-center justify-between gap-3 ${
                selectedCandidateLink === "CLEAR"
                  ? "bg-white text-[#fcc015]"
                  : "bg-white text-[#545a6d]"
              }`}
            >
              <div className="flex items-center gap-1">
                <IconUserPlus
                  className={
                    selectedCandidateLink === "CLEAR"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }
                />
                <h2
                  className={`text-[12px] lg:text-[12px] md:text-[10px] font-medium text-center ${
                    selectedCandidateLink === "CLEAR"
                      ? "text-[#fcc015]"
                      : "text-[#41434b]"
                  }`}
                >
                  Available Candidates
                </h2>
              </div>
            </button>
          
            <button
              onClick={() => handleCandidate("NO_CLEAR")}
              className={`py-2 pl-6 pr-4  flex items-center justify-between gap-3 border-solid border-b-2 border-[#eeeeee] ${
                selectedCandidateLink === "NO_CLEAR"
                  ? "bg-white text-[#fcc015]"
                  : "bg-white text-[#545a6d]"
              }`}
            >
              <div className="flex items-center gap-2">
                <IconX
                  className={
                    selectedCandidateLink === "NO_CLEAR"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }
                />
                <h2
                  className={`text-[12px]  lg:text-[12px] md:text-[10px] font-medium text-center ${
                    selectedCandidateLink === "NO_CLEAR"
                      ? "text-[#fcc015]"
                      : "text-[#41434b]"
                  }`}
                >
                  Rejected Candidates
                </h2>
              </div>
            </button>
          </div>
        </div>
      )}
      {user?.customer?.role === "SUPER-ADMIN" || (user?.customer?.role === "MANAGER" && (
          <div className="addteammember ml-6 md:ml-[1.4rem] mt-4">
            <div
              className="flex items-center cursor-pointer gap-2 "
              onClick={() => handleEmployerRequest("employer-Interview")}
            >
              <IconUsers size={22} className={
                   selectedLink === "employer-Interview"
                      ? "bg-white text-[#fcc015]"
                      : "bg-white text-[#545a6d]"
                  }/>
              <h2
                className={`text-[12px] lg:text-[12px] md:text-[12px] font-medium text-center ${
                  selectedLink === "employer-Interview"
                    ? "text-[#fcc015]"
                    : "text-[#545a6d]"
                }`}
              >
                Employer Interviews
              </h2>
            </div>
          </div>
        ))}
      {user?.customer.role === "SUPER-ADMIN" && (
        <div className="addteammember ml-6 md:ml-[1.4rem] mt-4">
          <div
            className="flex items-center cursor-pointer gap-2 "
            onClick={() => setOpenMember(true)}
          >
            <IconUsersPlus size={22} />
            <h2 className="text-[14px] text-[#545a6d] font-medium text-center">
              Add Team Member
            </h2>
          </div>
        </div>
      )}
    </>
  );
};

export default LeftSidebar;
