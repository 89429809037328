import React from "react";

import logo from "./../../assets/images/logo.png";
import { Link } from "react-router-dom";

const CandidateNavbar = ({title}) => {
  return (
    <div className="py-3 sm:mx-0 rounded flex items-center pl-20 sm:pl-4 ">
      <div >
        <img src={logo} alt="logo" />
      </div>
      <div className="buttons ml-20">
       {/* <Link to={"/login"} > <button className="bg-black text-white px-8 py-2 rounded text-lg mr-3">
          Log In{" "}
        </button></Link> */}
        <button className=" text-[#495057] font-semibold px-8 py-2 rounded text-2xl pl-10">
          {title}
        </button>
      </div>
    </div>
  );
};

export default CandidateNavbar;
