import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store/store";
import { ClientContext } from "../src/Pages/zoomIntegration/Context/globalContext";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import ZoomVideo from "@zoom/videosdk";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// const zmClient = ZoomVideo.createClient();
// const start = zmClient.init('en-US', 'Global', { patchJsMedia: true });
// const stream = zmClient.getMediaStream();


// const AppWithClientContext = () => {
//   return (
//     <ClientContext.Provider value={{ uitoolkit, zmClient, stream,start }}>
//       <Provider store={store}>
//         <BrowserRouter>
//           <App />
//         </BrowserRouter>
//       </Provider>
//     </ClientContext.Provider>
//   );
// };

// root.render(
//   <React.StrictMode>
//     <AppWithClientContext />
//   </React.StrictMode>
// );
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
