import React, { lazy, Suspense } from "react";
import Schedular from "../../common/Schedular/schedular";
import CandidateProfileAdmin from "../../Pages/BMGadmin/candidateProfileAdmin/candidateProfileAdmin";
import AdminInterviewDashboard from "../../Pages/BMGadmin/interviewDashboardAdmin";
import EmployeInterviews from "../../Pages/BMGadmin/EmployerInterviewsManager";


const withSuspense = (WrappedComponent, fallback) => (props) =>
  (
    <Suspense fallback={fallback || null}>
      <WrappedComponent {...props} />
    </Suspense>
  );

//   only for login //
const LoginForm = withSuspense(lazy(() => import("./../Auth/login")));
const CandidateDashboard = withSuspense(
  lazy(() => import("../../Pages/Employer/CandidateDashboard"))
);

// rolebase Authentiction//
const EmployerDashboard = withSuspense(
  lazy(() => import("./../../Pages/Employer/EmployerDashboard"))
);
const TopCandidates = withSuspense(
  lazy(() => import("../../Pages/Employer/TopCandidates"))
);
const CandidateProfile = withSuspense(
  lazy(() => import("../../Pages/Employer/CandidateProfile"))
);
const CandidateSearchBar = withSuspense(
  lazy(() => import("../../Pages/Employer/CandidateSearchBar"))
);


/// admin Routes
const OnboardingDashbaord = withSuspense(
  lazy(() => import("../../Pages/BMGadmin/OnboardingDashboard"))
);

const BmgDashboard = withSuspense(
  lazy(() => import("../../Pages/BMGadmin/BmgDashboard"))
);
const InterviewDashboard = withSuspense(
  lazy(() => import("../../Pages/BMGadmin/interviewDashboard"))
);
const CandidateAdminDashboard = withSuspense(
  lazy(() => import("../../Pages/BMGadmin/candidateDashboard"))
);


export { LoginForm };
const protectedRoutes = [
  {
    path: `/candidate-dashboard`,
    element: <CandidateDashboard />,
  },
];

const rolebaseProtectedRoute = [
  {
    path: `/employer-dashboard`,
    element: <EmployerDashboard />,
  },
  {
    path: `/top-candidate`,
    element: <TopCandidates />,
  },
  // {
  //   path: `/candidate-profile/:id`,
  //   element: <CandidateProfile />,
  // },
  {
    path: `/schedular/:id`,
    element: <Schedular />,
  },
  {
    path: `/candidate-searchbar`,
    element: <CandidateSearchBar />,
  },
];



const adminProtectedRoute = [
  {
    path: `/onboarding-dashboard`,
    element: <OnboardingDashbaord />,
  },
  {
    path: `/bmg-dashboard`,
    element: <BmgDashboard />,
  },
  {
    path: `/interview-dashboard`,
    element: <InterviewDashboard />,
  },
  {
    path: `/admin-interview-dashboard`,
    element: <AdminInterviewDashboard />,
  },
  {
    path: `/employer-interviews`,
    element: <EmployeInterviews />,
  },
  {
    path: `/admin-candidate-dashboard`,
    element: <CandidateAdminDashboard />,
  },
  // {
  //   path: `/candidate-profile-admin/:id`,
  //   element: <CandidateProfileAdmin />,
  // },
];
export { protectedRoutes, rolebaseProtectedRoute, adminProtectedRoute };
