export const BASE_URL=`https://api.bmgtalentplus.com.sg`
// export const BASE_URL=`http://13.48.182.158:8080/`

export const ADMIN_LOGIN=`/admin/login`

export const LOGIN=`/auth/login`

export const REGISTER=`/auth/register`

export const CANDIDATE_DASHBOARD=`/profile`

export const ADMIN_PROFILE=`/admin/profile`

export const OTP_VERIFICATION=`/auth/otp_verification`

export const RESEND_OTP=`/auth/resend_otp`

export const SHORTLIST_CANDIDATE_LIST=`/profile/selected/candidates`

export const ADMIN_DASHBOARD_CARDS=`/admin/dashboard`

export const ADD_MORE_ADMIN=`/admin/add_admin`

export const ALL_EMPLOYERS_INTERVIEW_REQUEST=`/admin/allClearEmployees`


export const CANDIDATE_UPDATE=(id)=>`/profile/update/${id}`

export const CANDIDATE_PICTURE=(id)=>`/profile/profile_picture/${id}`

export const CANDIDATE_PROFILE=(id)=>`public/candidate/${id}`

export const CANDIDATE_SEARCH=(key,page)=>`/public/search/candidate/${key}?page=${page}`

// export const INTERVIEW_REQUEST=(data)=>`/admin/dashboard/candidate/${data.approval}/${data.interviewStatus}?page=${data.page}`
export const INTERVIEW_STATUS_ADMIN=(data)=>`/admin/dashboard/candidate/interview?page=${data.page}&status=${data?.status}`

export const ALL_USERS_FOR_ADMIN=(data)=>`/admin/dashboard/all_user?page=${data.page}&role=${data.role}&approval=${data.approval}`

export const INTERVIEW_REQUEST=(data)=>`/admin/dashboard/candidate/${data.approval}?page=${data.page}`

export const TOP_CANDIDATE=(data)=>`/public/candidate?page=${data.page}`

export const SCHEDULAR=(date)=>`https://api.bmgtalentplus.com.sg/profile/employer/availability/${date}`

export const EMPLOYER_SCHEDULE_MEETING=(id)=>`https://api.bmgtalentplus.com.sg/public/bmg_request/candidate/${id}`

export const CANDIDATE_SCHEDULAR_TIME=(date)=>`https://api.bmgtalentplus.com.sg/auth/candidate/availability/${date}`

// export const EMPLOYER_SHORTLIST_CANDIDATE=(id)=>`/public/candidate/profile/like/${id}`
export const EMPLOYER_SHORTLIST_CANDIDATE=(id)=>`/profile/likeCandidates/${id}`

export const ALL_EMPLOYER_REQUEST=(page,interviewapproval)=>`/admin/dashboard/employee/request?page=${page}&accepted=${interviewapproval}`

export const INTERVIEW_APPROVAL=(id)=>`/admin/dashboard/candidate/${id}`

export const EMPLOYER_GIVE_RESULT=(id)=>`/admin/interview/video/upload/${id}`

export const HR_INTERVIEW_APPROVAL=(id)=>`/admin/dashboard/employee/request/${id}`

export const EMPLOYER_SHORTLISTED_CANDIDATES=(id)=>`/admin/allClearCandidate/${id}`