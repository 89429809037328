import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  return (
    <div className="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6 ">
      <div className=" sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={()=>setCurrentPage(currentPage-1)}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {Array.from({ length: totalPages }, (_, i) => i + 1)?.map(
              (pageNum) => (
                <button
                key={pageNum}
                // onChange={()=>handlePageChange(pageNum)}
                onClick={() => handlePageChange(pageNum)}
                className={`... ${
                  currentPage === pageNum
                    ? "bg-[#ECB311] text-white"
                    : "text-gray-900 "
                } ... relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-600 ring-1 ring-inset ring-gray-300 hover:bg-[#E9AA06] hover:text-white focus:z-20 focus:outline-offset-0`}
              >
                {pageNum}
              </button>
              )
            )}
            <button
              onClick={()=>setCurrentPage(currentPage+1)}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
