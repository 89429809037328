import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "../../components/Auth/authenticateApi/authenticateApi";
import { useFormik } from "formik";
import CandidateNavbar from "../candidateNavbar";
import videoicon from "./../../assets/images/videoicon.png";
import clock from "./../../assets/images/clock.png";
import user from "./../../assets/images/user.png";
import DatePicker from "react-datepicker";
import { EMPLOYER_SCHEDULE_MEETING, SCHEDULAR } from "../../utils/baseUrl";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const Schedular = (props) => {
    const {id}=useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [meetingData, setMeetingData] = useState({
    meeting_time: "",
    meeting_date: "",
    meting_url: "string",
  });
  useEffect(() => {
    const year = startDate.getFullYear();
    const month = ('0' + (startDate.getMonth() + 1)).slice(-2);
    const day = ('0' + startDate.getDate()).slice(-2);
    
    const formattedStartDate = `${year}-${month}-${day}`;
    const fetchTimeSlots = async () => {
      try {
        const response = await axios.get(
          SCHEDULAR(formattedStartDate)
        );
        setTimeSlots(response?.data?.content);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching time slots:", error);
      }
    };
    fetchTimeSlots();
  }, [startDate]);

  const formik = useFormik({
    initialValues: meetingData,

    onSubmit: async (values) => {
      try {
        if (values.meeting_date === "") {
          // Show an error message using a library like Swal
          Swal.fire({
            icon: "error",
            title: "Meeting date and time is required",
            text: "Please Select Both.",
          });
          return; // Stop the function execution if the date is empty
        }
        const year = startDate.getFullYear();
        const month = ('0' + (startDate.getMonth() + 1)).slice(-2);
        const day = ('0' + startDate.getDate()).slice(-2);
        
        const formattedStartDate = `${year}-${month}-${day}`;
        const payload = {
          ...values,
          meeting_date:formattedStartDate
          // Other fields in your payload
        };
  
        const response = await axios.post(EMPLOYER_SCHEDULE_MEETING(id), payload);
  
        if (response?.data?.success) {
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/login");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response?.data?.message,
          });
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },




    // onSubmit: async (values) => {
    //   try {
    //     const payload = {
    //       ...values,
       
    //     };
    //   if(payload.meeting_date===""){  
    //     Swal.fire({
    //       icon: "success",
    //       title: `Please Select the Date`,
    //       showConfirmButton: false,
    //       timer: 1500,
    //     });
    //   }
    //   const response = await axios.post(EMPLOYER_SCHEDULE_MEETING(id), payload);
    //     if (response?.data?.success) {
    //       Swal.fire({
    //         icon: "success",
    //         title: `${response?.data?.message}`,
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //       navigate("/login");
    //     } else {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Oops...",
    //         text: `${response?.data?.message}`,
    //       });
    //     }
       
    //   } catch (error) {
    //     console.error("API call failed:", error);
    //   }
    // },
  });

  const handleTimeSlotClick = (time, index) => {
    formik.setFieldValue('meeting_time', time.start_time);
    setSelectedTimeSlot(index); // Set the index of the selected time slot
  };

  // for date selection
  const [selectedDate, setSelectedDate] = useState(null);
  const currentDate = new Date(); // Get the current date
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1); // Set the next date
  const selectableDate = new Date(currentDate);
  selectableDate.setDate(currentDate.getDate() + 2); 
  return (
    <>
      <div className="scheduling-page-container">
        <CandidateNavbar />
        <div className="container mx-auto  bg-white rounded-xl mb-[4rem] w-[90%] py-10 mt-16 ">
          <div className="border-b-[2px] pt-10"></div>
          <div className="p-6 rounded-xl mt-2 grid 2xl:grid-cols-[1fr_1.75fr] xl:grid-cols-[1fr_2fr] lg:grid-cols-[1fr_2fr] gap-16">
            <div className="custom-calendar border-r-2 pr-7">
              <div className="py-4">
                <h2 className="text-[22px]">Office Hours</h2>
                <p className="text-lg font-normal py-4">
                  Book A Slot To Speak To Our Team
                </p>
                <div className="flex justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <img src={videoicon} alt="" className="w-8" />
                    <h2 className="text-[16px]">Zoom Meeting</h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={clock} alt="" className="w-8 " />
                    <h2 className="text-[16px]">1 hour</h2>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={user} alt="" className="w-8 " />
                    <h2 className="text-[16px]">1 on 1</h2>
                  </div>
                </div>
              </div>
              <DatePicker
              selected={selectedDate}
                  
              minDate={selectableDate}
                inline

                // selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  
                  formik.setFieldValue(
                    "meeting_date",
                   
                    date.toISOString().split("T")[0]
                  );
                }}
              />
            </div>
            <div className="rightsection flex flex-col pt-10 ">
              <h2 className="text-lg text-[#696969]">Your Selected Date</h2>
              <h2 className="text-[16px]">Saturday, 20 Dec</h2>
              <div className="slots mt-6">
                <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 gap-4">
                {isLoading ? (
                <div className="py-8  px-8 text-center rounded-lg">
                  <Skeleton count={6} width={500} className="mb-4" />
                    </div>
                  ) : (timeSlots?.map((time, index) => (
                    <button
                      key={index}
                      onClick={() =>{
                        handleTimeSlotClick(time, index)
                        formik.setFieldValue(
                          "meeting_time",
                          time.start_time
                        )}
                      }
                      className={`border-2 py-3 px-8 text-center rounded-lg ${selectedTimeSlot === index ? 'bg-[#E9AA06] text-white' : ''}`}
                    >
                      {time?.start_time} - {time?.end_time}
                    </button>
                  ))
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="md:container lg:container xl:container 2xl:container pb-[5rem]  w-4/5 mx-auto flex justify-center">
           <Link to={`/candidate-profile/${id}`}> <button
                // onClick={() => props.prev()}
                className="border-[1px]  border-black mr-[4px] px-16 py-2 rounded text-lg flex items-center"
              >
                {/* <img src={blackarrowleft} alt="email" className="mr-2 pl-2" />{" "} */}
                Back
              </button></Link>

            <button
              type="submit"
              // onClick={handleSubmit}
              className="border-[1px] bg-black text-white hover:bg-white hover:text-black  border-black ml-[5px] px-20  py-2 rounded text-lg flex items-center"
            >
              Continue{" "}
              {/* <img src={rightArrowBlack} alt="email" className="mr-2 pl-2" /> */}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Schedular;
