import React, { useEffect, useState } from "react";
import briefcase from "../../../assets/images/Icons/briefcase.svg";
import Videofullimg from "../../../assets/images/Videofullimg.png";
import Flag from "../../../assets/images/Flag.png";
import { useDispatch } from "react-redux";
import Location from "../../../assets/images/Icons/Location.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Phone from "../../../assets/images/Icons/Phone.svg";
import { useSelector } from "react-redux";
import AdminNavbar from "../adminNavbar";
import logo from "../../../assets/images/logo.png";
const CandidateProfileAdmin = () => {
  const location = useLocation();
  const receivedData = location.state?.item;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [candidate, setCandidate] = useState([receivedData]);
  const [currentStep, setCurrentStep] = useState(1);
  const userData = useSelector(
    (state) => state?.interviewRequestSlice?.user?.content?.content
  );

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate(`/public-profile?candidate=${id}`);
    }
  }, [userData, id, navigate]);

 

  return (
    <>
      {candidate?.map((user, index) => (
        <div key={index}>
          <AdminNavbar showlogo={true} logo={logo} />
          {user && (
            <div className="">
              <div className="grid 2xl:grid-cols-[300px_1fr] xl:grid-cols-[300px_1fr] lg:grid-cols-[280px_1fr] md:grid-cols-1 mt-5">
                <div className="leftsection bg-white rounded flex flex-col justify-between h-full py-12 dashboard-background px-6">
                  <div className="top">
                    <img
                      src={`https://api.bmgtalentplus.com.sg/profile/${user?.profile_picture}`}
                      alt="profilePicture"
                      className="w-[200px] h-[200px] rounded-full object-cover mx-auto"
                    />
                    <div className="candidateinfo bg-[#f9f9f9] border-[1px] mt-10 rounded-md py-1 px-8 flex flex-col items-center justify-center  ">
                      <h2 className="text-[16px] text-black font-semibold">
                        {user?.first_name} {user?.last_name}
                      </h2>
                      <p className="text-[12px] font-normal text-[#696969]">
                        {user?.title}
                      </p>
                    </div>
                    <div className="candidateinfo bg-[#f9f9f9] border-[1px] mt-[0.5rem] rounded-md py-1 px-8 flex flex-col items-center justify-center  ">
                      <h2 className="text-[16px] text-black font-semibold">
                        Email:
                      </h2>
                      <p className="text-sm font-normal text-[#696969]">
                        {user?.email}
                      </p>
                    </div>
                    <div className="status mt-5">
                      <h2 className="text-[16px] text-black font-semibold">
                        Availability
                      </h2>
                      <div className="flex items-center gap-2 mt-2">
                        <img src={briefcase} alt="" />
                        <h2 className="text-[13px] text-[#696969] font-normal">
                          Full time, part time
                        </h2>
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <img src={Flag} alt="" />
                        <h2 className="text-[13px] text-[#696969] font-normal">
                          {user?.country}
                        </h2>
                      </div>
                    </div>
                    <div className=" relative contactinfo bg-[#f9f9f9] border-[1px] mt-6 rounded-md py-6 px-4">
                      <div className="location flex items-start gap-2">
                        <img src={Location} alt="" className="w-4 h-4" />
                        <p className="text-[12px] font-medium">
                          {user?.address}
                        </p>
                      </div>
                      <div className="phone flex items-start gap-2 mt-4">
                        <img src={Phone} alt="" className="w-4 h-4" />
                        <p className="text-[12px] font-medium">
                          +{user?.cell_phone}
                        </p>
                      </div>
                    </div>
                    <div className="languages mt-8">
                      <h2 className="text-[16px] text-black font-semibold">
                        Languages
                      </h2>
                      <div className=" gap-2 mt-3">
                        {user?.language?.map((item, index) => (
                          <h2
                            key={index}
                            className="2xl:text-[13px] mb-2 xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-white bg-gradient-to-l from-[#E9AA06] to-[#FFC107] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal"
                          >
                            {item.lan}
                          </h2>
                        ))}
                      </div>
                    </div>
                    <div className="noticePeriod mt-8">
                      <div className="flex justify-between items-center">
                        <h2 className="text-[16px] text-black font-semibold">
                          Notice Period
                        </h2>
                      </div>
                      <div className="flex items-center gap-2 mt-3">
                        <h2 className="2xl:text-[13px] xl:text-[14px] lg:text-[12px] md:text-[10px] text-[10px] text-white bg-gradient-to-l from-[#E9AA06] to-[#FFC107] py-1 2xl:px-4 xl:px-2 lg:px-2 md:px-2 sm:px-1 rounded-[4px] font-normal">
                          {user?.notice_period?.replace(/_/g, " ")}
                        </h2>
                      </div>
                    </div>
                    {/* <div className="mt-10 flex gap-2">
                      <div className="bg-[#F7E7BC] shadow-lg flex items-center gap-[0.3rem] py-1 px-[10px]  ">
                        <img src={redheart} alt="" />
                        <h2 className="text-[12px] font-medium ">Shortlist</h2>
                      </div>
                      <div className="bg-[#F7E7BC] shadow-lg flex items-center gap-2 py-1  px-[10px]">
                        <img src={whiteheart} alt="" />
                        <h2 className="text-[12px] font-medium">
                          Considerable
                        </h2>
                      </div>
                      <div className="bg-[#F7E7BC] shadow-lg flex items-center gap-2 py-1  px-[10px]">
                        <img src={greenheart} alt="" />
                        <h2 className="text-[12px] font-medium">Rejected</h2>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="rightsection bg-[#f8f8fb] px-6 pt-6 pb-12 ">
                  <img
                    src={Videofullimg}
                    alt=""
                    className="xl:w-full 2xl:w-full"
                  />
                  <div className="aboutsection border-[1px] bg-white  rounded-md py-12 px-14 mt-6 md:py-8 md:px-8">
                    <h2 className="text-[22px] text-[#495057] font-medium">
                      About Me
                    </h2>
                    <p className="text-[15px] font-medium leading-[26px] pt-2 pr-16 text-[#696969]  rounded-md">
                      {user?.about_me}
                    </p>
                  </div>

                  <div className="workexperience border-[1px] relative bg-white rounded-md shadow-md py-14 px-14 md:py-8 sm:pt-4 md:px-8 sm:px-4 mt-6">
                    <h2 className="text-[22px] text-[#495057] font-medium pb-4">
                      Work Experience
                    </h2>

                    <div>
                      {user?.experience?.map((exp, index) => (
                        <div
                          key={index}
                          className="flex items-baseline justify-between  px-[1rem] pt-[0px] pb-[5px] bg-white  border-b-[1px] mb-2 border-[#eeeeee] "
                        >
                          <div>
                            <p className="text-[14px] font-medium leading-[20px] pt-1 pr-16 text-[#696969]">
                              {exp.designation} | {exp.company} |
                            </p>
                            <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#E9AA06]">
                              {exp.start_date} {exp.end_date}
                            </p>
                            <p className="text-[14px] font-medium leading-[20px]  pr-16 text-[#696969]">
                              {exp.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="Skills border-[1px] relative bg-white rounded-md shadow-md py-10 px-14 md:py-8 md:px-8 mt-6">
                    <h2 className="text-[22px] text-[#495057] font-medium pb-4">
                      Skills
                    </h2>

                    <div className="max-h-[220px] min-h-[220px] overflow-y-auto bg-white">
                      <h2 className="text-gray-700 text-[15px] text-left px-4 pt-4">
                        Tech Skills
                      </h2>
                      <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                        {user?.skill?.map(
                          (title, index) =>
                            title.type === "TECH" && (
                              <h2
                                key={index}
                                className="relative text-[13px] text-center py-[9px]   border-[1px] rounded-md bg-white"
                              >
                                {title.name} {title.rating}/10
                              </h2>
                            )
                        )}
                      </div>
                    </div>

                    <div className="max-h-[220px] min-h-[220px] overflow-y-auto bg-white">
                      <h2 className="text-gray-700 text-[15px] text-left px-4 pt-4">
                        Soft Skills
                      </h2>
                      <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 justify-center  pl-4 items-center pr-16 mt-7 ">
                        {user?.skill?.map(
                          (title, index) =>
                            title.type === "SOFT" && (
                              <h2
                                key={index}
                                className="relative text-[13px] text-center py-[9px]   border-[1px] rounded-md bg-white"
                              >
                                {title.name} {title.rating}/10
                              </h2>
                            )
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="education border-[1px] relative bg-white rounded-md shadow-md py-14 px-14 md:py-8 md:px-8 mt-6 mb-8">
                    <h2 className="text-[22px] text-[#495057] font-medium pb-4">
                      Education
                    </h2>

                    <div>
                      {user?.education?.map((edu, index) => (
                        <div
                          key={index}
                          className="flex items-baseline justify-between  px-[1rem] pt-[0px] mb-3 pb-[8px] border-solid border-b-[1px] bg-white"
                        >
                          <div>
                            <p className="text-[14px] font-medium leading-[25px]  pr-16 text-[#696969]">
                              {edu.degree} | {edu.university} |
                            </p>
                            <p className="text-[14px] font-medium leading-[25px] pr-16 text-[#E9AA06]">
                              {edu.start_date} {edu.end_date}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-5 flex justify-center items-center">
                    <Link to={"/interview-dashboard"}>
                      <button className="bg-black text-white border-2 flex gap-2 items-center border-black hover:bg-white hover:text-black px-10 py-2 rounded text-lg">
                        Back To Applications
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default CandidateProfileAdmin;
